.content {
  border: 0;
  height: 100%;
  width: 100%;
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    height: 100svh;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .modal {
    height: 90svh;
    width: 90svw;
  }
}
