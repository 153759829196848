.tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * 0.8);
}

.breakdown {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-XXS) 0;
}

.breakdown .item {
  justify-content: space-between;
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

.breakdown .item > ui-text:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breakdown ui-text {
  color: var(--mirai-ui-base);
}

.breakdown .anchor {
  border-bottom: solid 1px var(--mirai-ui-base);
  opacity: 0.33;
}
