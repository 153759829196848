.skeleton {
  border-radius: var(--mirai-ui-space-XXS);
}

.skeleton:not(.base) {
  animation: skeleton-loading 1.5s infinite;
  background: linear-gradient(
    90deg,
    var(--mirai-ui-content-border) 25%,
    var(--mirai-ui-content-background) 50%,
    var(--mirai-ui-content-border) 75%
  );
  background-size: 200% 100%;
  opacity: 0.66;
}

.skeleton.base {
  background-color: var(--mirai-ui-base);
}

.skeleton:not(.text):not(.button):not(.icon) {
  min-height: var(--mirai-ui-space-M);
  min-width: var(--mirai-ui-space-M);
}

.skeleton.text {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-base);
  /* margin: calc(var(--mirai-ui-border-width) / 2) 0; */
  min-width: var(--mirai-ui-space-M);
  width: fit-content;
}

.skeleton.button {
  pointer-events: none;
}

.skeleton.button,
.skeleton.text {
  color: transparent !important;
}

.icon {
  height: var(--mirai-ui-font-size-headline-3);
  width: var(--mirai-ui-font-size-headline-3);
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
