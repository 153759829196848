.inputPhone {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
}

.prefix {
  padding-right: 0;
  width: calc(var(--mirai-ui-space-XXL) + var(--mirai-ui-space-XS));
  overflow: hidden;
  text-overflow: ellipsis;
}

.prefixValue {
  background-color: var(--mirai-ui-base);
  bottom: var(--mirai-ui-input-text-padding-y);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  font-weight: inherit;
  left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  pointer-events: none;
  position: absolute;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.separator {
  border-right: var(--mirai-ui-input-text-border);
  margin-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  margin-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding: var(--mirai-ui-space-M) 0;
}
