.view {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.fit {
  width: fit-content;
}

.row,
.forceRow {
  align-items: center;
}

.row {
  flex-direction: var(--mirai-ui-row-direction);
}

.forceRow {
  flex-direction: row;
}

.wide {
  width: 100%;
}
