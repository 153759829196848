.container {
  align-items: normal;
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-accent-border);
  overflow: hidden;
}

.container > :first-child > * {
  width: max-content;
}

.title {
  gap: var(--mirai-ui-space-S);
}

.items {
  overflow-x: auto;
  width: 100%;
}

.item {
  align-items: center;
  flex-wrap: wrap;
}

/* S */
@media only screen and (max-width: 480px) {
  .container > :first-child {
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-accent-border);
    padding: var(--mirai-ui-space-S);
  }

  .title {
    justify-content: space-between;
  }

  .items {
    align-items: center;
    padding: 0 var(--mirai-ui-space-XS);
  }

  .item {
    padding: var(--mirai-ui-space-XS) 0;
    max-width: 25%;
    min-width: 25%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container > * {
    padding: var(--mirai-ui-space-M);
  }
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .item {
    padding: var(--mirai-ui-space-XS) 0;
    width: 33%;
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .items {
    margin-left: var(--mirai-ui-space-M);
  }

  .item {
    width: 25%;
  }
}
