.card {
  align-items: center;
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  display: flex;
  flex-direction: row;
  gap: calc(var(--mirai-chat-space) / 2);
  overflow: hidden;
}

.card + .card {
  margin-top: calc(var(--mirai-chat-space) / 2);
}

.card.offset {
  padding: var(--mirai-chat-message-offset);
}

.card.pressable:not(.checked):not(.disabled):hover {
  background-color: var(--mirai-ui-content);
  border-color: var(--mirai-ui-content);
  cursor: pointer;
}

.card .image {
  align-self: flex-start;
  background-size: contain;
  background-color: var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  min-height: var(--mirai-ui-space-XL);
  min-width: var(--mirai-ui-space-XL);
}

.card.pressable:not(.checked):not(.disabled):hover [data-role='icon'],
.card.pressable:not(.checked):not(.disabled):hover [data-role='text'] {
  color: var(--mirai-chat-background);
}

.card.disabled .image {
  filter: grayscale(1);
}

/* S  */
@media only screen and (max-width: 480px) {
  .card {
    width: auto;
  }
}

/* M & L  */
@media only screen and (min-width: 481px) {
  .card {
    width: fit-content;
  }
}
