html {
  scroll-behavior: smooth;
}

.button {
  margin-top: var(--mirai-ui-space-L);
}

.header {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-M);
}

.description {
  color: var(--mirai-ui-content-light);
  margin-top: var(--mirai-ui-space-S);
}

.form {
  padding: var(--mirai-ui-space-M);
}

.notification {
  margin-bottom: var(--mirai-ui-space-L);
}

.privacy {
  display: flex;
  flex-direction: row;
}

.privacy .link {
  display: inline;
}

/* S */
@media only screen and (max-width: 480px) {
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    align-self: center;
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
    width: 100%;
  }
}
