.container {
  align-items: center;
  align-content: center;
  background-color: var(--mirai-chat-background);
  height: 100svh;
  justify-content: center;
  overflow: hidden;
  padding-bottom: calc(var(--mirai-chat-space) * 2);
  width: 100svw;
  z-index: var(--mirai-chat-layer);
}

.messages {
  flex: 1;
  gap: calc(var(--mirai-chat-space) * 2);
  padding-bottom: calc(var(--mirai-chat-space) * 2);
  padding-top: calc(var(--mirai-chat-space) * 5);
  width: 100%;
}

.input {
  width: calc(100% - calc(var(--mirai-chat-space) * 2));
  max-width: var(--mirai-chat-content-max-width);
}

.warning {
  bottom: calc(var(--mirai-chat-space) / 2);
  position: absolute;
}

@media print {
  .container {
    display: none;
  }
}
