.buttonDispatcher {
  bottom: var(--mirai-chat-space);
  position: fixed;
  right: var(--mirai-chat-space);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  transform: translateY(250%);
}

.buttonDispatcher.left {
  left: var(--mirai-chat-space);
}

.buttonDispatcher.right {
  right: var(--mirai-chat-space);
}

.buttonDispatcher.top {
  top: var(--mirai-chat-space);
  transform: translateY(-25svh);
}

.buttonDispatcher.bottom {
  bottom: var(--mirai-chat-space);
  transform: translateY(25svh);
}

.buttonDispatcher.visible {
  transform: translateY(0%);
  z-index: var(--mirai-chat-layer);
}

.buttonDispatcher .avatar {
  box-shadow: var(--mirai-chat-box-shadow);
}

.buttonDispatcher .welcome {
  background-color: var(--mirai-chat-background);
  border-radius: var(--mirai-chat-border-radius);
  box-shadow: var(--mirai-chat-box-shadow);
  margin-bottom: var(--mirai-chat-space);
  opacity: 0;
  padding: var(--mirai-chat-space);
  position: absolute;
  transform-origin: bottom right;
  transform: scale(0);
  transition: all var(--mirai-ui-motion-collapse) cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.buttonDispatcher.left .welcome {
  left: 0;
}

.buttonDispatcher.right .welcome {
  right: 0;
}

.buttonDispatcher.top .welcome {
  top: calc(var(--mirai-chat-avatar-size) + calc(var(--mirai-chat-space) / 2));
}

.buttonDispatcher.bottom .welcome {
  bottom: calc(var(--mirai-chat-avatar-size) - calc(var(--mirai-chat-space) / 2));
}

.buttonDispatcher .welcome.visible {
  opacity: 1;
  transform: scale(1);
}

.buttonDispatcher .welcome:before {
  border-style: solid;
  border-width: calc(var(--mirai-chat-space) / 2);
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.buttonDispatcher.left .welcome:before {
  left: var(--mirai-ui-space-L);
}

.buttonDispatcher.right .welcome:before {
  right: var(--mirai-ui-space-L);
}

.buttonDispatcher.top .welcome:before {
  border-color: transparent transparent var(--mirai-chat-background) transparent;
  top: calc(var(--mirai-chat-space) / -1);
}

.buttonDispatcher.bottom .welcome:before {
  border-color: var(--mirai-chat-background) transparent transparent transparent;
  bottom: calc(var(--mirai-chat-space) / -1);
}

.buttonDispatcher .hi {
  animation: textGradient 1.5s linear infinite alternate;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--mirai-chat-avatar-color-2),
    var(--mirai-chat-avatar-color-3),
    var(--mirai-chat-avatar-color-2)
  );
  background-size: 200% 100%;
  color: transparent;
  margin-bottom: var(--mirai-ui-space-XXS);
  -webkit-background-clip: text;
}

/* S */
@media only screen and (max-width: 480px) {
  .welcome {
    width: calc(100vw - calc(var(--mirai-ui-space-M) * 2));
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .welcome {
    width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-XXL) * 2));
  }
}

@keyframes textGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
