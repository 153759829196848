.avatar,
.image {
  background-size: cover;
  border-radius: var(--mirai-chat-avatar-border-radius);
  height: var(--mirai-chat-avatar-size);
  overflow: hidden;
  position: relative;
  width: var(--mirai-chat-avatar-size);
  z-index: -1;
}

.avatar.small,
.image.small {
  height: calc(var(--mirai-chat-avatar-size) / 2);
  min-height: calc(var(--mirai-chat-avatar-size) / 2);
  min-width: calc(var(--mirai-chat-avatar-size) / 2);
  width: calc(var(--mirai-chat-avatar-size) / 2);
}

.avatar {
  animation: avatarRotate 5s infinite linear, avatarGlow 2.5s infinite ease-in-out;
  background: radial-gradient(
    circle at 50% 50%,
    var(--mirai-chat-avatar-color-1),
    var(--mirai-chat-avatar-color-2) 40%,
    var(--mirai-chat-avatar-color-3) 70%,
    var(--mirai-chat-avatar-color-4) 90%
  );
  background-size: 200% 200%;
  box-shadow: 0 0 4px var(--mirai-chat-avatar-color-2), 0 0 8px var(--mirai-chat-avatar-color-3);
  overflow: hidden;
  position: relative;
}

.avatar.small {
  animation: avatarRotate 5s infinite linear, avatarGlowSmall 2.5s infinite ease-in-out;
  box-shadow: 0 0 2px var(--mirai-chat-avatar-color-2), 0 0 4px var(--mirai-chat-avatar-color-3);
}

.avatar .face {
  background-color: var(--mirai-chat-avatar-background);
  border-radius: var(--mirai-chat-avatar-border-radius);
  height: 88%;
  left: 6%;
  position: absolute;
  top: 6%;
  width: 88%;
}

.avatar.small .face {
  height: 86%;
  left: 7%;
  top: 7%;
  width: 86%;
}

.eyes {
  bottom: 27.5%;
  height: 17.5%;
  left: 0;
  position: absolute;
  right: 0;
  transition: transform 40ms;
}

.eye {
  animation: blink 8s infinite;
  background-color: #000;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 17.5%;
}

.eye.left {
  left: 27.5%;
}

.eye.right {
  right: 27.5%;
}

.eye::after {
  background-color: white;
  border-radius: 50%;
  content: '';
  height: 45%;
  left: 0%;
  top: 0%;
  position: absolute;
  width: 45%;
}

.avatar:not(.small) .eye {
  box-shadow: 0 0 0 6px #fff;
}

.avatar.small .eye {
  box-shadow: 0 0 0 3px #fff;
}

@keyframes avatarRotate {
  0% {
    background-position: 20% 20%;
  }
  25% {
    background-position: 80% 20%;
  }
  50% {
    background-position: 80% 80%;
  }
  75% {
    background-position: 20% 80%;
  }
  100% {
    background-position: 20% 20%;
  }
}

@keyframes avatarGlow {
  0%,
  100% {
    box-shadow: 0 0 16px var(--mirai-chat-avatar-color-2), 0 0 32px var(--mirai-chat-avatar-color-3);
  }
  50% {
    box-shadow: 0 0 24px var(--mirai-chat-avatar-color-1), 0 0 48px var(--mirai-chat-avatar-color-4);
  }
}

@keyframes avatarGlowSmall {
  0%,
  100% {
    box-shadow: 0 0 2px var(--mirai-chat-avatar-color-2), 0 0 4px var(--mirai-chat-avatar-color-3);
  }
  50% {
    box-shadow: 0 0 4px var(--mirai-chat-avatar-color-1), 0 0 8px var(--mirai-chat-avatar-color-4);
  }
}

@keyframes blink {
  0%,
  100% {
    transform: scaleY(100%);
  }
  /* double-blink */
  31% {
    transform: scaleY(100%);
  }
  32% {
    transform: scaleY(15%);
  }
  33% {
    transform: scaleY(100%);
  }
  34% {
    transform: scaleY(15%);
  }
  35% {
    transform: scaleY(100%);
  }
  /* single-blink  */
  61% {
    transform: scaleY(100%);
  }
  62% {
    transform: scaleY(15%);
  }
  63% {
    transform: scaleY(100%);
  }
}
