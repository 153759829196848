.container {
  background-color: var(--mirai-ui-content-background);
  width: 100vw;
}

.content {
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

.info {
  gap: var(--mirai-ui-space-XXS);
}

.info .logo {
  filter: grayscale(1);
  max-height: var(--mirai-ui-font-size-headline-2);
  opacity: 0.8;
}

.info .logo:hover {
  filter: grayscale(0);
  opacity: 1;
}

.info .copyright {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.info .link {
  color: var(--mirai-ui-content-light);
  display: block;
  text-transform: capitalize;
  text-wrap: nowrap;
  position: static;
}

.info .copyright .link {
  margin-left: var(--mirai-ui-space-XXS);
}

.powered {
  color: var(--mirai-ui-content-light) !important;
  text-decoration: none;
  position: static;
}

.powered,
.powered * {
  transition: color var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.powered:hover {
  color: var(--mirai-ui-content) !important;
}

.powered:hover .mirai {
  color: #de1223;
}

.powered .mirai {
  margin-left: var(--mirai-ui-space-XXS);
}

/* S */
@media only screen and (max-width: 480px) {
  .content {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-M);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    padding: 0 var(--mirai-ui-space-M);
  }
  .content {
    padding: var(--mirai-ui-space-L) 0;
  }
}
