.progress {
  background-color: var(--mirai-ui-progress-background);
  height: 0;
  min-height: 0;
  overflow: hidden;
  transition: height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    min-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
}

.progress.visible {
  height: var(--mirai-ui-progress-height);
  min-height: var(--mirai-ui-progress-height);
}

.value {
  background-color: var(--mirai-ui-progress-color);
  height: 100%;
  transition: all var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 0%;
}

.value.indeterminate {
  animation: mirai-progress-indeterminate var(--mirai-ui-progress-motion) infinite var(--mirai-ui-motion-easing)
    alternate;
  transform-origin: 0% 50%;
  width: 100%;
}
