.calendar.vertical {
  max-height: calc(var(--mirai-ui-calendar-cell) * 9.75);
}

.scrollview {
  direction: var(--mirai-ui-text-direction);
  height: 100%;
  width: 100%;
}

.calendar:not(.vertical) .scrollview {
  gap: calc(var(--mirai-ui-calendar-padding) * 0.5);
}

.month {
  justify-content: flex-start;
  padding: var(--mirai-ui-calendar-padding);
  width: calc(var(--mirai-ui-calendar-cell) * 7);
}

.calendar.vertical .month {
  margin-left: 0;
  padding-bottom: 0;
  width: 100%;
}

.header {
  min-height: calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-XS));
}

.title {
  flex: 1;
  text-align: center;
  text-transform: capitalize;
}

.cell {
  flex: 1;
  min-width: 0;
  overflow: visible;
  text-align: center;
}

.cell:not(.weekday) {
  margin-bottom: calc(var(--mirai-ui-space-XXS) / 2);
}

.vertical .weekdays {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  padding: calc(var(--mirai-ui-calendar-padding) / 2) var(--mirai-ui-calendar-padding);
}

.calendar:not(.vertical) .weekdays {
  margin: var(--mirai-ui-calendar-padding) 0;
}

.weekday {
  color: var(--mirai-ui-calendar-weekday-color);
  text-transform: capitalize;
}

.day {
  align-items: center;
  height: var(--mirai-ui-calendar-cell);
  justify-content: center;
  margin: 0 auto;
}

.vertical .day {
  width: auto;
}

.calendar:not(.vertical) .day {
  width: var(--mirai-ui-calendar-cell);
}

.dayRange:not(.daySelected) {
  background-color: var(--mirai-ui-calendar-range-background);
}

.daySelected {
  background-color: var(--mirai-ui-calendar-selected-background);
}

.daySelectedStart {
  border-bottom-left-radius: var(--mirai-ui-calendar-cell-border-radius);
  border-top-left-radius: var(--mirai-ui-calendar-cell-border-radius);
}

.daySelectedEnd {
  border-bottom-right-radius: var(--mirai-ui-calendar-cell-border-radius);
  border-top-right-radius: var(--mirai-ui-calendar-cell-border-radius);
}

.textDisabled {
  color: var(--mirai-ui-content-border);
}

.textHighlight {
  color: var(--mirai-ui-calendar-highlight-color);
}

.daySelected .textSelected {
  color: var(--mirai-ui-base);
}

.caption {
  height: var(--mirai-ui-line-height-tiny);
  max-width: calc(var(--mirai-ui-calendar-cell) * 0.85);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.caption.color {
  color: var(--mirai-ui-calendar-caption-color);
}

.caption.hide {
  color: transparent;
}

/* M & L */
@media only screen and (min-width: 481px) {
  .dayTouchable:not(.dayRanging):hover {
    background-color: var(--mirai-ui-calendar-selected-background);
    border-radius: var(--mirai-ui-calendar-cell-border-radius);
  }

  .dayTouchable.dayRanging:hover {
    background-color: var(--mirai-ui-calendar-selected-background);
    border-bottom-right-radius: var(--mirai-ui-calendar-cell-border-radius);
    border-top-right-radius: var(--mirai-ui-calendar-cell-border-radius);
  }

  .dayTouchable:hover > * {
    color: var(--mirai-ui-calendar-selected-color);
  }
}

/* -- reset defaults -------------------------------------------------------- */
.month .title {
  margin: 0;
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  :root {
    --mirai-ui-calendar-cell: calc(var(--mirai-ui-space-XL) - var(--mirai-ui-space-XS));
  }
}
