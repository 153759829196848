.pressable.center[data-role] {
  justify-content: center;
}

.pressable.wide {
  width: 100%;
}

.modal {
  height: 100%;
  width: 100%;
  max-height: 770px;
  max-width: 1200px;
}

/* S */
@media only screen and (max-width: 480px) {
  .pressable {
    flex-grow: 1;
  }
}
