.chart {
  align-items: flex-end;
  justify-content: stretch;
}

.chart .value {
  background-color: var(--mirai-ui-content);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  flex: 1;
}

.chart .value.disabled {
  background-color: var(--mirai-ui-content-border);
}

.range {
  background-color: var(--mirai-ui-content);
  height: var(--mirai-ui-border-width);
  margin-bottom: var(--mirai-ui-space-S);
  position: relative;
}

.range .input {
  background-color: transparent;
}

.range .input::-webkit-slider-thumb,
.range .input::-moz-range-thumb,
.range .input::-ms-thumb {
  appearance: none;
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content);
  border-radius: 50%;
  cursor: pointer;
  height: var(--mirai-ui-space-L);
  left: 0;
  margin-top: calc(calc(var(--mirai-ui-space-L) - 1px) / -2);
  right: 0;
  top: 0;
  width: var(--mirai-ui-space-L);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.range .input:active::-webkit-slider-thumb,
.range .input:active::-moz-range-thumb,
.range .input:active::-ms-thumb {
  box-shadow: var(--mirai-ui-shadow);
  transform: scale(1.25);
}

.range .input::-webkit-slider-runnable-track,
.range .input::-moz-range-track,
.range .input::-ms-track {
  width: 100%;
  height: 0px;
  background-color: transparent;
  border-color: transparent;
  color: transparent;
}

.range .input::-ms-fill-lower {
  background-color: var(--mirai-ui-content);
}

.range .input::-ms-fill-upper {
  background-color: var(--mirai-ui-content);
}

.inputNumbers {
  gap: var(--mirai-ui-space-M);
}

.inputNumbers .input {
  margin: 0;
}

/* S */
@media only screen and (max-width: 480px) {
  .chart {
    gap: var(--mirai-ui-space-XS);
    height: var(--mirai-ui-space-XXL);
    padding: 0 var(--mirai-ui-space-XS);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .chart {
    gap: var(--mirai-ui-space-S);
    height: calc(var(--mirai-ui-space-XXL) * 2);
    padding: 0 var(--mirai-ui-space-S);
  }
}
