.modal {
  height: 100%;
  width: 100%;
  max-height: 770px;
  max-width: 1170px;
}

.action {
  display: inline;
}

