.divider {
  max-height: 1px;
  margin: 0 var(--mirai-ui-space-XS);
}

.item .bullet {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.item .bullet.success {
  color: var(--mirai-ui-base);
}

/* S */
@media only screen and (max-width: 480px) {
  .divider {
    flex: 1;
    min-width: 3%;
  }

  .item {
    gap: var(--mirai-ui-space-XXS);
  }

  .item .bullet {
    height: var(--mirai-ui-space-M);
    max-width: var(--mirai-ui-space-M);
    min-width: var(--mirai-ui-space-M);
    line-height: var(--mirai-ui-space-M);
  }
}

/* S & M */
@media only screen and (max-width: 1179px) {
  .bookingProcess {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    justify-content: center;
    padding: var(--mirai-ui-space-S);
    width: 100%;
  }

  .divider {
    border: dashed 1px var(--mirai-ui-content-border);
  }

  .item .bullet {
    background-color: var(--mirai-ui-content-border);
    color: var(--mirai-ui-content-light);
  }

  .item .bullet.success {
    background-color: var(--mirai-ui-accent);
  }

  .item .label:not(.disabled) {
    color: var(--mirai-ui-content);
  }

  .item .label.disabled {
    color: var(--mirai-ui-content-light);
  }
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .divider {
    flex: 1;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .item {
    gap: var(--mirai-ui-space-XS);
  }

  .item .bullet {
    height: var(--mirai-ui-space-L);
    max-width: var(--mirai-ui-space-L);
    min-width: var(--mirai-ui-space-L);
    line-height: var(--mirai-ui-space-L);
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .divider {
    border: dashed 1px var(--mirai-header-border-color);
    width: var(--mirai-ui-space-L);
  }

  .item .bullet {
    background-color: var(--mirai-header-border-color);
    color: var(--mirai-header-color-disabled);
  }

  .item .bullet.success {
    background-color: var(--mirai-header-accent);
  }

  .item .label:not(.disabled) {
    color: var(--mirai-header-color);
  }

  .item .label.disabled {
    color: var(--mirai-header-color-disabled);
  }
}
