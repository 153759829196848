.menu.desktop {
  background-color: var(--mirai-ui-menu-background);
  border-radius: var(--mirai-ui-menu-border-radius);
  box-shadow: var(--mirai-ui-menu-shadow);
  min-width: var(--mirai-ui-menu-min-width);
  overflow: hidden;
}

.menu .option {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  cursor: default;
  flex: 1;
  flex-direction: row;
  padding: var(--mirai-ui-space-M);
  width: 100%;
}

.menu .option.value:hover:not(.disabled) {
  background-color: var(--mirai-ui-menu-option-background-active);
  cursor: pointer;
}

.menu .option.value:hover:not(.disabled) .icon,
.menu .option.value:hover:not(.disabled) .label {
  color: var(--mirai-ui-menu-option-color-active);
  cursor: pointer;
}

.menu .option .content {
  gap: var(--mirai-ui-space-M);
}

.menu .divider {
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-menu-option-color-disabled);
}

.menu .icon {
  color: var(--mirai-ui-content);
}

.menu .label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu .option.disabled .icon,
.menu .option.disabled .label {
  color: var(--mirai-ui-menu-option-color-disabled);
  cursor: default;
}

/* S */
@media only screen and (max-width: 480px) {
  .menu {
    padding-bottom: var(--mirai-ui-space-XS);
  }
}

@media only screen and (min-width: 481px) {
  .menu {
    background-color: var(--mirai-ui-menu-background);
    border-radius: var(--mirai-ui-menu-border-radius);
    box-shadow: var(--mirai-ui-menu-shadow);
    min-width: var(--mirai-ui-menu-min-width);
    overflow: hidden;
  }
}
