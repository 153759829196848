._3qK-PW_checkbox {
  position: relative;
}

._3qK-PW_checkbox input {
  background-color: var(--mirai-ui-checkbox-background);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-checkbox-border-color);
  border-radius: var(--mirai-ui-checkbox-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0;
}

._3qK-PW_checkbox input:disabled {
  border-color: var(--mirai-ui-checkbox-background-disabled);
}

._3qK-PW_checkbox input:disabled._3qK-PW_checked {
  background-color: var(--mirai-ui-checkbox-background-disabled);
}

._3qK-PW_checkbox input:not(:disabled)._3qK-PW_checked {
  background-color: var(--mirai-ui-checkbox-background-checked);
  border-color: var(--mirai-ui-checkbox-background-checked);
}

._3qK-PW_checkbox input:not(:disabled)._3qK-PW_error {
  border-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

._3qK-PW_checkbox input:not(:disabled):not(._3qK-PW_error):not(:checked):focus, ._3qK-PW_checkbox input:not(:disabled):not(._3qK-PW_error):not(:checked):hover {
  border-color: var(--mirai-ui-input-color-active);
  box-shadow: var(--mirai-ui-input-shadow-active);
}

._3qK-PW_checkbox input:not(:disabled)._3qK-PW_error:focus, ._3qK-PW_checkbox input:not(:disabled)._3qK-PW_error:hover {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

._3qK-PW_checkbox input:not(:disabled):checked:focus, ._3qK-PW_checkbox input:not(:disabled):checked:hover {
  box-shadow: var(--mirai-ui-input-shadow-active);
}

._3qK-PW_checkbox input._3qK-PW_input {
  margin-right: 0;
  top: 0;
}

@media only screen and (max-width: 480px) {
  ._3qK-PW_checkbox {
    height: var(--mirai-ui-checkbox-size-mobile);
    width: var(--mirai-ui-checkbox-size-mobile);
    font-size: var(--mirai-ui-checkbox-size-mobile);
    line-height: var(--mirai-ui-checkbox-size-mobile);
  }

  ._3qK-PW_checkbox input {
    height: var(--mirai-ui-checkbox-size-mobile);
    width: var(--mirai-ui-checkbox-size-mobile);
  }
}

@media only screen and (min-width: 481px) {
  ._3qK-PW_checkbox {
    height: var(--mirai-ui-checkbox-size);
    width: var(--mirai-ui-checkbox-size);
    font-size: var(--mirai-ui-checkbox-size);
    line-height: var(--mirai-ui-checkbox-size);
  }

  ._3qK-PW_checkbox input {
    height: var(--mirai-ui-checkbox-size);
    width: var(--mirai-ui-checkbox-size);
  }
}

.l5vG4W_text {
  color: var(--mirai-ui-content);
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-font);
  font-weight: var(--mirai-ui-font-weight);
  text-align: var(--mirai-ui-text-align);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.l5vG4W_action {
  font-size: var(--mirai-ui-font-size-action);
  line-height: var(--mirai-ui-line-height-action);
}

.l5vG4W_bold:not(.l5vG4W_brand) {
  font-family: var(--mirai-ui-font-bold);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.l5vG4W_medium:not(.l5vG4W_brand) {
  font-family: var(--mirai-ui-font-medium);
  font-weight: var(--mirai-ui-font-medium-weight);
}

.l5vG4W_capitalize:first-letter {
  text-transform: uppercase;
}

.l5vG4W_brand {
  font-family: var(--mirai-ui-text-brand-font);
  font-weight: var(--mirai-ui-text-brand-font-weight);
}

.l5vG4W_brand.l5vG4W_bold {
  font-family: var(--mirai-ui-text-brand-font-bold);
  font-weight: var(--mirai-ui-text-brand-font-bold-weight);
}

.l5vG4W_brand.l5vG4W_medium {
  font-family: var(--mirai-ui-text-brand-font-medium);
  font-weight: var(--mirai-ui-text-brand-font-medium-weight);
}

.l5vG4W_headline-1 {
  font-size: var(--mirai-ui-font-size-headline-1);
  line-height: var(--mirai-ui-line-height-headline-1);
}

.l5vG4W_headline-2 {
  font-size: var(--mirai-ui-font-size-headline-2);
  line-height: var(--mirai-ui-line-height-headline-2);
}

.l5vG4W_headline-3 {
  font-size: var(--mirai-ui-font-size-headline-3);
  line-height: var(--mirai-ui-line-height-headline-3);
}

.l5vG4W_paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
  line-height: var(--mirai-ui-line-height-paragraph);
}

.l5vG4W_small {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
}

.l5vG4W_tiny {
  font-size: var(--mirai-ui-font-size-tiny);
  line-height: var(--mirai-ui-line-height-tiny);
}

.l5vG4W_underline {
  text-decoration: underline;
}

.l5vG4W_upperCase {
  text-transform: uppercase;
}

.l5vG4W_wide {
  width: 100%;
}

.l5vG4W_accent {
  color: var(--mirai-ui-accent);
}

.l5vG4W_accentDark {
  color: var(--mirai-ui-accent-dark);
}

.l5vG4W_accentLight {
  color: var(--mirai-ui-accent-light);
}

.l5vG4W_error {
  color: var(--mirai-ui-error);
}

.l5vG4W_info {
  color: var(--mirai-ui-info);
}

.l5vG4W_light {
  color: var(--mirai-ui-content-light);
}

.l5vG4W_success {
  color: var(--mirai-ui-success);
}

.l5vG4W_warning {
  color: var(--mirai-ui-warning);
}

.DwpEmq_icon {
  display: block;
}

.DwpEmq_action {
  font-size: var(--mirai-ui-font-size-action);
  height: var(--mirai-ui-font-size-action);
  line-height: var(--mirai-ui-font-size-action);
  width: var(--mirai-ui-font-size-action);
}

.DwpEmq_headline-1 {
  font-size: var(--mirai-ui-space-XL);
  height: var(--mirai-ui-space-XL);
  line-height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
}

.DwpEmq_headline-2 {
  font-size: var(--mirai-ui-font-size-headline-2);
  height: var(--mirai-ui-font-size-headline-2);
  line-height: var(--mirai-ui-font-size-headline-2);
  width: var(--mirai-ui-font-size-headline-2);
}

.DwpEmq_headline-3 {
  font-size: var(--mirai-ui-font-size-headline-3);
  height: var(--mirai-ui-font-size-headline-3);
  line-height: var(--mirai-ui-font-size-headline-3);
  width: var(--mirai-ui-font-size-headline-3);
}

.DwpEmq_paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
  height: var(--mirai-ui-font-size-paragraph);
  line-height: var(--mirai-ui-font-size-paragraph);
  width: var(--mirai-ui-font-size-paragraph);
}

.DwpEmq_small {
  font-size: var(--mirai-ui-font-size-small);
  height: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-font-size-small);
  width: var(--mirai-ui-font-size-small);
}

.I2Uiuq_input {
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-input-background);
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  text-align: var(--mirai-ui-text-align);
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
}

.I2Uiuq_input.I2Uiuq_autoResize {
  resize: none;
  min-height: auto;
  overflow-y: hidden;
}

.I2Uiuq_input:disabled, .I2Uiuq_input::placeholder {
  color: var(--mirai-ui-input-color-disabled);
}

input.I2Uiuq_input, textarea.I2Uiuq_input {
  border: none;
  margin: 0;
}

input.I2Uiuq_input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input.I2Uiuq_input::-ms-clear {
  -webkit-appearance: none;
  appearance: none;
}

input.I2Uiuq_input:focus:required:invalid:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  -webkit-text-fill-color: var(--mirai-ui-input-color);
  transition: none;
}

input.I2Uiuq_input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  -webkit-text-fill-color: var(--mirai-ui-input-color);
  transition: none;
}

input.I2Uiuq_input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  -webkit-text-fill-color: var(--mirai-ui-input-color);
  transition: none;
}

input.I2Uiuq_input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  -webkit-text-fill-color: var(--mirai-ui-input-color);
  transition: none;
}

input.I2Uiuq_input:focus:required:invalid {
  color: var(--mirai-ui-input-color);
}

.jgiT-G_container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.jgiT-G_layer {
  z-index: var(--mirai-ui-layer-content);
}

.jgiT-G_layer:not(.jgiT-G_fixed) {
  position: absolute;
}

.jgiT-G_layer.jgiT-G_fixed {
  position: fixed;
}

.jgiT-G_layer.jgiT-G_hidden {
  visibility: hidden;
  display: none;
}

.nC5E9W_pressable {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background-color: #0000;
  border: none;
  outline: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.nC5E9W_pressable:focus-visible {
  border-radius: var(--mirai-ui-border-radius);
  outline: var(--mirai-ui-focus-width) solid var(--mirai-ui-focus-color);
  outline-offset: calc(var(--mirai-ui-focus-width) * -.5);
  z-index: var(--mirai-ui-layer-S);
}

.nC5E9W_pressable.nC5E9W_disabled, .nC5E9W_pressable:disabled {
  cursor: unset;
  outline: none;
}

.n3DDtW_radio {
  position: relative;
}

.n3DDtW_radio input {
  background-color: var(--mirai-ui-radio-background);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-border-color);
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  outline: none;
  margin: 0;
}

.n3DDtW_radio .n3DDtW_checkmark {
  margin: calc(var(--mirai-ui-radio-size) / 4);
  height: calc(var(--mirai-ui-radio-size) / 2);
  pointer-events: none;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: calc(var(--mirai-ui-radio-size) / 2);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.n3DDtW_radio input:disabled {
  border-color: var(--mirai-ui-radio-background-disabled);
}

.n3DDtW_radio input:checked:not(:disabled) {
  background-color: var(--mirai-ui-radio-background-checked);
  border-color: var(--mirai-ui-radio-background-checked);
}

.n3DDtW_radio input:checked:disabled ~ .n3DDtW_checkmark {
  background-color: var(--mirai-ui-radio-background-disabled);
}

.n3DDtW_radio input:checked:not(:disabled) ~ .n3DDtW_checkmark {
  background-color: var(--mirai-ui-radio-background);
}

.n3DDtW_radio input:not(:disabled).n3DDtW_error {
  border-color: var(--mirai-ui-error);
}

.n3DDtW_radio input:not(:disabled):not(.n3DDtW_error):not(:checked):focus, .n3DDtW_radio input:not(:disabled):not(.n3DDtW_error):not(:checked):hover {
  border-color: var(--mirai-ui-input-color-active);
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.n3DDtW_radio input:not(:disabled).n3DDtW_error:focus, .n3DDtW_radio input:not(:disabled).n3DDtW_error:hover {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

.n3DDtW_radio input:not(:disabled):checked:focus, .n3DDtW_radio input:not(:disabled):checked:hover {
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.n3DDtW_radio input.n3DDtW_input {
  margin-right: 0;
  top: 0;
}

@media only screen and (max-width: 480px) {
  .n3DDtW_radio, .n3DDtW_radio input {
    height: var(--mirai-ui-radio-size-mobile);
    width: var(--mirai-ui-radio-size-mobile);
  }

  .n3DDtW_radio .n3DDtW_checkmark {
    margin: calc(var(--mirai-ui-radio-size-mobile) / 4);
    height: calc(var(--mirai-ui-radio-size-mobile) / 2);
    width: calc(var(--mirai-ui-radio-size-mobile) / 2);
  }
}

@media only screen and (min-width: 481px) {
  .n3DDtW_radio, .n3DDtW_radio input {
    height: var(--mirai-ui-radio-size);
    width: var(--mirai-ui-radio-size);
  }

  .n3DDtW_radio .n3DDtW_checkmark {
    margin: calc(var(--mirai-ui-radio-size) / 4);
    height: calc(var(--mirai-ui-radio-size) / 2);
    width: calc(var(--mirai-ui-radio-size) / 2);
  }
}

.HcoFjW_view {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
}

.HcoFjW_fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.HcoFjW_row, .HcoFjW_forceRow {
  align-items: center;
}

.HcoFjW_row {
  flex-direction: var(--mirai-ui-row-direction);
}

.HcoFjW_forceRow {
  flex-direction: row;
}

.HcoFjW_wide {
  width: 100%;
}

.Pw7v4G_scrollview {
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.Pw7v4G_scrollview.Pw7v4G_notIndicator {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Pw7v4G_scrollview.Pw7v4G_notIndicator::-webkit-scrollbar {
  display: none;
}

.Pw7v4G_scrollview.Pw7v4G_horizontal {
  direction: var(--mirai-ui-text-direction);
  flex-direction: row;
  overflow-x: scroll;
}

.Pw7v4G_scrollview.Pw7v4G_vertical {
  flex-direction: column;
  overflow-y: scroll;
}

.Pw7v4G_scrollview.Pw7v4G_snap.Pw7v4G_vertical {
  scroll-snap-type: y mandatory;
}

.Pw7v4G_scrollview.Pw7v4G_snap.Pw7v4G_horizontal {
  scroll-snap-type: x mandatory;
}

.Pw7v4G_scrollview.Pw7v4G_snap > * {
  scroll-snap-align: start;
}

.Pw7v4G_scrollview .Pw7v4G_indicator {
  background-color: var(--mirai-ui-base);
  box-shadow: var(--mirai-ui-shadow);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
}

.Pw7v4G_indicator.Pw7v4G_horizontal {
  right: var(--mirai-ui-space-M);
  transform-origin: top;
  top: 50%;
  transform: scale(1)translateY(-50%);
}

.Pw7v4G_indicator.Pw7v4G_vertical {
  bottom: var(--mirai-ui-space-M);
  transform-origin: 0;
  left: 50%;
  transform: scale(1)translateX(-50%);
}

.Pw7v4G_indicator.Pw7v4G_horizontal.Pw7v4G_hide {
  transform: scale(0)translateY(-50%);
}

.Pw7v4G_indicator.Pw7v4G_vertical.Pw7v4G_hide {
  transform: scale(0)translateX(-50%);
}

.Pw7v4G_indicator .Pw7v4G_progress {
  background-color: var(--mirai-ui-content-border);
  width: 100%;
  height: 0%;
  position: absolute;
  top: 0;
  left: 0;
}

.Pw7v4G_indicator .Pw7v4G_icon {
  z-index: var(--mirai-ui-layer-M);
}

@media only screen and (max-width: 480px) {
  .Pw7v4G_scrollview .Pw7v4G_indicator {
    min-height: var(--mirai-ui-button-small-height);
    min-width: var(--mirai-ui-button-small-height);
  }
}

@media only screen and (min-width: 481px) {
  .Pw7v4G_scrollview .Pw7v4G_indicator {
    min-height: var(--mirai-ui-button-height);
    min-width: var(--mirai-ui-button-height);
  }
}

.iIfbuW_select {
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-input-background);
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  cursor: pointer;
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  text-align: var(--mirai-ui-text-align);
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  width: 100%;
  margin: 0;
}

.iIfbuW_select.iIfbuW_empty:not(:focus) {
  color: var(--mirai-ui-input-color-disabled);
}

.iIfbuW_select:disabled {
  color: var(--mirai-ui-input-color-disabled);
  opacity: 1;
}

.HenTcW_switch {
  box-sizing: border-box;
  align-self: flex-start;
}

.HenTcW_switch input {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  visibility: hidden;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  width: 0;
  height: 0;
  position: absolute;
}

.HenTcW_switch .HenTcW_area {
  background-color: var(--mirai-ui-border-color);
  border-radius: var(--mirai-ui-switch-border-radius);
  cursor: pointer;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.HenTcW_switch:focus .HenTcW_area, .HenTcW_switch:hover .HenTcW_area {
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.HenTcW_switch .HenTcW_area.HenTcW_error {
  background-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

.HenTcW_switch .HenTcW_toggle {
  background-color: var(--mirai-ui-switch-background);
  border-radius: calc(var(--mirai-ui-switch-border-radius)  - var(--mirai-ui-switch-border-size));
  left: var(--mirai-ui-switch-border-size);
  top: var(--mirai-ui-switch-border-size);
  transition: var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  position: absolute;
}

.HenTcW_switch input:checked + .HenTcW_area {
  background-color: var(--mirai-ui-switch-background-checked);
}

.HenTcW_switch input:disabled + .HenTcW_area {
  background-color: var(--mirai-ui-switch-background-disabled);
  cursor: default;
}

.HenTcW_switch input:checked + .HenTcW_area .HenTcW_toggle {
  left: calc(100% - var(--mirai-ui-switch-border-size));
  transform: translateX(-100%);
}

@media only screen and (max-width: 480px) {
  .HenTcW_switch .HenTcW_area {
    height: calc(var(--mirai-ui-switch-size-mobile)  + calc(var(--mirai-ui-switch-border-size) * 2));
    width: calc(var(--mirai-ui-switch-size-mobile) * 2.4);
  }

  .HenTcW_switch .HenTcW_toggle {
    height: var(--mirai-ui-switch-size-mobile);
    width: var(--mirai-ui-switch-size-mobile);
  }
}

@media only screen and (min-width: 481px) {
  .HenTcW_switch .HenTcW_area {
    height: calc(var(--mirai-ui-switch-size)  + calc(var(--mirai-ui-switch-border-size) * 2));
    width: calc(var(--mirai-ui-switch-size) * 2.4);
  }

  .HenTcW_switch .HenTcW_toggle {
    height: var(--mirai-ui-switch-size);
    width: var(--mirai-ui-switch-size);
  }

  .HenTcW_switch:hover input:not(:checked):not(:disabled) + .HenTcW_area {
    background-color: var(--mirai-ui-input-color-active);
    box-shadow: var(--mirai-ui-input-shadow-active-error);
  }
}

.FCHFva_action {
  color: var(--mirai-ui-action-color);
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  font-weight: var(--mirai-ui-action-font-weight);
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.FCHFva_action:focus-visible {
  outline-offset: calc(var(--mirai-ui-focus-width) / 2);
}

.FCHFva_action.FCHFva_bold {
  font-family: var(--mirai-ui-font-bold);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.FCHFva_action.FCHFva_disabled, .FCHFva_action.FCHFva_disabled * {
  color: var(--mirai-ui-action-color-disabled);
}

.FCHFva_action:not(.FCHFva_disabled):focus, .FCHFva_action:not(.FCHFva_disabled):hover {
  color: var(--mirai-ui-focus-color);
}

.FCHFva_paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
}

.FCHFva_small {
  font-size: var(--mirai-ui-font-size-small);
}

.FCHFva_tiny {
  font-size: var(--mirai-ui-font-size-tiny);
}

.FCHFva_underline, .FCHFva_underline * {
  text-decoration: underline;
}

.FCHFva_wide {
  width: 100%;
}

.FCHFva_action * {
  margin-right: var(--mirai-ui-space-XXS);
}

.TvyzcG_dispatcher {
  cursor: pointer;
}

.TvyzcG_tooltip {
  background-color: var(--mirai-ui-tooltip-background);
  border-radius: var(--mirai-ui-tooltip-border-radius);
  margin-bottom: calc(var(--mirai-ui-tooltip-space) / 2);
  margin-top: calc(var(--mirai-ui-tooltip-space) / 2);
  max-width: var(--mirai-ui-tooltip-max-width);
  padding: var(--mirai-ui-tooltip-space);
  pointer-events: none;
}

.TvyzcG_tooltip:before {
  border-style: solid;
  border-width: calc(var(--mirai-ui-tooltip-space) / 2);
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.TvyzcG_tooltip:not(.TvyzcG_align):not(.TvyzcG_left):not(.TvyzcG_right):before {
  left: calc(50% - calc(var(--mirai-ui-tooltip-space) / 2));
}

.TvyzcG_tooltip.TvyzcG_top:not(.TvyzcG_align):before {
  border-color: var(--mirai-ui-tooltip-background) transparent transparent transparent;
  bottom: calc(calc(var(--mirai-ui-tooltip-space) / 2) * -1);
}

.TvyzcG_tooltip.TvyzcG_bottom:not(.TvyzcG_align):before {
  border-color: transparent transparent var(--mirai-ui-tooltip-background) transparent;
  top: calc(calc(var(--mirai-ui-tooltip-space) / 2) * -1);
}

.TvyzcG_tooltip.TvyzcG_left:not(.TvyzcG_align):before {
  left: calc(var(--mirai-ui-tooltip-space) * 2);
}

.TvyzcG_tooltip.TvyzcG_right:not(.TvyzcG_align):before {
  right: calc(var(--mirai-ui-tooltip-space) * 2);
}

.TvyzcG_tooltip.TvyzcG_align {
  margin-top: 0;
  margin-bottom: 0;
}

.TvyzcG_tooltip.TvyzcG_align.TvyzcG_left {
  right: calc(var(--mirai-ui-tooltip-space) / 2);
  position: relative;
}

.TvyzcG_tooltip.TvyzcG_align.TvyzcG_right {
  margin-left: calc(var(--mirai-ui-tooltip-space) / 2);
}

.TvyzcG_tooltip.TvyzcG_align:before {
  top: calc(50% - calc(var(--mirai-ui-tooltip-space) / 2));
}

.TvyzcG_tooltip.TvyzcG_align.TvyzcG_left:before {
  right: calc(var(--mirai-ui-tooltip-space) * -1);
  border-color: transparent transparent transparent var(--mirai-ui-tooltip-background);
}

.TvyzcG_tooltip.TvyzcG_align.TvyzcG_right:before {
  left: calc((var(--mirai-ui-tooltip-space) / 2) * -1);
  border-color: transparent var(--mirai-ui-tooltip-background) transparent transparent;
}

.TvyzcG_tooltip .TvyzcG_text {
  color: var(--mirai-ui-tooltip-color);
}

.ex0cka_button {
  background-color: var(--mirai-ui-button-background);
  border-radius: var(--mirai-ui-button-radius);
  color: var(--mirai-ui-button-color);
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  font-weight: var(--mirai-ui-button-font-weight);
  max-height: var(--mirai-ui-button-height);
  min-height: var(--mirai-ui-button-height);
  padding: 0 var(--mirai-ui-button-padding-x);
  text-align: center;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ex0cka_button, .ex0cka_button .ex0cka_children {
  gap: var(--mirai-ui-space-XXS);
}

.ex0cka_button.ex0cka_disabled:not(.ex0cka_busy) {
  background-color: var(--mirai-ui-button-color-disabled);
  color: var(--mirai-ui-button-disabled-color);
}

.ex0cka_secondary {
  background-color: var(--mirai-ui-button-secondary-background);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-button-secondary-color);
  color: var(--mirai-ui-button-secondary-color);
}

.ex0cka_button:not(.ex0cka_disabled).ex0cka_secondary:active, .ex0cka_button:not(.ex0cka_disabled).ex0cka_secondary:hover {
  background-color: var(--mirai-ui-button-secondary-background-active);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-button-secondary-color-active);
  color: var(--mirai-ui-button-secondary-color-active);
}

.ex0cka_transparent {
  color: var(--mirai-ui-button-transparent-color);
  background-color: #0000;
}

.ex0cka_transparent:not(.ex0cka_disabled):active, .ex0cka_transparent:not(.ex0cka_disabled):hover {
  background-color: var(--mirai-ui-button-transparent-background-active);
  color: var(--mirai-ui-button-transparent-color-active);
}

.ex0cka_button:not(.ex0cka_disabled):not(.ex0cka_secondary):not(.ex0cka_transparent):active, .ex0cka_button:not(.ex0cka_disabled):not(.ex0cka_secondary):not(.ex0cka_transparent):hover {
  background-color: var(--mirai-ui-button-background-active);
  color: var(--mirai-ui-button-color-active);
}

.ex0cka_large {
  font-size: var(--mirai-ui-font-size-paragraph);
  max-height: var(--mirai-ui-button-large-height);
  min-height: var(--mirai-ui-button-large-height);
}

.ex0cka_rounded {
  border-radius: var(--mirai-ui-space-XXL);
}

.ex0cka_small {
  font-size: var(--mirai-ui-font-size-small);
  max-height: var(--mirai-ui-button-small-height);
  min-height: var(--mirai-ui-button-small-height);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.ex0cka_squared {
  min-width: var(--mirai-ui-button-height);
  max-width: var(--mirai-ui-button-height);
  padding: 0;
}

.ex0cka_large.ex0cka_squared {
  min-width: var(--mirai-ui-button-large-height);
  max-width: var(--mirai-ui-button-large-height);
}

.ex0cka_small.ex0cka_squared {
  min-width: var(--mirai-ui-button-small-height);
  max-width: var(--mirai-ui-button-small-height);
}

.ex0cka_wide {
  width: auto;
}

.ex0cka_button .ex0cka_children, .ex0cka_button .ex0cka_spinner {
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.ex0cka_button.ex0cka_busy .ex0cka_children {
  opacity: 0;
  transform: translateY(var(--mirai-ui-space-XL));
}

.ex0cka_button .ex0cka_spinner {
  opacity: 0;
  transform: translateY(calc(var(--mirai-ui-space-XL) * -1));
}

.ex0cka_button.ex0cka_busy .ex0cka_spinner {
  opacity: 1;
  transform: translateY(0%);
}

.ex0cka_busyContainer {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.ex0cka_busyContainer:before {
  background-color: var(--mirai-ui-base);
  content: "";
  opacity: 0;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ex0cka_secondary:not(.ex0cka_disabled) .ex0cka_busyContainer:before {
  background-color: var(--mirai-ui-button-background);
}

.ex0cka_busyContainer.ex0cka_active:before {
  opacity: .2;
}

.ex0cka_spinner, .ex0cka_spinner:before, .ex0cka_spinner:after {
  animation: mirai-button-busy calc(var(--mirai-ui-motion-expand) * 3) infinite var(--mirai-ui-motion-easing) alternate;
  background-color: var(--mirai-ui-button-spinner-background);
  border-radius: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-XS);
  width: var(--mirai-ui-space-XS);
}

.ex0cka_squared .ex0cka_spinner, .ex0cka_squared .ex0cka_spinner:before, .ex0cka_squared .ex0cka_spinner:after {
  height: calc(var(--mirai-ui-space-XS) / 1.5);
  width: calc(var(--mirai-ui-space-XS) / 1.5);
}

.ex0cka_secondary .ex0cka_spinner, .ex0cka_secondary .ex0cka_spinner:before, .ex0cka_secondary .ex0cka_spinner:after {
  background-color: var(--mirai-ui-button-secondary-color);
  animation-name: ex0cka_mirai-button-secondary-busy;
}

.ex0cka_transparent .ex0cka_spinner, .ex0cka_transparent .ex0cka_spinner:before, .ex0cka_transparent .ex0cka_spinner:after {
  background-color: var(--mirai-ui-content-border);
  animation-name: ex0cka_mirai-button-transparent-busy;
}

.ex0cka_spinner:before, .ex0cka_spinner:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.ex0cka_spinner:before {
  left: calc(var(--mirai-ui-space-S) * -1);
  animation-delay: 0s;
}

.ex0cka_squared .ex0cka_spinner:before {
  left: calc(var(--mirai-ui-space-S) * -.75);
}

.ex0cka_spinner {
  animation-delay: calc(var(--mirai-ui-motion-expand) * 2);
}

.ex0cka_spinner:after {
  animation-delay: calc(var(--mirai-ui-motion-expand) * 3);
  left: var(--mirai-ui-space-S);
}

.ex0cka_squared .ex0cka_spinner:after {
  left: calc(var(--mirai-ui-space-S) * .75);
}

.NtOAYa_calendar.NtOAYa_vertical {
  max-height: calc(var(--mirai-ui-calendar-cell) * 9.75);
}

.NtOAYa_scrollview {
  direction: var(--mirai-ui-text-direction);
  width: 100%;
  height: 100%;
}

.NtOAYa_calendar:not(.NtOAYa_vertical) .NtOAYa_scrollview {
  gap: calc(var(--mirai-ui-calendar-padding) * .5);
}

.NtOAYa_month {
  padding: var(--mirai-ui-calendar-padding);
  width: calc(var(--mirai-ui-calendar-cell) * 7);
  justify-content: flex-start;
}

.NtOAYa_calendar.NtOAYa_vertical .NtOAYa_month {
  width: 100%;
  margin-left: 0;
  padding-bottom: 0;
}

.NtOAYa_header {
  min-height: calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-XS));
}

.NtOAYa_title {
  text-align: center;
  text-transform: capitalize;
  flex: 1;
}

.NtOAYa_cell {
  text-align: center;
  flex: 1;
  min-width: 0;
  overflow: visible;
}

.NtOAYa_cell:not(.NtOAYa_weekday) {
  margin-bottom: calc(var(--mirai-ui-space-XXS) / 2);
}

.NtOAYa_vertical .NtOAYa_weekdays {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  padding: calc(var(--mirai-ui-calendar-padding) / 2) var(--mirai-ui-calendar-padding);
}

.NtOAYa_calendar:not(.NtOAYa_vertical) .NtOAYa_weekdays {
  margin: var(--mirai-ui-calendar-padding) 0;
}

.NtOAYa_weekday {
  color: var(--mirai-ui-calendar-weekday-color);
  text-transform: capitalize;
}

.NtOAYa_day {
  height: var(--mirai-ui-calendar-cell);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.NtOAYa_vertical .NtOAYa_day {
  width: auto;
}

.NtOAYa_calendar:not(.NtOAYa_vertical) .NtOAYa_day {
  width: var(--mirai-ui-calendar-cell);
}

.NtOAYa_dayRange:not(.NtOAYa_daySelected) {
  background-color: var(--mirai-ui-calendar-range-background);
}

.NtOAYa_daySelected {
  background-color: var(--mirai-ui-calendar-selected-background);
}

.NtOAYa_daySelectedStart {
  border-bottom-left-radius: var(--mirai-ui-calendar-cell-border-radius);
  border-top-left-radius: var(--mirai-ui-calendar-cell-border-radius);
}

.NtOAYa_daySelectedEnd {
  border-bottom-right-radius: var(--mirai-ui-calendar-cell-border-radius);
  border-top-right-radius: var(--mirai-ui-calendar-cell-border-radius);
}

.NtOAYa_textDisabled {
  color: var(--mirai-ui-content-border);
}

.NtOAYa_textHighlight {
  color: var(--mirai-ui-calendar-highlight-color);
}

.NtOAYa_daySelected .NtOAYa_textSelected {
  color: var(--mirai-ui-base);
}

.NtOAYa_caption {
  height: var(--mirai-ui-line-height-tiny);
  max-width: calc(var(--mirai-ui-calendar-cell) * .85);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.NtOAYa_caption.NtOAYa_color {
  color: var(--mirai-ui-calendar-caption-color);
}

.NtOAYa_caption.NtOAYa_hide {
  color: #0000;
}

@media only screen and (min-width: 481px) {
  .NtOAYa_dayTouchable:not(.NtOAYa_dayRanging):hover {
    background-color: var(--mirai-ui-calendar-selected-background);
    border-radius: var(--mirai-ui-calendar-cell-border-radius);
  }

  .NtOAYa_dayTouchable.NtOAYa_dayRanging:hover {
    background-color: var(--mirai-ui-calendar-selected-background);
    border-bottom-right-radius: var(--mirai-ui-calendar-cell-border-radius);
    border-top-right-radius: var(--mirai-ui-calendar-cell-border-radius);
  }

  .NtOAYa_dayTouchable:hover > * {
    color: var(--mirai-ui-calendar-selected-color);
  }
}

.NtOAYa_month .NtOAYa_title {
  margin: 0;
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  :root {
    --mirai-ui-calendar-cell: calc(var(--mirai-ui-space-XL)  - var(--mirai-ui-space-XS));
  }
}

.Uta_jq_inputContainer {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
  flex: 1;
  position: relative;
}

.Uta_jq_inputBorder {
  border: var(--mirai-ui-input-text-border);
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-base);
  padding-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  transition: box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.Uta_jq_inputBorder.Uta_jq_disabled {
  border-color: var(--mirai-ui-input-text-color-disabled);
}

.Uta_jq_inputBorder.Uta_jq_error {
  border-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

.Uta_jq_inputBorder.Uta_jq_focus:not(.Uta_jq_error):not(.Uta_jq_warning):not(.Uta_jq_success) {
  border-color: var(--mirai-ui-input-color-active);
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.Uta_jq_inputBorder.Uta_jq_focus.Uta_jq_error {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

.Uta_jq_content {
  min-height: var(--mirai-ui-input-min-height);
  justify-content: center;
  position: relative;
}

.Uta_jq_icon {
  font-size: var(--mirai-ui-input-text-icon-size);
  height: var(--mirai-ui-input-text-icon-size);
  line-height: var(--mirai-ui-input-text-icon-size);
  width: var(--mirai-ui-input-text-icon-size);
  margin: 0 calc(var(--mirai-ui-input-text-padding-x) / 3);
}

.Uta_jq_icon.Uta_jq_left {
  color: var(--mirai-ui-input-color-disabled);
  pointer-events: none;
}

.Uta_jq_icon.Uta_jq_expand {
  pointer-events: none;
  z-index: var(--mirai-ui-layer-S);
}

.Uta_jq_icon.Uta_jq_expand:not(.Uta_jq_reverse) {
  margin-left: calc(var(--mirai-ui-input-text-icon-size) * -1);
}

.Uta_jq_icon.Uta_jq_expand.Uta_jq_reverse {
  margin-right: calc(var(--mirai-ui-input-text-icon-size) * -1);
}

.Uta_jq_icon.Uta_jq_expand, .Uta_jq_icon.Uta_jq_password {
  color: var(--mirai-ui-content);
}

.Uta_jq_input:not(.Uta_jq_multiLine) {
  padding: var(--mirai-ui-input-text-padding-y) calc(var(--mirai-ui-input-text-padding-x) / 2);
}

.Uta_jq_input.Uta_jq_multiLine {
  margin: var(--mirai-ui-input-text-padding-y) 0;
  padding: 0 calc(var(--mirai-ui-input-text-padding-x) / 2);
}

.Uta_jq_input.Uta_jq_withLabel:not(.Uta_jq_multiLine) {
  padding-top: calc(var(--mirai-ui-input-text-padding-y)  + var(--mirai-ui-space-S));
}

.Uta_jq_input.Uta_jq_withLabel.Uta_jq_multiLine {
  margin-top: calc(var(--mirai-ui-input-text-padding-y)  + var(--mirai-ui-space-M));
}

.Uta_jq_input.Uta_jq_empty:not(:focus) {
  color: var(--mirai-ui-base);
}

.Uta_jq_hint {
  margin: calc(var(--mirai-ui-input-text-padding-y) / 4) var(--mirai-ui-input-text-padding-x);
}

.Uta_jq_label {
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-input-background);
  font-size: var(--mirai-ui-input-font-size);
  left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  line-height: calc(var(--mirai-ui-input-min-height)  - var(--mirai-ui-border-width));
  pointer-events: none;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  text-overflow: ellipsis;
  top: var(--mirai-ui-border-width);
  transition: font-size var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), line-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), right var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), top var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
}

.Uta_jq_label.Uta_jq_withValue {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
  top: calc(var(--mirai-ui-space-XXS)  + var(--mirai-ui-border-width));
}

.Uta_jq_text {
  color: var(--mirai-ui-input-color-disabled);
}

.Uta_jq_text.Uta_jq_disabled, .Uta_jq_icon.Uta_jq_disabled {
  color: var(--mirai-ui-input-text-color-disabled);
}

.Uta_jq_text.Uta_jq_error {
  color: var(--mirai-ui-error);
}

.Uta_jq_text.Uta_jq_focus:not(.Uta_jq_error) {
  color: var(--mirai-ui-input-text-color-active);
}

.Uta_jq_text .Uta_jq_required {
  margin-left: var(--mirai-ui-space-XXS);
}

.Uta_jq_text .Uta_jq_required.Uta_jq_error {
  color: var(--mirai-ui-error);
}

.Uta_jq_caption {
  margin: 0 calc(var(--mirai-ui-input-text-padding-x) / 3);
}

.Uta_jq_icon.Uta_jq_error {
  color: var(--mirai-ui-error);
}

.Uta_jq_icon.Uta_jq_success {
  color: var(--mirai-ui-success);
}

.Uta_jq_icon.Uta_jq_warning {
  color: var(--mirai-ui-warning);
}

input.Uta_jq_input, textarea.Uta_jq_input {
  padding: var(--mirai-ui-input-text-padding-y) calc(var(--mirai-ui-input-text-padding-x) / 2);
  border: none;
  margin: 0;
}

.zxEaka_inputOption {
  flex-direction: var(--mirai-ui-row-direction);
  align-items: center;
  gap: var(--mirai-ui-input-option-gap);
  margin-bottom: var(--mirai-ui-input-option-margin-bottom);
  width: 100%;
  display: flex;
  position: relative;
}

.zxEaka_icon {
  color: var(--mirai-ui-base);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.zxEaka_label {
  color: var(--mirai-ui-input-text-color-active);
}

@media only screen and (max-width: 480px) {
  .zxEaka_icon {
    margin: calc(calc(var(--mirai-ui-checkbox-size-mobile)  - var(--mirai-ui-input-text-icon-size)) / 2);
  }
}

@media only screen and (min-width: 481px) {
  .zxEaka_icon {
    margin: var(--mirai-ui-border-width);
  }
}

.iUBYoW_inputNumber {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
}

.iUBYoW_texts {
  flex: 1;
}

.iUBYoW_value {
  color: var(--mirai-ui-input-color);
  text-align: center;
  width: var(--mirai-ui-space-XL);
  display: block;
}

.iUBYoW_value.iUBYoW_disabled {
  color: var(--mirai-ui-input-text-color-disabled);
}

.iUBYoW_label {
  color: var(--mirai-ui-input-text-color-active);
}

.iUBYoW_hint {
  margin: 0;
}

.x8Z40W_inputPhone {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
}

.x8Z40W_prefix {
  width: calc(var(--mirai-ui-space-XXL)  + var(--mirai-ui-space-XS));
  text-overflow: ellipsis;
  padding-right: 0;
  overflow: hidden;
}

.x8Z40W_prefixValue {
  background-color: var(--mirai-ui-base);
  bottom: var(--mirai-ui-input-text-padding-y);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  font-weight: inherit;
  left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  pointer-events: none;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}

.x8Z40W_separator {
  border-right: var(--mirai-ui-input-text-border);
  margin-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  margin-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding: var(--mirai-ui-space-M) 0;
}

[data-mirai-modal] {
  overflow: hidden;
}

.z3V4JG_container {
  pointer-events: none;
  z-index: var(--mirai-ui-modal-layer);
  justify-content: center;
  width: 100svw;
  height: 100svh;
  display: flex;
  position: fixed;
  left: 0;
}

.z3V4JG_container.z3V4JG_mobileBehavior {
  align-content: flex-end;
  align-items: flex-end;
  bottom: 0;
}

.z3V4JG_container.z3V4JG_visible {
  pointer-events: all;
}

.z3V4JG_header {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  align-items: center;
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-modal-header-padding) calc(var(--mirai-ui-modal-header-padding) / 1.5);
  width: 100%;
}

.z3V4JG_header:not(.z3V4JG_reverse).z3V4JG_hideBack, .z3V4JG_header.z3V4JG_reverse.z3V4JG_hideClose {
  padding-left: calc(var(--mirai-ui-modal-icon)  + var(--mirai-ui-space-M));
}

.z3V4JG_header:not(.z3V4JG_reverse).z3V4JG_hideClose, .z3V4JG_header.z3V4JG_reverse.z3V4JG_hideBack {
  padding-right: calc(var(--mirai-ui-modal-icon)  + var(--mirai-ui-space-M));
}

.z3V4JG_header .z3V4JG_title {
  padding: 0 var(--mirai-ui-modal-header-padding);
  text-align: center;
  flex: 1;
}

.z3V4JG_header .z3V4JG_icon {
  font-size: var(--mirai-ui-modal-icon);
  height: var(--mirai-ui-modal-icon);
  line-height: var(--mirai-ui-modal-icon);
  width: var(--mirai-ui-modal-icon);
}

.z3V4JG_modal {
  background-color: var(--mirai-ui-modal-background);
  cursor: default;
  pointer-events: none;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.z3V4JG_container.z3V4JG_mobileBehavior .z3V4JG_modal {
  transform: translateY(100%);
}

.z3V4JG_modal.z3V4JG_visible {
  box-shadow: var(--mirai-ui-modal-shadow);
  pointer-events: all;
}

.z3V4JG_container.z3V4JG_mobileBehavior .z3V4JG_modal.z3V4JG_visible {
  transform: translateY(0%);
}

.z3V4JG_overflow {
  background-color: var(--mirai-ui-modal-overflow-background);
  opacity: 0;
  transition: backdrop-filter var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  cursor: default;
}

.z3V4JG_overflow.z3V4JG_visible {
  opacity: 1;
}

.z3V4JG_overflow.z3V4JG_blur.z3V4JG_visible {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.z3V4JG_visible.z3V4JG_onOverflow {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .z3V4JG_container {
    align-content: flex-end;
    align-items: flex-end;
    height: 100svh;
    bottom: 0;
  }

  .z3V4JG_header {
    justify-content: flex-end;
  }

  .z3V4JG_modal {
    border-top-left-radius: var(--mirai-ui-modal-border-radius);
    border-top-right-radius: var(--mirai-ui-modal-border-radius);
    width: 100%;
    transform: translateY(100%);
  }

  .z3V4JG_modal.z3V4JG_visible {
    transform: translateY(0%);
  }

  .z3V4JG_overflow {
    transition-delay: calc(var(--mirai-ui-motion-expand) / 2);
  }

  .z3V4JG_overflow.z3V4JG_visible {
    transition-delay: 0s;
  }
}

@media only screen and (min-width: 481px) {
  .z3V4JG_container {
    align-items: center;
    top: 0;
    bottom: 0;
  }

  .z3V4JG_header {
    justify-content: flex-end;
  }

  .z3V4JG_modal:not(.z3V4JG_mobileMode) {
    border-radius: var(--mirai-ui-modal-border-radius);
    opacity: 0;
    transform: scale(1.1);
  }

  .z3V4JG_modal.z3V4JG_calcWidth {
    width: calc(var(--mirai-ui-breakpoint-content) / 3);
  }

  .z3V4JG_modal.z3V4JG_visible:not(.z3V4JG_mobileMode) {
    opacity: 1;
    transform: scale(1);
  }

  .z3V4JG_pressable {
    align-self: flex-end;
  }
}

.woFimq_menu.woFimq_desktop {
  background-color: var(--mirai-ui-menu-background);
  border-radius: var(--mirai-ui-menu-border-radius);
  box-shadow: var(--mirai-ui-menu-shadow);
  min-width: var(--mirai-ui-menu-min-width);
  overflow: hidden;
}

.woFimq_menu .woFimq_option {
  box-sizing: border-box;
  cursor: default;
  padding: var(--mirai-ui-space-M);
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;
  display: flex;
}

.woFimq_menu .woFimq_option.woFimq_value:hover:not(.woFimq_disabled) {
  background-color: var(--mirai-ui-menu-option-background-active);
  cursor: pointer;
}

.woFimq_menu .woFimq_option.woFimq_value:hover:not(.woFimq_disabled) .woFimq_icon, .woFimq_menu .woFimq_option.woFimq_value:hover:not(.woFimq_disabled) .woFimq_label {
  color: var(--mirai-ui-menu-option-color-active);
  cursor: pointer;
}

.woFimq_menu .woFimq_option .woFimq_content {
  gap: var(--mirai-ui-space-M);
}

.woFimq_menu .woFimq_divider {
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-menu-option-color-disabled);
}

.woFimq_menu .woFimq_icon {
  color: var(--mirai-ui-content);
}

.woFimq_menu .woFimq_label {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.woFimq_menu .woFimq_option.woFimq_disabled .woFimq_icon, .woFimq_menu .woFimq_option.woFimq_disabled .woFimq_label {
  color: var(--mirai-ui-menu-option-color-disabled);
  cursor: default;
}

@media only screen and (max-width: 480px) {
  .woFimq_menu {
    padding-bottom: var(--mirai-ui-space-XS);
  }
}

@media only screen and (min-width: 481px) {
  .woFimq_menu {
    background-color: var(--mirai-ui-menu-background);
    border-radius: var(--mirai-ui-menu-border-radius);
    box-shadow: var(--mirai-ui-menu-shadow);
    min-width: var(--mirai-ui-menu-min-width);
    overflow: hidden;
  }
}

._3UXiZa_notification {
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._3UXiZa_notification:not(._3UXiZa_large):not(._3UXiZa_small) {
  gap: var(--mirai-ui-space-XS);
}

._3UXiZa_notification._3UXiZa_small {
  gap: var(--mirai-ui-space-XXS);
}

._3UXiZa_notification._3UXiZa_large {
  gap: var(--mirai-ui-notification-padding);
  flex-direction: column;
  align-items: center;
}

._3UXiZa_notification._3UXiZa_wide {
  width: 100%;
}

._3UXiZa_notification ._3UXiZa_info {
  color: var(--mirai-ui-inysfo);
}

._3UXiZa_notification._3UXiZa_outlined {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-notification-border-radius);
  padding: var(--mirai-ui-notification-padding);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_large {
  padding: var(--mirai-ui-notification-large-padding);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_small {
  padding: var(--mirai-ui-notification-small-padding);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_contrast {
  background-color: var(--mirai-ui-content-dark);
  border-color: var(--mirai-ui-content-dark);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_error {
  background-color: var(--mirai-ui-error-background);
  border-color: var(--mirai-ui-error-border);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_warning {
  background-color: var(--mirai-ui-warning-background);
  border-color: var(--mirai-ui-warning-border);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_success {
  background-color: var(--mirai-ui-success-background);
  border-color: var(--mirai-ui-success-border);
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_info {
  background-color: var(--mirai-ui-info-background);
  border-color: var(--mirai-ui-info-border);
}

._3UXiZa_notification ._3UXiZa_texts {
  flex: 1;
}

._3UXiZa_notification._3UXiZa_large ._3UXiZa_texts {
  align-items: center;
  gap: var(--mirai-ui-notification-small-padding);
}

._3UXiZa_notification._3UXiZa_large ._3UXiZa_texts * {
  text-align: center;
}

._3UXiZa_notification._3UXiZa_outlined._3UXiZa_contrast * {
  color: var(--mirai-ui-base);
}

._3UXiZa_notification._3UXiZa_error * {
  color: var(--mirai-ui-error);
}

._3UXiZa_notification._3UXiZa_warning * {
  color: var(--mirai-ui-warning);
}

._3UXiZa_notification._3UXiZa_success * {
  color: var(--mirai-ui-success);
}

._3UXiZa_notification._3UXiZa_info * {
  color: var(--mirai-ui-info);
}

.sSDsNG_progress {
  background-color: var(--mirai-ui-progress-background);
  transition: height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), min-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
  height: 0;
  min-height: 0;
  overflow: hidden;
}

.sSDsNG_progress.sSDsNG_visible {
  height: var(--mirai-ui-progress-height);
  min-height: var(--mirai-ui-progress-height);
}

.sSDsNG_value {
  background-color: var(--mirai-ui-progress-color);
  transition: all var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 0%;
  height: 100%;
}

.sSDsNG_value.sSDsNG_indeterminate {
  animation: mirai-progress-indeterminate var(--mirai-ui-progress-motion) infinite var(--mirai-ui-motion-easing) alternate;
  transform-origin: 0%;
  width: 100%;
}

.yvgJSG_slider {
  background-color: var(--mirai-ui-content-border);
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.yvgJSG_button {
  background-color: var(--mirai-ui-slider-button-background);
  box-shadow: var(--mirai-ui-modal-shadow);
  height: var(--mirai-ui-button-small-height);
  top: calc(50% - calc(var(--mirai-ui-button-small-height) / 2));
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: var(--mirai-ui-button-small-height);
  z-index: var(--mirai-ui-layer-M);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.yvgJSG_button.yvgJSG_hide {
  transform: scale(0);
}

.yvgJSG_button .yvgJSG_icon {
  color: var(--mirai-ui-slider-icon-color);
}

.yvgJSG_button.yvgJSG_first {
  left: var(--mirai-ui-space-S);
}

.yvgJSG_button.yvgJSG_second {
  right: var(--mirai-ui-space-S);
}

.yvgJSG_item {
  position: relative;
}

.yvgJSG_item .yvgJSG_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.yvgJSG_item .yvgJSG_image.yvgJSG_portrait {
  background-size: contain;
}

.yvgJSG_item .yvgJSG_caption {
  bottom: var(--mirai-ui-space-S);
  left: var(--mirai-ui-space-S);
  right: var(--mirai-ui-space-S);
  align-items: center;
  position: absolute;
}

.yvgJSG_item .yvgJSG_caption .yvgJSG_text {
  color: var(--mirai-ui-slider-overlay-color);
}

.yvgJSG_counter {
  bottom: var(--mirai-ui-space-S);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  flex-direction: row;
  display: flex;
  position: absolute;
}

.yvgJSG_counter:not(.yvgJSG_reverse) {
  right: var(--mirai-ui-space-S);
}

.yvgJSG_counter.yvgJSG_reverse {
  left: var(--mirai-ui-space-S);
}

.yvgJSG_counter * {
  color: var(--mirai-ui-slider-color);
}

.yvgJSG_overlay {
  background-color: var(--mirai-ui-slider-overlay);
  border-radius: var(--mirai-ui-slider-border-radius);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

.yvgJSG_overlay * {
  color: var(--mirai-ui-slider-overlay-color);
}

.yvgJSG_progress {
  background-color: var(--mirai-ui-slider-overlay);
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.yvgJSG_progress.yvgJSG_visible {
  height: calc(var(--mirai-ui-space-XS) / 1);
}

@media only screen and (min-width: 481px) {
  .yvgJSG_item .yvgJSG_caption {
    transition: opacity var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
    opacity: 0;
  }

  .yvgJSG_item:hover .yvgJSG_caption {
    opacity: 1;
  }
}

.bnGtBa_container {
  width: 100%;
}

.bnGtBa_container.bnGtBa_outlined {
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  border-radius: var(--mirai-ui-table-border-radius);
}

.bnGtBa_table {
  background-color: var(--mirai-ui-table-background);
  border-collapse: collapse;
  color: var(--mirai-ui-table-color);
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  table-layout: auto;
  text-align: left;
  width: 100%;
}

.bnGtBa_table thead {
  background-color: var(--mirai-ui-table-background);
  box-shadow: 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  z-index: var(--mirai-ui-layer-L);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.bnGtBa_table thead tr {
  font-family: var(--mirai-ui-font-bold);
  font-size: var(--mirai-ui-font-size-small);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.bnGtBa_table tbody {
  font-size: var(--mirai-ui-font-size-action);
}

.bnGtBa_table tbody tr {
  scroll-snap-align: start;
}

.bnGtBa_table tbody tr.bnGtBa_selected {
  background-color: var(--mirai-ui-table-selected-background);
  color: var(--mirai-ui-table-selected-color);
}

.bnGtBa_table tbody tr.bnGtBa_selected td:first-child {
  background-color: var(--mirai-ui-table-selected-background);
}

.bnGtBa_table tbody tr:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
}

.bnGtBa_table th {
  -webkit-user-select: none;
  user-select: none;
}

.bnGtBa_table th, .bnGtBa_table td {
  padding: var(--mirai-ui-table-padding-y) var(--mirai-ui-table-padding-x) var(--mirai-ui-table-padding-y) 0;
}

.bnGtBa_table th:first-child, .bnGtBa_table td:first-child {
  background-color: var(--mirai-ui-table-background);
  padding-left: var(--mirai-ui-table-padding-x);
  z-index: var(--mirai-ui-layer-M);
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.bnGtBa_table th:last-child, .bnGtBa_table td:last-child {
  padding-right: var(--mirai-ui-table-padding-x);
}

.bnGtBa_table th .bnGtBa_icons {
  gap: var(--mirai-ui-space-XXS);
  margin-left: auto;
}

.bnGtBa_table th .bnGtBa_icon.bnGtBa_disabled {
  color: var(--mirai-ui-table-color-disabled);
}

.bnGtBa_table th .bnGtBa_column, .bnGtBa_table .bnGtBa_column.bnGtBa_selectable {
  gap: calc(var(--mirai-ui-table-padding-x) / 2);
}

.bnGtBa_table th .bnGtBa_tooltip {
  position: relative;
}

.bnGtBa_table .bnGtBa_column.bnGtBa_text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.bnGtBa_table .bnGtBa_column.bnGtBa_number {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.bnGtBa_table .bnGtBa_column.bnGtBa_component {
  z-index: var(--mirai-ui-layer-S);
}

.bnGtBa_table .bnGtBa_checkbox {
  margin: 0;
}

.bnGtBa_table .bnGtBa_menuFilter .bnGtBa_items {
  gap: var(--mirai-ui-space-M);
}

.bnGtBa_table .bnGtBa_menuFilter .bnGtBa_input {
  margin-bottom: 0;
}

.bnGtBa_filters {
  background-color: var(--mirai-ui-table-background);
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-table-border-color);
  gap: calc(var(--mirai-ui-table-padding-y) / 2);
  padding: calc(var(--mirai-ui-table-padding-y) / 2) var(--mirai-ui-table-padding-x);
  flex-wrap: wrap;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.bnGtBa_filters .bnGtBa_item {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XXS) var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  -webkit-user-select: none;
  user-select: none;
}

.bnGtBa_filters .bnGtBa_item .bnGtBa_buttonRemove {
  border-radius: var(--mirai-ui-border-radius);
  height: var(--mirai-ui-space-L);
  width: var(--mirai-ui-space-L);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 480px) {
  .bnGtBa_table .bnGtBa_checkbox {
    max-width: var(--mirai-ui-checkbox-size-mobile);
    min-width: var(--mirai-ui-checkbox-size-mobile);
  }
}

@media only screen and (min-width: 481px) {
  .bnGtBa_table .bnGtBa_checkbox {
    max-width: var(--mirai-ui-checkbox-size);
    min-width: var(--mirai-ui-checkbox-size);
  }

  .bnGtBa_table thead tr th:hover {
    color: var(--mirai-ui-table-color);
    cursor: pointer;
  }

  .bnGtBa_table tbody tr:hover.bnGtBa_onPress {
    background-color: var(--mirai-ui-table-selected-background);
    cursor: pointer;
  }

  .bnGtBa_table tbody tr:hover.bnGtBa_onPress:not(.bnGtBa_selected) td:first-child {
    background-color: var(--mirai-ui-table-selected-background);
  }

  .bnGtBa_table .bnGtBa_menuFilter {
    margin: var(--mirai-ui-space-M) 0;
    width: calc(var(--mirai-ui-breakpoint-S) * .8);
  }

  .bnGtBa_filters .bnGtBa_item .bnGtBa_buttonRemove:hover {
    background-color: var(--mirai-ui-content-background);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .bnGtBa_table thead {
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  }
}

._6sXwYW_anchor {
  margin: 0 var(--mirai-ui-space-XS);
}

._6sXwYW_legend {
  color: var(--mirai-ui-content-light);
}

@media only screen and (min-width: 481px) {
  ._6sXwYW_nights {
    gap: var(--mirai-ui-space-XS);
  }
}

._2UfjFG_box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

._2UfjFG_details {
  gap: var(--mirai-ui-space-M);
  padding-bottom: 0;
}

._2UfjFG_details ._2UfjFG_inline {
  color: var(--mirai-ui-content-light);
  align-items: start;
  gap: var(--mirai-ui-space-XS);
}

._2UfjFG_details ._2UfjFG_item {
  gap: var(--mirai-ui-space-S);
  margin-bottom: var(--mirai-ui-space-XS);
}

._2UfjFG_details ._2UfjFG_item ._2UfjFG_image {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 0 1px var(--mirai-ui-content-background);
  background-size: cover;
}

._2UfjFG_details ._2UfjFG_buttonMore {
  border-top: solid 1px var(--mirai-ui-content-border);
  margin: calc(var(--mirai-ui-space-M) * -1);
  padding: var(--mirai-ui-space-XXS) 0;
  width: auto;
}

._2UfjFG_details ._2UfjFG_observations {
  gap: var(--mirai-ui-space-XXS);
}

@media only screen and (max-width: 480px) {
  ._2UfjFG_details ._2UfjFG_item ._2UfjFG_image {
    height: var(--mirai-ui-space-XL);
    min-height: var(--mirai-ui-space-XL);
    min-width: var(--mirai-ui-space-XL);
    width: var(--mirai-ui-space-XL);
  }
}

@media only screen and (min-width: 481px) {
  ._2UfjFG_details ._2UfjFG_item ._2UfjFG_image {
    height: var(--mirai-ui-space-XXL);
    min-height: var(--mirai-ui-space-XXL);
    min-width: var(--mirai-ui-space-XXL);
    width: var(--mirai-ui-space-XXL);
  }
}

._2ecYeW_bookingSummary._2ecYeW_box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
}

._2ecYeW_bookingSummary ._2ecYeW_section {
  gap: var(--mirai-ui-space-S);
  padding: var(--mirai-ui-space-M);
}

._2ecYeW_bookingSummary ._2ecYeW_section:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

._2ecYeW_bookingSummary ._2ecYeW_dates {
  align-items: flex-start;
}

._2ecYeW_bookingSummary ._2ecYeW_dates > * {
  flex: 1;
}

._2ecYeW_bookingSummary ._2ecYeW_info {
  gap: var(--mirai-ui-space-XS);
}

._2ecYeW_bookingSummary ._2ecYeW_notification > * {
  text-align: justify;
}

._2ecYeW_bookingSummary ._2ecYeW_links {
  justify-content: space-between;
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  ._2ecYeW_bookingSummary ._2ecYeW_dates {
    gap: var(--mirai-ui-space-XS);
  }

  ._2ecYeW_bookingSummary ._2ecYeW_details {
    padding: var(--mirai-ui-space-M) var(--mirai-ui-space-M) 0 var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 1180px) {
  ._2ecYeW_bookingSummary ._2ecYeW_dates {
    gap: var(--mirai-ui-space-S);
  }
}

@media print {
  ._2ecYeW_noPrint {
    display: none !important;
  }

  ._2ecYeW_bookingSummary ._2ecYeW_section {
    margin-bottom: var(--mirai-ui-space-M);
    padding: 0;
  }

  ._2ecYeW_bookingSummary ._2ecYeW_section:not(:last-child) {
    border-bottom: none;
  }

  ._2ecYeW_bookingSummary h2, ._2ecYeW_bookingSummary h3 {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  ._2ecYeW_bookingSummary span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }
}

.zmHfua_container {
  justify-content: center;
  align-items: center;
}

.bJC0ma_currency {
  white-space: nowrap;
}

.bJC0ma_priceBreakdown {
  gap: var(--mirai-ui-space-M);
}

.bJC0ma_lines > :not(.bJC0ma_notification) {
  align-items: flex-start;
  gap: var(--mirai-ui-space-M);
}

.bJC0ma_lines .bJC0ma_amount {
  white-space: nowrap;
}

.bJC0ma_lines > :not(:last-child) {
  margin-bottom: var(--mirai-ui-space-XS);
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .bJC0ma_priceBreakdown {
    gap: var(--mirai-ui-space-XS);
  }
}

@media only screen and (min-width: 1180px) {
  .bJC0ma_priceBreakdown {
    gap: var(--mirai-ui-space-M);
  }
}

.Q8b9oG_table {
  width: 100%;
}

.Q8b9oG_table.Q8b9oG_collapsed {
  padding-bottom: var(--mirai-ui-space-XS);
}

.Q8b9oG_buttonMore {
  box-shadow: 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  padding: var(--mirai-ui-space-XS);
  z-index: 1;
  top: 0;
}

.Q8b9oG_row {
  width: 100%;
  position: relative;
}

.Q8b9oG_row:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
}

.Q8b9oG_lineThrough {
  text-decoration: line-through;
}

.Q8b9oG_separator {
  margin: 0 var(--mirai-ui-space-XXS);
}

.Q8b9oG_caption {
  color: var(--mirai-ui-content-light);
}

.Q8b9oG_action {
  color: var(--mirai-ui-accent);
}

.Q8b9oG_action:hover, .Q8b9oG_action:active {
  color: var(--mirai-ui-content);
}

@media only screen and (max-width: 480px) {
  .Q8b9oG_row {
    padding-bottom: var(--mirai-ui-space-XS);
    padding-top: var(--mirai-ui-space-S);
  }

  .Q8b9oG_title .Q8b9oG_subtitle {
    margin-top: var(--mirai-ui-space-XXS);
  }

  .Q8b9oG_wide {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  .Q8b9oG_row {
    align-items: flex-start;
  }

  .Q8b9oG_row > * {
    padding: var(--mirai-ui-table-padding-y) 0;
  }

  .Q8b9oG_title {
    width: 40%;
  }

  .Q8b9oG_summary {
    width: 45%;
  }

  .Q8b9oG_price {
    align-items: flex-end;
    width: 15%;
  }
}

@media print {
  .Q8b9oG_noPrint {
    display: none;
  }

  .Q8b9oG_row {
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
    align-items: flex-start;
  }

  .Q8b9oG_row > * {
    padding: calc(var(--mirai-ui-table-padding-y) / 2) 0;
  }

  .Q8b9oG_title {
    width: 40%;
  }

  .Q8b9oG_summary {
    width: 45%;
  }

  .Q8b9oG_price {
    align-items: flex-end;
    width: 15%;
  }
}

.ZKgALq_box {
  background-color: var(--mirai-ui-base);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  border-radius: var(--mirai-ui-border-radius);
  padding: var(--mirai-ui-space-M);
  align-items: flex-start;
}

.ZKgALq_box > * {
  flex: 1;
}

.ZKgALq_linkPrivacy {
  display: inline;
}

.ZKgALq_modal {
  padding: var(--mirai-ui-space-M);
}

@media print {
  .ZKgALq_noPrint {
    display: none;
  }

  .ZKgALq_box {
    box-shadow: none;
    padding: 0 0 var(--mirai-ui-space-XS) 0;
    border-radius: 0;
    margin: 0;
  }

  .ZKgALq_box.ZKgALq_others > * {
    margin-top: var(--mirai-ui-space-M);
  }

  .ZKgALq_box span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }
}

.iVJayW_field {
  width: 100%;
}

.iVJayW_field.iVJayW_inline {
  flex-direction: var(--mirai-ui-row-direction);
}

.iVJayW_field.iVJayW_inline > .iVJayW_label {
  min-width: calc(var(--mirai-ui-space-XL) * 3);
}

.iVJayW_field.iVJayW_inline > .iVJayW_inlineValue {
  flex-direction: row;
  align-items: baseline;
  display: flex;
}

.iVJayW_field:not(.iVJayW_inline) > .iVJayW_value, .iVJayW_value > [data-role="text"] {
  text-align: justify;
  display: block;
}

@media screen {
  .iVJayW_field .iVJayW_print {
    display: none;
  }

  .iVJayW_field .iVJayW_tooltip {
    margin-left: var(--mirai-ui-space-XXS);
    text-decoration: underline;
  }
}

@media only screen and (max-width: 480px) {
  .iVJayW_field.iVJayW_withValue:not(:last-child) {
    margin-bottom: var(--mirai-ui-space-S);
  }
}

@media only screen and (min-width: 481px) {
  .iVJayW_field.iVJayW_withValue:not(:last-child) {
    margin-bottom: var(--mirai-ui-space-M);
  }
}

@media print {
  .iVJayW_field .iVJayW_noPrint {
    display: none;
  }

  .iVJayW_field .iVJayW_print {
    display: block;
  }
}

.ngZywW_modal {
  width: 100%;
  max-width: 1170px;
  height: 100%;
  max-height: 770px;
}

.ngZywW_action {
  display: inline;
}

.gye5vG_modalContent {
  padding: var(--mirai-ui-space-L);
}

.wNotVG_form {
  display: none;
}

._7Dj5Ka_description {
  margin-bottom: var(--mirai-ui-space-M);
}

._7Dj5Ka_iframe {
  height: calc(var(--mirai-ui-space-XXL) * 9);
  border: none;
  width: 100%;
}

.qLL4Yq_container {
  min-height: calc(var(--mirai-ui-space-L) * 4.75);
}

.RCVQmG_button {
  margin-top: var(--mirai-ui-space-M);
}

.RCVQmG_description {
  margin-bottom: var(--mirai-ui-space-M);
}

.RCVQmG_frame, .RCVQmG_name {
  font-family: var(--mirai-ui-input-font) !important;
  font-size: var(--mirai-ui-input-font-size) !important;
}

.RCVQmG_frame {
  color: var(--mirai-ui-input-color);
  direction: var(--mirai-ui-text-direction);
  height: var(--mirai-ui-space-L);
  outline: 0;
  padding-bottom: 0 !important;
  padding-top: var(--mirai-ui-space-M) !important;
}

.RCVQmG_frame::placeholder {
  color: var(--mirai-ui-input-color-disabled);
}

.RCVQmG_inputCVV {
  margin-left: var(--mirai-ui-space-M);
}

._6KLvna_container {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
  flex: 1;
  position: relative;
}

._6KLvna_border {
  border: var(--mirai-ui-input-text-border);
  border-radius: var(--mirai-ui-input-text-radius);
  padding-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  transition: box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

._6KLvna_border._6KLvna_error {
  border-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

._6KLvna_border._6KLvna_focus:not(._6KLvna_error) {
  border-color: var(--mirai-ui-input-text-color-active);
  box-shadow: var(--mirai-ui-focus-shadow);
}

._6KLvna_border._6KLvna_focus._6KLvna_error {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

._6KLvna_content {
  min-height: var(--mirai-ui-input-min-height);
}

._6KLvna_content > :not(._6KLvna_label):not(._6KLvna_icon) {
  padding: calc(var(--mirai-ui-input-text-padding-y)  + var(--mirai-ui-space-S)) calc(var(--mirai-ui-input-text-padding-x) / 2) calc(var(--mirai-ui-input-text-padding-x) / 2) calc(var(--mirai-ui-input-text-padding-x) / 2);
}

._6KLvna_hint {
  color: var(--mirai-ui-content-light);
  margin: calc(var(--mirai-ui-input-text-padding-y) / 4) var(--mirai-ui-input-text-padding-x);
}

._6KLvna_icon {
  font-size: var(--mirai-ui-input-text-icon-size);
  height: var(--mirai-ui-input-text-icon-size);
  line-height: var(--mirai-ui-input-text-icon-size);
  right: var(--mirai-ui-input-text-padding-x);
  top: calc(calc(var(--mirai-ui-input-min-height)  - var(--mirai-ui-input-text-icon-size)) / 2);
  width: var(--mirai-ui-input-text-icon-size);
  margin: 0;
  padding: 0;
  position: absolute;
}

._6KLvna_icon._6KLvna_error {
  color: var(--mirai-ui-error);
}

._6KLvna_icon._6KLvna_success {
  color: var(--mirai-ui-success);
}

._6KLvna_withLabel {
  padding-top: calc(var(--mirai-ui-input-text-padding-y)  + var(--mirai-ui-space-S));
}

._6KLvna_focus ._6KLvna_label {
  color: var(--mirai-ui-input-text-color-active);
}

._6KLvna_label {
  color: var(--mirai-ui-input-color-disabled);
  background-color: var(--mirai-ui-input-background);
  font-size: var(--mirai-ui-input-font-size);
  left: var(--mirai-ui-input-text-padding-x);
  line-height: calc(var(--mirai-ui-input-min-height)  - var(--mirai-ui-border-width));
  pointer-events: none;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  text-overflow: ellipsis;
  top: var(--mirai-ui-border-width);
  transition: font-size var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), line-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), right var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), top var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
}

._6KLvna_label._6KLvna_withValue {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
  top: calc(var(--mirai-ui-space-XXS)  + var(--mirai-ui-border-width));
}

._6KLvna_label ._6KLvna_defaultRequired {
  margin: 0 var(--mirai-ui-space-XXS) 0 var(--mirai-ui-space-XXS);
}

._6KLvna_text._6KLvna_required {
  border-radius: var(--mirai-ui-border-radius);
  bottom: calc(var(--mirai-ui-space-XXS) / 4);
  margin-left: var(--mirai-ui-space-XXS);
  padding: calc(var(--mirai-ui-space-XXS) / 4) calc(var(--mirai-ui-space-XXS) / 2);
  text-align: center;
  position: relative;
}

.djIzXW_container {
  gap: var(--mirai-ui-space-M);
}

.djIzXW_inputCVV {
  margin-left: var(--mirai-ui-space-M);
}

.djIzXW_inputExpire {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  outline: 0;
  width: 22px;
}

.djIzXW_inputName {
  box-sizing: content-box;
  color: var(--mirai-ui-input-color);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  border: 0;
  outline: 0;
  height: var(--mirai-ui-space-L) !important;
}

.djIzXW_button {
  width: 100%;
}

.tgFaiq_container > iframe {
  border: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.tgFaiq_container.tgFaiq_ready > iframe {
  height: 460px;
}

.tgFaiq_button {
  display: none;
}

._7cvrJW_button {
  display: flex;
}

._7cvrJW_error, ._7cvrJW_wallet {
  margin-top: var(--mirai-ui-space-S);
}

.VETGpq_form {
  border: 0;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
}

.VETGpq_form .VETGpq_input {
  flex: 100%;
}

@media only screen and (min-width: 431px) {
  .VETGpq_form .VETGpq_input:nth-child(n+3) {
    flex: 1 1 calc(50% - var(--mirai-ui-space-M));
    margin-right: var(--mirai-ui-space-M);
  }

  .VETGpq_form .VETGpq_input:last-child {
    margin-right: 0;
  }
}

.X60uFG_container {
  gap: var(--mirai-ui-space-S);
}

.X60uFG_input {
  box-sizing: border-box;
  height: calc(var(--mirai-ui-space-XL)  + var(--mirai-ui-space-XXS));
}

.FkY4aq_container {
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  border-radius: var(--mirai-ui-border-radius);
  display: flex;
}

.FkY4aq_item .FkY4aq_header {
  padding: var(--mirai-ui-space-M);
  flex-direction: row;
  align-items: center;
  width: auto;
  display: flex;
}

.FkY4aq_item .FkY4aq_header:not(.FkY4aq_selected) .FkY4aq_image {
  filter: grayscale();
  opacity: .5;
}

.FkY4aq_item:not(:first-child) .FkY4aq_header, .FkY4aq_children {
  border-top: var(--mirai-ui-border-width) solid var(--mirai-ui-content-border);
}

.FkY4aq_item .FkY4aq_header .FkY4aq_radio {
  margin-right: var(--mirai-ui-space-XS);
  pointer-events: none;
}

.FkY4aq_item .FkY4aq_header .FkY4aq_title {
  margin-right: var(--mirai-ui-space-M);
  flex: 1;
}

.FkY4aq_item .FkY4aq_header .FkY4aq_image {
  height: var(--mirai-ui-space-M);
  margin-left: var(--mirai-ui-space-XXS);
}

.FkY4aq_item .FkY4aq_header .FkY4aq_image.FkY4aq_disabled {
  filter: grayscale();
  opacity: .25;
}

.FkY4aq_item .FkY4aq_content {
  padding: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
}

.FkY4aq_item .FkY4aq_content .FkY4aq_caption {
  color: var(--mirai-ui-content-light);
}

.FkY4aq_item .FkY4aq_content .FkY4aq_caption.FkY4aq_withComponent {
  margin-bottom: var(--mirai-ui-space-XS);
}

.FkY4aq_children {
  padding: var(--mirai-ui-space-M);
}

._9MM14W_cards {
  gap: var(--mirai-ui-space-M);
}

._9MM14W_cards:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-M);
}

._9MM14W_card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), filter var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

._9MM14W_card._9MM14W_disabled {
  filter: grayscale();
}

._9MM14W_card._9MM14W_focus {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color), var(--mirai-ui-focus-shadow);
}

._9MM14W_card > :nth-last-child(2) {
  flex: 1;
}

._9MM14W_card > :not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

@media only screen and (max-width: 480px) {
  ._9MM14W_cards {
    flex-direction: column;
  }

  ._9MM14W_card {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) {
  ._9MM14W_cards {
    flex-wrap: wrap;
    align-items: stretch;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  ._9MM14W_card {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }
}

@media only screen and (min-width: 1180px) {
  ._9MM14W_card:not(._9MM14W_columnMode) {
    width: 1%;
    flex-basis: calc(33.33% - calc(calc(var(--mirai-ui-space-M) * 2) / 3)) !important;
  }

  ._9MM14W_card._9MM14W_columnMode {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }
}

.HH83Qq_caption {
  color: var(--mirai-field-content-color);
  text-align: var(--mirai-ui-text-align);
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.HH83Qq_caption.HH83Qq_placeholder {
  color: var(--mirai-ui-input-color-disabled);
}

.HH83Qq_field {
  background-color: var(--mirai-field-background);
  border-radius: var(--mirai-field-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: var(--mirai-ui-row-direction);
  align-content: center;
  align-items: center;
  gap: calc(var(--mirai-ui-input-text-padding-x) / 2);
  height: var(--mirai-field-height);
  padding: 0 var(--mirai-ui-input-text-padding-x);
  z-index: 0;
  justify-content: flex-start;
  display: flex;
}

.HH83Qq_field:not(.HH83Qq_inline) {
  border: var(--mirai-field-border);
  transition: border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.HH83Qq_field:not(.HH83Qq_inline).HH83Qq_focus, .HH83Qq_field:not(.HH83Qq_inline).HH83Qq_hover {
  border-color: var(--mirai-field-border-active);
}

.HH83Qq_field.HH83Qq_inline.HH83Qq_focus, .HH83Qq_field.HH83Qq_inline.HH83Qq_hover {
  background-color: var(--mirai-field-background-active);
}

.HH83Qq_icon {
  font-size: var(--mirai-ui-input-text-icon-size);
  height: var(--mirai-ui-input-text-icon-size);
  line-height: var(--mirai-ui-input-text-icon-size);
  width: var(--mirai-ui-input-text-icon-size);
}

.HH83Qq_icon.HH83Qq_context {
  color: var(--mirai-field-icon-color);
}

.HH83Qq_icon.HH83Qq_skeleton {
  color: var(--mirai-ui-content-border);
}

.HH83Qq_icon.HH83Qq_error {
  color: var(--mirai-ui-error);
}

.HH83Qq_icon.HH83Qq_warning {
  color: var(--mirai-ui-warning);
}

.HH83Qq_label {
  color: var(--mirai-field-label-color);
}

.HH83Qq_input {
  flex: 1;
}

.HH83Qq_field input {
  color: var(--mirai-field-content-color);
}

.HH83Qq_layerContent {
  margin: var(--mirai-ui-input-text-padding-y) 0;
  background-color: var(--mirai-ui-input-background);
  border: var(--mirai-field-border);
  border-radius: var(--mirai-field-border-radius);
  box-shadow: var(--mirai-ui-shadow);
}

@media only screen and (max-width: 480px) {
  .HH83Qq_modal {
    max-height: var(--mirai-modal-max-height);
  }

  .HH83Qq_field {
    width: auto;
  }

  .HH83Qq_input {
    max-width: 90%;
  }
}

@media only screen and (min-width: 481px) {
  .HH83Qq_caption {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .HH83Qq_field:not(.HH83Qq_hasPlaces) .HH83Qq_caption {
    max-width: 25vw;
  }

  .HH83Qq_field.HH83Qq_hasPlaces .HH83Qq_caption {
    max-width: 15vw;
  }
}

.E7ZpTq_skeleton {
  border-radius: var(--mirai-ui-space-XXS);
}

.E7ZpTq_skeleton:not(.E7ZpTq_base) {
  background: linear-gradient(90deg, var(--mirai-ui-content-border) 25%, var(--mirai-ui-content-background) 50%, var(--mirai-ui-content-border) 75%);
  opacity: .66;
  background-size: 200% 100%;
  animation: 1.5s infinite E7ZpTq_skeleton-loading;
}

.E7ZpTq_skeleton.E7ZpTq_base {
  background-color: var(--mirai-ui-base);
}

.E7ZpTq_skeleton:not(.E7ZpTq_text):not(.E7ZpTq_button):not(.E7ZpTq_icon) {
  min-height: var(--mirai-ui-space-M);
  min-width: var(--mirai-ui-space-M);
}

.E7ZpTq_skeleton.E7ZpTq_text {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-base);
  min-width: var(--mirai-ui-space-M);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.E7ZpTq_skeleton.E7ZpTq_button {
  pointer-events: none;
}

.E7ZpTq_skeleton.E7ZpTq_button, .E7ZpTq_skeleton.E7ZpTq_text {
  color: #0000 !important;
}

.E7ZpTq_icon {
  height: var(--mirai-ui-font-size-headline-3);
  width: var(--mirai-ui-font-size-headline-3);
}

@keyframes E7ZpTq_skeleton-loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.t5yToW_offset {
  padding-left: var(--mirai-ui-space-M);
  padding-right: var(--mirai-ui-space-M);
}

.t5yToW_notification {
  margin: var(--mirai-ui-space-M) var(--mirai-ui-space-M) 0 var(--mirai-ui-space-M);
}

.t5yToW_container .t5yToW_scrollview {
  gap: var(--mirai-ui-space-XXS);
  padding-top: var(--mirai-ui-space-XXS);
  width: 100%;
}

.t5yToW_ages {
  background-color: var(--mirai-ui-content-background);
  border-bottom: solid 1px var(--mirai-ui-content-border);
  border-top: solid 1px var(--mirai-ui-content-border);
  padding-bottom: var(--mirai-ui-space-S);
  padding-top: var(--mirai-ui-space-M);
  position: relative;
  overflow: visible;
}

.t5yToW_ages:after, .t5yToW_ages:before {
  content: "";
  right: calc(var(--mirai-ui-space-M)  + var(--mirai-ui-space-XL));
  top: calc(var(--mirai-ui-space-XS) * -2);
  border-style: solid;
  border-width: var(--mirai-ui-space-XS);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.t5yToW_ages:after {
  border-color: transparent transparent var(--mirai-ui-content-background) transparent;
  margin-top: 1px;
}

.t5yToW_ages:before {
  border-color: transparent transparent var(--mirai-ui-content-border) transparent;
}

.t5yToW_ages .t5yToW_inputs {
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.t5yToW_ages .t5yToW_inputs .t5yToW_inputSelect {
  box-sizing: border-box;
  margin-bottom: var(--mirai-ui-space-M);
  min-width: 47%;
  max-width: 47%;
  margin-top: 0;
}

.t5yToW_ages .t5yToW_inputs .t5yToW_inputSelect:nth-child(odd) {
  margin-right: 6%;
}

.t5yToW_inputNumber :first-letter {
  text-transform: capitalize;
}

.t5yToW_header {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  height: calc(var(--mirai-ui-button-small-height)  + var(--mirai-ui-space-XS));
  margin-bottom: var(--mirai-ui-space-S);
  padding-left: var(--mirai-ui-space-M);
  padding-right: var(--mirai-ui-space-XS);
  justify-content: space-between;
  align-items: center;
}

.t5yToW_footer {
  border-top: 1px solid var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  justify-content: space-between;
}

.t5yToW_buttonAdd {
  flex: 1;
}

.t5yToW_buttonSubmit {
  align-self: flex-start;
}

@media only screen and (max-width: 480px) {
  .t5yToW_container {
    max-height: calc(var(--mirai-modal-max-height)  - var(--mirai-ui-space-XXL));
  }

  .t5yToW_scrollview {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  .t5yToW_container {
    width: var(--mirai-occupation-width);
  }

  .t5yToW_scrollview {
    max-height: var(--mirai-occupation-scrollview-max-height);
  }

  .t5yToW_ages {
    margin-left: 1px;
    margin-right: 1px;
  }
}

.JiK2PW_container {
  overflow: hidden;
}

input.JiK2PW_input {
  font-size: var(--mirai-ui-font-size-action);
  text-align: var(--mirai-ui-text-align);
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  padding: 0;
  overflow: hidden;
}

@media only screen and (max-width: 480px) {
  .JiK2PW_content {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-S);
  }

  input.JiK2PW_input.JiK2PW_compacted {
    line-height: var(--mirai-ui-input-min-height);
  }
}

@media only screen and (min-width: 481px) {
  input.JiK2PW_input {
    max-width: calc(var(--mirai-ui-space-XL) * 2);
  }
}

@media screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  input.JiK2PW_input {
    font-size: var(--mirai-ui-input-font-size);
  }
}

.QsP7Ma_container {
  background-color: var(--mirai-ui-content-background);
  width: 100vw;
}

.QsP7Ma_content {
  max-width: var(--mirai-ui-breakpoint-content);
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.QsP7Ma_info {
  gap: var(--mirai-ui-space-XXS);
}

.QsP7Ma_info .QsP7Ma_logo {
  filter: grayscale();
  max-height: var(--mirai-ui-font-size-headline-2);
  opacity: .8;
}

.QsP7Ma_info .QsP7Ma_logo:hover {
  filter: grayscale(0);
  opacity: 1;
}

.QsP7Ma_info .QsP7Ma_copyright {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.QsP7Ma_info .QsP7Ma_link {
  color: var(--mirai-ui-content-light);
  text-transform: capitalize;
  text-wrap: nowrap;
  display: block;
  position: static;
}

.QsP7Ma_info .QsP7Ma_copyright .QsP7Ma_link {
  margin-left: var(--mirai-ui-space-XXS);
}

.QsP7Ma_powered {
  text-decoration: none;
  position: static;
  color: var(--mirai-ui-content-light) !important;
}

.QsP7Ma_powered, .QsP7Ma_powered * {
  transition: color var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.QsP7Ma_powered:hover {
  color: var(--mirai-ui-content) !important;
}

.QsP7Ma_powered:hover .QsP7Ma_mirai {
  color: #de1223;
}

.QsP7Ma_powered .QsP7Ma_mirai {
  margin-left: var(--mirai-ui-space-XXS);
}

@media only screen and (max-width: 480px) {
  .QsP7Ma_content {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) {
  .QsP7Ma_container {
    padding: 0 var(--mirai-ui-space-M);
  }

  .QsP7Ma_content {
    padding: var(--mirai-ui-space-L) 0;
  }
}

.E9yqTa_field {
  display: inline;
}

.SFSZZG_selectEnvironment {
  border-radius: var(--mirai-ui-border-radius);
  cursor: pointer;
  position: relative;
}

.SFSZZG_selectEnvironment:hover, .SFSZZG_selectEnvironment:active {
  background-color: var(--mirai-header-background-active);
}

.SFSZZG_selectEnvironment > * {
  height: var(--mirai-ui-button-height);
  line-height: var(--mirai-ui-button-height);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.SFSZZG_selectEnvironment * {
  color: var(--mirai-header-color);
}

.SFSZZG_selectEnvironment:hover *, .SFSZZG_selectEnvironment:active * {
  color: var(--mirai-header-color-active);
}

.SFSZZG_selectEnvironment.SFSZZG_small > * {
  height: calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-S));
  line-height: calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-S));
}

.SFSZZG_selectEnvironment .SFSZZG_input {
  color: #0000;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  align-self: center;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.SFSZZG_selectEnvironment .SFSZZG_caption {
  gap: var(--mirai-ui-space-XXS);
  pointer-events: none;
}

.SFSZZG_icon.SFSZZG_skeleton {
  color: var(--mirai-ui-content-background) !important;
}

.fcTVra_text {
  color: var(--mirai-ui-content-light);
  margin-bottom: var(--mirai-ui-space-S);
}

.fcTVra_asterisk {
  color: var(--mirai-ui-error);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.W8LilG_progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.W8LilG_container {
  background-color: var(--mirai-ui-content-background);
  padding: var(--mirai-ui-space-M);
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  align-items: center;
  width: 100vw;
}

.W8LilG_content {
  align-items: flex-start;
  gap: var(--mirai-ui-space-M);
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

.W8LilG_content > * {
  flex: 1;
}

.W8LilG_content > * > :not(:last-child) {
  margin-bottom: var(--mirai-ui-space-M);
}

.W8LilG_content > .W8LilG_side {
  z-index: var(--mirai-ui-layer-M);
}

.W8LilG_box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
}

.W8LilG_caption {
  color: var(--mirai-ui-content-light);
  margin-top: var(--mirai-ui-space-XS);
}

.W8LilG_wide {
  width: 100%;
}

.W8LilG_error {
  margin-top: var(--mirai-ui-space-XL);
}

.W8LilG_linkAgency {
  margin-left: var(--mirai-ui-space-XS);
  display: inline;
}

.W8LilG_priceBreakdown {
  border-top: solid 1px var(--mirai-ui-content-border);
}

.W8LilG_priceBreakdown .W8LilG_footer {
  text-align: center;
}

.W8LilG_modal {
  padding: var(--mirai-ui-space-M);
}

.W8LilG_modal .W8LilG_buttons {
  gap: var(--mirai-ui-space-M);
  margin-top: var(--mirai-ui-space-M);
}

.W8LilG_modal .W8LilG_buttons ui-button {
  width: 100%;
}

.W8LilG_cancel {
  margin-bottom: var(--mirai-ui-space-M);
}

.W8LilG_description {
  margin-bottom: var(--mirai-ui-space-S);
}

.W8LilG_notificationPayment .W8LilG_action {
  color: var(--mirai-ui-accent);
}

@media only screen and (max-width: 480px) {
  [data-role="booking"] [data-role="menu-content"] {
    position: relative;
  }

  .W8LilG_notificationPayment {
    gap: var(--mirai-ui-space-S);
  }
}

@media only screen and (min-width: 481px) {
  [data-role="booking"] [data-role="menu-content"] {
    position: relative;
  }

  .W8LilG_notificationPayment {
    gap: var(--mirai-ui-space-M);
  }

  .W8LilG_notificationPayment .W8LilG_action {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px), only screen and (max-width: 480px) {
  .W8LilG_menu {
    border-top: solid 1px var(--mirai-ui-content-border);
    background-color: var(--mirai-ui-base);
    padding: var(--mirai-ui-space-S);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-width: 1180px) {
  .W8LilG_content > .W8LilG_side {
    max-width: calc(var(--mirai-ui-breakpoint-S)  - var(--mirai-ui-space-M) * 4);
    top: var(--mirai-ui-space-M);
    position: -webkit-sticky;
    position: sticky;
  }

  .W8LilG_error {
    max-width: 50vw;
  }

  .W8LilG_menu ui-menu-content {
    position: relative !important;
  }

  .W8LilG_menu ui-menu-content > * {
    box-shadow: 0 0 1px var(--mirai-ui-content-light);
  }
}

@media only screen {
  .W8LilG_print {
    display: none;
  }
}

@media print {
  .W8LilG_noPrint {
    display: none !important;
  }

  .W8LilG_content {
    flex-direction: column;
  }

  .W8LilG_content > * {
    width: 100%;
  }

  .W8LilG_box {
    box-shadow: none;
    padding: 0 0 var(--mirai-ui-space-XS) 0;
    border-radius: 0;
    margin: 0;
  }

  .W8LilG_box h2, .W8LilG_box h3 {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  .W8LilG_box span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }

  .W8LilG_box .W8LilG_priceLabel {
    margin-bottom: 0;
    margin-top: var(--mirai-ui-space-XS);
  }

  .W8LilG_box .W8LilG_price {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  .W8LilG_item {
    margin-bottom: var(--mirai-ui-space-L);
  }

  .W8LilG_caption {
    margin-top: 0;
  }
}

.nR8gRW_divider {
  margin: 0 var(--mirai-ui-space-XS);
  max-height: 1px;
}

.nR8gRW_item .nR8gRW_bullet {
  text-align: center;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nR8gRW_item .nR8gRW_bullet.nR8gRW_success {
  color: var(--mirai-ui-base);
}

@media only screen and (max-width: 480px) {
  .nR8gRW_divider {
    flex: 1;
    min-width: 3%;
  }

  .nR8gRW_item {
    gap: var(--mirai-ui-space-XXS);
  }

  .nR8gRW_item .nR8gRW_bullet {
    height: var(--mirai-ui-space-M);
    max-width: var(--mirai-ui-space-M);
    min-width: var(--mirai-ui-space-M);
    line-height: var(--mirai-ui-space-M);
  }
}

@media only screen and (max-width: 1179px) {
  .nR8gRW_bookingProcess {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-S);
    justify-content: center;
    width: 100%;
  }

  .nR8gRW_divider {
    border: dashed 1px var(--mirai-ui-content-border);
  }

  .nR8gRW_item .nR8gRW_bullet {
    background-color: var(--mirai-ui-content-border);
    color: var(--mirai-ui-content-light);
  }

  .nR8gRW_item .nR8gRW_bullet.nR8gRW_success {
    background-color: var(--mirai-ui-accent);
  }

  .nR8gRW_item .nR8gRW_label:not(.nR8gRW_disabled) {
    color: var(--mirai-ui-content);
  }

  .nR8gRW_item .nR8gRW_label.nR8gRW_disabled {
    color: var(--mirai-ui-content-light);
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .nR8gRW_divider {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  .nR8gRW_item {
    gap: var(--mirai-ui-space-XS);
  }

  .nR8gRW_item .nR8gRW_bullet {
    height: var(--mirai-ui-space-L);
    max-width: var(--mirai-ui-space-L);
    min-width: var(--mirai-ui-space-L);
    line-height: var(--mirai-ui-space-L);
  }
}

@media only screen and (min-width: 1180px) {
  .nR8gRW_divider {
    border: dashed 1px var(--mirai-header-border-color);
    width: var(--mirai-ui-space-L);
  }

  .nR8gRW_item .nR8gRW_bullet {
    background-color: var(--mirai-header-border-color);
    color: var(--mirai-header-color-disabled);
  }

  .nR8gRW_item .nR8gRW_bullet.nR8gRW_success {
    background-color: var(--mirai-header-accent);
  }

  .nR8gRW_item .nR8gRW_label:not(.nR8gRW_disabled) {
    color: var(--mirai-header-color);
  }

  .nR8gRW_item .nR8gRW_label.nR8gRW_disabled {
    color: var(--mirai-header-color-disabled);
  }
}

.MqZHCq_header {
  width: 100vw;
}

.MqZHCq_header:not(.MqZHCq_extra) {
  background-color: var(--mirai-header-background);
  border-bottom: solid 1px var(--mirai-header-border-color);
}

.MqZHCq_content {
  gap: var(--mirai-ui-space-S);
  max-width: var(--mirai-ui-breakpoint-content);
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.MqZHCq_header .MqZHCq_main {
  gap: var(--mirai-ui-space-XL);
}

.MqZHCq_header .MqZHCq_main .MqZHCq_textName {
  color: var(--mirai-header-color);
}

.MqZHCq_header .MqZHCq_login {
  border-radius: var(--mirai-ui-border-radius);
}

.MqZHCq_header .MqZHCq_login:hover, .MqZHCq_header .MqZHCq_login:active {
  background-color: var(--mirai-header-background-active);
}

.MqZHCq_header .MqZHCq_login > * {
  gap: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-XL);
  line-height: var(--mirai-ui-space-XL);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.MqZHCq_header .MqZHCq_login * {
  color: var(--mirai-header-color);
}

.MqZHCq_header .MqZHCq_login:hover *, .MqZHCq_header .MqZHCq_login:active * {
  color: var(--mirai-header-color-active);
}

.MqZHCq_header.MqZHCq_extra {
  background-color: var(--mirai-ui-base);
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.MqZHCq_header.MqZHCq_extra .MqZHCq_content {
  min-height: calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-S));
}

@media only screen and (max-width: 480px) {
  .MqZHCq_header {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .MqZHCq_header .MqZHCq_main {
    gap: var(--mirai-ui-space-L);
  }

  .MqZHCq_main .MqZHCq_image {
    max-height: var(--mirai-ui-space-XL);
    max-width: 40vw;
  }
}

@media only screen and (min-width: 481px) {
  .MqZHCq_header {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .MqZHCq_header .MqZHCq_name {
    max-width: calc(var(--mirai-ui-space-XXL) * 5);
  }

  .MqZHCq_header .MqZHCq_options {
    gap: var(--mirai-ui-space-XXS);
  }

  .MqZHCq_main .MqZHCq_image {
    max-height: var(--mirai-ui-space-XL);
    max-width: calc(var(--mirai-ui-breakpoint-content) / 8);
  }
}

@media print {
  .MqZHCq_noPrint {
    display: none;
  }

  .MqZHCq_header:not(.MqZHCq_extra) {
    border-bottom: none;
  }

  .MqZHCq_main .MqZHCq_image {
    max-height: var(--mirai-ui-space-L);
  }
}

.fUIr3W_button {
  margin-top: var(--mirai-ui-space-L);
}

.fUIr3W_login {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
}

.fUIr3W_login .fUIr3W_color {
  color: var(--mirai-session-color);
}

.fUIr3W_login .fUIr3W_iconColor {
  color: var(--mirai-session-icon-color);
}

.fUIr3W_login:hover .fUIr3W_color {
  color: var(--mirai-session-color-active);
}

.fUIr3W_login:hover .fUIr3W_iconColor {
  color: var(--mirai-session-icon-color-active);
}

.fUIr3W_account {
  align-items: center;
}

.fUIr3W_account .fUIr3W_avatar {
  background-color: var(--mirai-ui-accent);
  height: var(--mirai-ui-space-L);
  width: var(--mirai-ui-space-L);
  min-width: var(--mirai-ui-space-L);
  border-radius: 50%;
  justify-content: center;
  overflow: hidden;
}

.fUIr3W_account .fUIr3W_avatar > * {
  color: var(--mirai-ui-base);
}

.fUIr3W_account .fUIr3W_texts {
  max-width: calc(var(--mirai-ui-space-L) * 3);
}

.fUIr3W_account .fUIr3W_texts > * {
  color: var(--mirai-session-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fUIr3W_account:hover .fUIr3W_texts > * {
  color: var(--mirai-session-color-active);
}

.fUIr3W_description {
  margin-bottom: var(--mirai-ui-space-S);
}

.fUIr3W_modal {
  padding-bottom: var(--mirai-ui-space-L);
}

.fUIr3W_modal .fUIr3W_buttonTransparent {
  margin: 0 var(--mirai-ui-space-L);
  width: auto;
}

.fUIr3W_notification {
  margin-top: var(--mirai-ui-space-M);
  width: 100%;
}

.fUIr3W_info {
  margin-bottom: var(--mirai-ui-space-L);
}

.fUIr3W_offset {
  margin: var(--mirai-ui-space-L) var(--mirai-ui-space-L) var(--mirai-ui-space-M) var(--mirai-ui-space-L);
}

.fUIr3W_actions {
  direction: var(--mirai-ui-text-direction);
}

.fUIr3W_wide, .fUIr3W_scrollview, .fUIr3W_children {
  width: 100%;
}

.fUIr3W_pressable {
  overflow: visible;
}

@media only screen and (max-width: 480px) {
  .fUIr3W_modal {
    max-height: 100svh;
  }
}

@media only screen and (min-width: 481px) {
  .fUIr3W_account {
    border-radius: var(--mirai-ui-border-radius);
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .fUIr3W_account.fUIr3W_hover:hover {
    background-color: var(--mirai-ui-accent-background);
  }

  .fUIr3W_account.fUIr3W_hover:hover > :nth-child(2) > * {
    color: var(--mirai-ui-accent-dark);
  }

  .fUIr3W_children {
    padding: var(--mirai-ui-space-L) 0 0 var(--mirai-ui-space-L);
    align-items: stretch;
  }

  .fUIr3W_children .fUIr3W_form {
    border-left: solid 1px var(--mirai-ui-content-border);
    margin-left: var(--mirai-ui-space-L);
    width: calc(var(--mirai-ui-breakpoint-S)  - calc(var(--mirai-ui-space-L) * 2));
    height: auto;
  }
}

.xzMBSq_text {
  color: var(--mirai-booking-query-color);
}

.xzMBSq_text:hover {
  color: var(--mirai-booking-query-color-active);
}

.xzMBSq_button {
  margin-bottom: var(--mirai-ui-space-L);
  margin-top: var(--mirai-ui-space-L);
}

.xzMBSq_description {
  margin-bottom: var(--mirai-ui-space-S);
  width: 100%;
}

.xzMBSq_form {
  padding: var(--mirai-ui-space-M) var(--mirai-ui-space-L);
}

.xzMBSq_wide {
  width: 100%;
}

.QkrNga_button {
  border-radius: var(--mirai-finder-button-border-radius);
  font-family: var(--mirai-finder-button-font-family);
  font-size: var(--mirai-finder-button-font-size);
  font-weight: var(--mirai-finder-button-font-weight);
  height: var(--mirai-field-height);
  max-height: var(--mirai-field-height);
  min-height: var(--mirai-field-height);
}

.QkrNga_button:not(:hover) {
  background-color: var(--mirai-finder-button-background) !important;
  color: var(--mirai-finder-button-color) !important;
}

.QkrNga_button:hover {
  background-color: var(--mirai-finder-button-background-active) !important;
  color: var(--mirai-finder-button-color-active) !important;
}

.vcTbNW_calendar {
  flex: 1;
}

.vcTbNW_footer {
  border-top: solid 1px var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  justify-content: space-between;
}

.vcTbNW_hint {
  color: var(--mirai-ui-content-light);
}

.vcTbNW_notification {
  margin: var(--mirai-ui-space-S);
  max-width: calc(var(--mirai-ui-calendar-cell) * 14);
  align-self: center;
}

.vcTbNW_offset {
  padding: var(--mirai-finder-calendar-padding);
}

@media only screen and (max-width: 480px) {
  .vcTbNW_container {
    max-height: calc(var(--mirai-modal-max-height)  - var(--mirai-ui-space-XXL));
  }

  .vcTbNW_calendar {
    flex: 1;
    max-height: calc(var(--mirai-modal-max-height)  - calc(var(--mirai-ui-space-XXL) * 3)  - var(--mirai-ui-space-XXS)) !important;
  }
}

@media only screen and (min-width: 481px) {
  .vcTbNW_container {
    min-height: calc(var(--mirai-ui-calendar-cell) * 8);
  }

  .vcTbNW_container.vcTbNW_top {
    min-height: calc(calc(var(--mirai-ui-calendar-cell) * 8)  + calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-S)));
  }
}

._20A6Ua_header {
  padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-M) var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  margin-bottom: var(--mirai-ui-space-S);
  border-bottom: solid 1px var(--mirai-ui-content-border) !important;
}

._20A6Ua_header ._20A6Ua_input {
  font-size: var(--mirai-ui-font-size-action);
  margin-left: var(--mirai-ui-space-XXS);
}

._20A6Ua_footer {
  border-top: solid 1px var(--mirai-ui-content-border);
  align-items: flex-end;
}

._20A6Ua_item {
  box-sizing: border-box;
  max-height: var(--mirai-places-item-height);
  min-height: var(--mirai-places-item-height);
  padding: 0 var(--mirai-places-padding);
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

._20A6Ua_item._20A6Ua_selected, ._20A6Ua_item:hover:not(._20A6Ua_head) {
  background-color: var(--mirai-places-item-background-selected);
}

._20A6Ua_item._20A6Ua_selected *, ._20A6Ua_item:hover:not(._20A6Ua_head) * {
  color: var(--mirai-places-item-color-selected);
}

._20A6Ua_item ._20A6Ua_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._20A6Ua_scrollview {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 480px) {
  ._20A6Ua_container {
    max-height: calc(var(--mirai-modal-max-height)  - var(--mirai-ui-space-XXL));
  }

  ._20A6Ua_footer {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  ._20A6Ua_scrollview {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  ._20A6Ua_container {
    max-height: calc(calc(var(--mirai-places-item-height) * calc(var(--mirai-places-items-per-column)  + 3))  - var(--mirai-places-padding));
    padding: calc(var(--mirai-places-padding) / 2) 0;
  }

  ._20A6Ua_container._20A6Ua_withSubmit {
    max-height: calc(calc(var(--mirai-places-item-height) * calc(var(--mirai-places-items-per-column)  + 5))  - var(--mirai-places-padding));
  }

  ._20A6Ua_footer {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M) var(--mirai-ui-space-XXS);
  }

  ._20A6Ua_item {
    width: var(--mirai-places-item-width);
  }

  ._20A6Ua_columns {
    max-width: calc(var(--mirai-places-item-width) * 3);
    flex-wrap: wrap;
  }

  ._20A6Ua_columns2 {
    width: calc(var(--mirai-places-item-width) * 2);
  }

  ._20A6Ua_columns3 {
    width: calc(var(--mirai-places-item-width) * 3);
  }
}

.YRq8BG_finder {
  flex: 0;
  justify-content: center;
  align-self: center;
  overflow: hidden;
}

.YRq8BG_finder:not(.YRq8BG_compact) {
  background-color: var(--mirai-finder-background);
  border: var(--mirai-finder-border);
  gap: var(--mirai-finder-gap);
}

.YRq8BG_finder:not(.YRq8BG_column) {
  border-radius: var(--mirai-finder-border-radius);
}

.YRq8BG_finder:not(.YRq8BG_column):not(.YRq8BG_compact) {
  padding: var(--mirai-finder-padding);
}

.YRq8BG_finder.YRq8BG_column {
  border-radius: var(--mirai-finder-border-radius);
  padding: var(--mirai-finder-padding);
  width: 100%;
}

.YRq8BG_field {
  height: var(--mirai-finder-field-height);
}

.YRq8BG_separator {
  background-color: var(--mirai-finder-field-separator-color);
}

.YRq8BG_finder.YRq8BG_column .YRq8BG_separator {
  height: var(--mirai-ui-border-width);
  width: 100%;
}

.YRq8BG_finder:not(.YRq8BG_column) .YRq8BG_separator {
  height: var(--mirai-finder-field-height);
  width: var(--mirai-ui-border-width);
}

.YRq8BG_button {
  height: var(--mirai-finder-field-height);
  font-family: var(--mirai-finder-button-font-family);
  font-size: var(--mirai-finder-button-font-size);
  font-weight: var(--mirai-finder-button-font-weight);
}

.YRq8BG_finder:not(.YRq8BG_compact) .YRq8BG_button:not(:hover):not(.YRq8BG_skeleton) {
  background-color: var(--mirai-finder-button-background) !important;
  color: var(--mirai-finder-button-color) !important;
}

.YRq8BG_finder:not(.YRq8BG_compact) .YRq8BG_button:hover:not(.YRq8BG_skeleton) {
  background-color: var(--mirai-finder-button-background-active) !important;
  color: var(--mirai-finder-button-color-active) !important;
}

.YRq8BG_finder:not(.YRq8BG_compact) .YRq8BG_button {
  border-radius: var(--mirai-finder-button-border-radius);
}

.YRq8BG_finder.YRq8BG_column .YRq8BG_button {
  margin-top: var(--mirai-finder-padding);
}

.YRq8BG_finder:not(.YRq8BG_column):not(.YRq8BG_compact) .YRq8BG_button {
  max-height: var(--mirai-finder-field-height);
  min-height: var(--mirai-finder-field-height);
}

.YRq8BG_finder:not(.YRq8BG_column):not(.YRq8BG_compact) .YRq8BG_button.YRq8BG_withIcon {
  max-width: var(--mirai-finder-field-height);
  min-width: var(--mirai-finder-field-height);
  padding: 0;
}

.YRq8BG_compact {
  background-color: var(--mirai-ui-base);
  border: var(--mirai-ui-input-text-border);
  transition: border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing), box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  max-width: 100svw;
}

.YRq8BG_compact:hover {
  border-color: var(--mirai-ui-focus-color);
  box-shadow: var(--mirai-ui-focus-shadow);
}

.YRq8BG_compact .YRq8BG_icon {
  color: var(--mirai-finder-field-icon-color);
}

.YRq8BG_compact .YRq8BG_icon.YRq8BG_skeleton {
  color: var(--mirai-ui-content-border);
}

.YRq8BG_compact .YRq8BG_caption {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.YRq8BG_compact .YRq8BG_pressable {
  flex-direction: var(--mirai-ui-row-direction);
  align-items: flex-end;
  gap: var(--mirai-ui-space-XS);
  display: flex;
}

.YRq8BG_compact .YRq8BG_button {
  border-radius: var(--mirai-finder-button-border-radius);
  background-color: var(--mirai-finder-button-background) !important;
  color: var(--mirai-finder-button-color) !important;
}

.YRq8BG_compact .YRq8BG_button:hover {
  background-color: var(--mirai-finder-button-background-active) !important;
  color: var(--mirai-finder-button-color-active) !important;
}

.YRq8BG_compact.YRq8BG_directionLeft > .YRq8BG_pressable:not(:last-of-type) {
  box-shadow: 1px 0 var(--mirai-ui-content-border);
}

.YRq8BG_compact.YRq8BG_directionRight > .YRq8BG_pressable:not(:last-of-type) {
  box-shadow: -1px 0 var(--mirai-ui-content-border);
}

@media only screen and (max-width: 1179px) {
  .YRq8BG_finder {
    max-width: calc(100vw - calc(var(--mirai-ui-space-M) * 2));
  }
}

@media only screen and (max-width: 480px) {
  .YRq8BG_compact {
    padding: var(--mirai-ui-space-XS) 0;
    flex: 1;
    justify-content: center;
    width: 100%;
  }

  .YRq8BG_compact > :not(.YRq8BG_button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  }

  .YRq8BG_compact .YRq8BG_calendar {
    flex: 1;
    justify-content: center;
  }

  .YRq8BG_compact .YRq8BG_promocode {
    max-width: var(--mirai-ui-space-L);
  }

  .YRq8BG_compact .YRq8BG_button {
    margin-right: var(--mirai-ui-space-XS);
  }
}

@media only screen and (min-width: 481px) {
  .YRq8BG_finder:not(.YRq8BG_column):not(.YRq8BG_expand) {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .YRq8BG_compact {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) 0;
  }

  .YRq8BG_compact:not(.YRq8BG_hasPlaces) .YRq8BG_caption {
    max-width: 25vw;
  }

  .YRq8BG_compact.YRq8BG_hasPlaces .YRq8BG_caption {
    max-width: 17.5vw;
  }

  .YRq8BG_finder.YRq8BG_expand {
    width: 100%;
  }

  [data-mirai-component="finder"]:has(.YRq8BG_finder.YRq8BG_expand) {
    width: 100%;
  }

  .YRq8BG_finder.YRq8BG_expand > ui-layer {
    flex: 1;
  }

  .YRq8BG_finder.YRq8BG_expand > ui-layer > ui-pressable {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .YRq8BG_compact > :not(.YRq8BG_button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  }
}

@media only screen and (min-width: 1180px) {
  .YRq8BG_finder {
    max-width: var(--mirai-ui-breakpoint-content);
  }

  .YRq8BG_compact > :not(.YRq8BG_button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-M);
  }
}

.LV1vkq_container {
  background-color: var(--mirai-chat-background);
  padding-bottom: calc(var(--mirai-chat-space) * 2);
  z-index: var(--mirai-chat-layer);
  place-content: center;
  align-items: center;
  width: 100svw;
  height: 100svh;
  overflow: hidden;
}

.LV1vkq_messages {
  gap: calc(var(--mirai-chat-space) * 2);
  padding-bottom: calc(var(--mirai-chat-space) * 2);
  padding-top: calc(var(--mirai-chat-space) * 5);
  flex: 1;
  width: 100%;
}

.LV1vkq_input {
  width: calc(100% - calc(var(--mirai-chat-space) * 2));
  max-width: var(--mirai-chat-content-max-width);
}

.LV1vkq_warning {
  bottom: calc(var(--mirai-chat-space) / 2);
  position: absolute;
}

@media print {
  .LV1vkq_container {
    display: none;
  }
}

.hfw90G_avatar, .hfw90G_image {
  border-radius: var(--mirai-chat-avatar-border-radius);
  height: var(--mirai-chat-avatar-size);
  width: var(--mirai-chat-avatar-size);
  z-index: -1;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hfw90G_avatar.hfw90G_small, .hfw90G_image.hfw90G_small {
  height: calc(var(--mirai-chat-avatar-size) / 2);
  min-height: calc(var(--mirai-chat-avatar-size) / 2);
  min-width: calc(var(--mirai-chat-avatar-size) / 2);
  width: calc(var(--mirai-chat-avatar-size) / 2);
}

.hfw90G_avatar {
  background: radial-gradient(circle at 50% 50%, var(--mirai-chat-avatar-color-1), var(--mirai-chat-avatar-color-2) 40%, var(--mirai-chat-avatar-color-3) 70%, var(--mirai-chat-avatar-color-4) 90%);
  box-shadow: 0 0 4px var(--mirai-chat-avatar-color-2), 0 0 8px var(--mirai-chat-avatar-color-3);
  background-size: 200% 200%;
  animation: 5s linear infinite hfw90G_avatarRotate, 2.5s ease-in-out infinite hfw90G_avatarGlow;
  position: relative;
  overflow: hidden;
}

.hfw90G_avatar.hfw90G_small {
  box-shadow: 0 0 2px var(--mirai-chat-avatar-color-2), 0 0 4px var(--mirai-chat-avatar-color-3);
  animation: 5s linear infinite hfw90G_avatarRotate, 2.5s ease-in-out infinite hfw90G_avatarGlowSmall;
}

.hfw90G_avatar .hfw90G_face {
  background-color: var(--mirai-chat-avatar-background);
  border-radius: var(--mirai-chat-avatar-border-radius);
  width: 88%;
  height: 88%;
  position: absolute;
  top: 6%;
  left: 6%;
}

.hfw90G_avatar.hfw90G_small .hfw90G_face {
  width: 86%;
  height: 86%;
  top: 7%;
  left: 7%;
}

.hfw90G_eyes {
  height: 17.5%;
  transition: transform 40ms;
  position: absolute;
  bottom: 27.5%;
  left: 0;
  right: 0;
}

.hfw90G_eye {
  background-color: #000;
  border-radius: 50%;
  width: 17.5%;
  height: 100%;
  animation: 8s infinite hfw90G_blink;
  position: absolute;
}

.hfw90G_eye.hfw90G_left {
  left: 27.5%;
}

.hfw90G_eye.hfw90G_right {
  right: 27.5%;
}

.hfw90G_eye:after {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 45%;
  height: 45%;
  position: absolute;
  top: 0%;
  left: 0%;
}

.hfw90G_avatar:not(.hfw90G_small) .hfw90G_eye {
  box-shadow: 0 0 0 6px #fff;
}

.hfw90G_avatar.hfw90G_small .hfw90G_eye {
  box-shadow: 0 0 0 3px #fff;
}

@keyframes hfw90G_avatarRotate {
  0% {
    background-position: 20% 20%;
  }

  25% {
    background-position: 80% 20%;
  }

  50% {
    background-position: 80% 80%;
  }

  75% {
    background-position: 20% 80%;
  }

  100% {
    background-position: 20% 20%;
  }
}

@keyframes hfw90G_avatarGlow {
  0%, 100% {
    box-shadow: 0 0 16px var(--mirai-chat-avatar-color-2), 0 0 32px var(--mirai-chat-avatar-color-3);
  }

  50% {
    box-shadow: 0 0 24px var(--mirai-chat-avatar-color-1), 0 0 48px var(--mirai-chat-avatar-color-4);
  }
}

@keyframes hfw90G_avatarGlowSmall {
  0%, 100% {
    box-shadow: 0 0 2px var(--mirai-chat-avatar-color-2), 0 0 4px var(--mirai-chat-avatar-color-3);
  }

  50% {
    box-shadow: 0 0 4px var(--mirai-chat-avatar-color-1), 0 0 8px var(--mirai-chat-avatar-color-4);
  }
}

@keyframes hfw90G_blink {
  0%, 100% {
    transform: scaleY(100%);
  }

  31% {
    transform: scaleY(100%);
  }

  32% {
    transform: scaleY(15%);
  }

  33% {
    transform: scaleY(100%);
  }

  34% {
    transform: scaleY(15%);
  }

  35% {
    transform: scaleY(100%);
  }

  61% {
    transform: scaleY(100%);
  }

  62% {
    transform: scaleY(15%);
  }

  63% {
    transform: scaleY(100%);
  }
}

.ro-b4q_buttonDispatcher {
  bottom: var(--mirai-chat-space);
  right: var(--mirai-chat-space);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  position: fixed;
  transform: translateY(250%);
}

.ro-b4q_buttonDispatcher.ro-b4q_left {
  left: var(--mirai-chat-space);
}

.ro-b4q_buttonDispatcher.ro-b4q_right {
  right: var(--mirai-chat-space);
}

.ro-b4q_buttonDispatcher.ro-b4q_top {
  top: var(--mirai-chat-space);
  transform: translateY(-25svh);
}

.ro-b4q_buttonDispatcher.ro-b4q_bottom {
  bottom: var(--mirai-chat-space);
  transform: translateY(25svh);
}

.ro-b4q_buttonDispatcher.ro-b4q_visible {
  z-index: var(--mirai-chat-layer);
  transform: translateY(0%);
}

.ro-b4q_buttonDispatcher .ro-b4q_avatar {
  box-shadow: var(--mirai-chat-box-shadow);
}

.ro-b4q_buttonDispatcher .ro-b4q_welcome {
  background-color: var(--mirai-chat-background);
  border-radius: var(--mirai-chat-border-radius);
  box-shadow: var(--mirai-chat-box-shadow);
  margin-bottom: var(--mirai-chat-space);
  opacity: 0;
  padding: var(--mirai-chat-space);
  transform-origin: 100% 100%;
  transition: all var(--mirai-ui-motion-collapse) cubic-bezier(.68, -.55, .27, 1.55);
  position: absolute;
  transform: scale(0);
}

.ro-b4q_buttonDispatcher.ro-b4q_left .ro-b4q_welcome {
  left: 0;
}

.ro-b4q_buttonDispatcher.ro-b4q_right .ro-b4q_welcome {
  right: 0;
}

.ro-b4q_buttonDispatcher.ro-b4q_top .ro-b4q_welcome {
  top: calc(var(--mirai-chat-avatar-size)  + calc(var(--mirai-chat-space) / 2));
}

.ro-b4q_buttonDispatcher.ro-b4q_bottom .ro-b4q_welcome {
  bottom: calc(var(--mirai-chat-avatar-size)  - calc(var(--mirai-chat-space) / 2));
}

.ro-b4q_buttonDispatcher .ro-b4q_welcome.ro-b4q_visible {
  opacity: 1;
  transform: scale(1);
}

.ro-b4q_buttonDispatcher .ro-b4q_welcome:before {
  border-style: solid;
  border-width: calc(var(--mirai-chat-space) / 2);
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.ro-b4q_buttonDispatcher.ro-b4q_left .ro-b4q_welcome:before {
  left: var(--mirai-ui-space-L);
}

.ro-b4q_buttonDispatcher.ro-b4q_right .ro-b4q_welcome:before {
  right: var(--mirai-ui-space-L);
}

.ro-b4q_buttonDispatcher.ro-b4q_top .ro-b4q_welcome:before {
  border-color: transparent transparent var(--mirai-chat-background) transparent;
  top: calc(var(--mirai-chat-space) / -1);
}

.ro-b4q_buttonDispatcher.ro-b4q_bottom .ro-b4q_welcome:before {
  border-color: var(--mirai-chat-background) transparent transparent transparent;
  bottom: calc(var(--mirai-chat-space) / -1);
}

.ro-b4q_buttonDispatcher .ro-b4q_hi {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, var(--mirai-chat-avatar-color-2), var(--mirai-chat-avatar-color-3), var(--mirai-chat-avatar-color-2));
  color: #0000;
  margin-bottom: var(--mirai-ui-space-XXS);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  animation: 1.5s linear infinite alternate ro-b4q_textGradient;
}

@media only screen and (max-width: 480px) {
  .ro-b4q_welcome {
    width: calc(100vw - calc(var(--mirai-ui-space-M) * 2));
  }
}

@media only screen and (min-width: 481px) {
  .ro-b4q_welcome {
    width: calc(var(--mirai-ui-breakpoint-S)  - calc(var(--mirai-ui-space-XXL) * 2));
  }
}

@keyframes ro-b4q_textGradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

._3-84jq_header {
  background-color: var(--mirai-chat-background);
  padding: var(--mirai-chat-space);
  transition: height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  z-index: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
}

._3-84jq_header ._3-84jq_avatar {
  margin-right: var(--mirai-ui-space-S);
}

._3QqjZq_container {
  background-color: var(--mirai-chat-input-background);
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-input-background);
  border-radius: var(--mirai-chat-border-radius);
  align-items: flex-end;
  gap: calc(var(--mirai-chat-space) / 2);
  padding: calc(var(--mirai-chat-space) / 1);
  z-index: 0;
  transition: border var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  justify-content: center;
}

._3QqjZq_container._3QqjZq_focus {
  border-color: var(--mirai-chat-border-color);
}

._3QqjZq_input {
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  line-height: var(--mirai-chat-text-line-height);
  text-align: var(--mirai-ui-text-align);
  -webkit-user-select: none;
  user-select: none;
  resize: none;
  background-color: #0000;
  border: none;
  outline: none;
  align-self: center;
  width: 100%;
  min-height: auto;
  margin: 0;
  overflow-y: hidden;
}

._3QqjZq_input:disabled, ._3QqjZq_input::placeholder {
  color: var(--mirai-ui-input-color-disabled);
  opacity: .66;
}

._3QqjZq_button {
  border-radius: 50%;
}

._3QqjZq_context {
  gap: 0 calc(var(--mirai-chat-space) / 2);
  flex-wrap: wrap;
}

._3QqjZq_context ._3QqjZq_field {
  background-color: var(--mirai-chat-background);
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: calc(var(--mirai-chat-space) * 2);
  padding: calc(var(--mirai-chat-space) / 2) calc(var(--mirai-chat-space) / 1);
}

._3QqjZq_context ._3QqjZq_field ._3QqjZq_icon {
  margin-right: calc(var(--mirai-chat-space) / 2);
}

@media screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  ._3QqjZq_input {
    font-size: var(--mirai-ui-input-font-size);
  }
}

.Ppdbjq_title {
  justify-content: space-between;
}

.geV7SW_card {
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  align-items: center;
  gap: calc(var(--mirai-chat-space) / 2);
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.geV7SW_card + .geV7SW_card {
  margin-top: calc(var(--mirai-chat-space) / 2);
}

.geV7SW_card.geV7SW_offset {
  padding: var(--mirai-chat-message-offset);
}

.geV7SW_card.geV7SW_pressable:not(.geV7SW_checked):not(.geV7SW_disabled):hover {
  background-color: var(--mirai-ui-content);
  border-color: var(--mirai-ui-content);
  cursor: pointer;
}

.geV7SW_card .geV7SW_image {
  background-size: contain;
  background-color: var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  min-height: var(--mirai-ui-space-XL);
  min-width: var(--mirai-ui-space-XL);
  align-self: flex-start;
}

.geV7SW_card.geV7SW_pressable:not(.geV7SW_checked):not(.geV7SW_disabled):hover [data-role="icon"], .geV7SW_card.geV7SW_pressable:not(.geV7SW_checked):not(.geV7SW_disabled):hover [data-role="text"] {
  color: var(--mirai-chat-background);
}

.geV7SW_card.geV7SW_disabled .geV7SW_image {
  filter: grayscale();
}

@media only screen and (max-width: 480px) {
  .geV7SW_card {
    width: auto;
  }
}

@media only screen and (min-width: 481px) {
  .geV7SW_card {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.gQ3O6G_typewriter {
  display: inline-block;
  position: relative;
}

.gQ3O6G_scaffold {
  visibility: hidden;
  white-space: pre-wrap;
  display: block;
}

.gQ3O6G_animated {
  white-space: pre-wrap;
  position: absolute;
  top: 0;
  left: 0;
}

._7Evlpq_environment {
  background-color: var(--mirai-chat-input-background);
  border-radius: var(--mirai-chat-border-radius);
  align-self: center;
  gap: var(--mirai-ui-space-XS);
  margin-top: calc(var(--mirai-chat-space) / 2);
  max-width: var(--mirai-chat-content-max-width);
  padding: calc(var(--mirai-chat-space) / 2) var(--mirai-chat-space);
  flex-wrap: wrap;
}

._7Evlpq_environment ._7Evlpq_variable {
  color: var(--mirai-ui-base);
  font-family: Courier New, Courier, monospace;
  font-weight: bold;
  display: block;
}

._7Evlpq_environment._7Evlpq_error {
  background-color: var(--mirai-ui-error-background);
}

._7Evlpq_environment._7Evlpq_error * {
  color: var(--mirai-ui-error);
}

@media only screen and (max-width: 480px) {
  ._7Evlpq_environment {
    margin-left: var(--mirai-chat-space);
  }
}

._c-bsa_list {
  flex: 1;
}

._c-bsa_list ._c-bsa_item {
  gap: calc(var(--mirai-chat-space) / 2);
  padding: var(--mirai-chat-message-offset);
  flex-direction: row;
  width: 100%;
  display: flex;
}

._c-bsa_list ._c-bsa_item._c-bsa_category {
  cursor: default;
  padding-bottom: 0;
}

._c-bsa_list ._c-bsa_item._c-bsa_category:not(:first-child) {
  border-top: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

._c-bsa_list ._c-bsa_item._c-bsa_option._c-bsa_selected, ._c-bsa_list ._c-bsa_item._c-bsa_option:hover {
  background-color: var(--mirai-ui-content);
}

._c-bsa_list ._c-bsa_item._c-bsa_option._c-bsa_selected *, ._c-bsa_list ._c-bsa_item._c-bsa_option:hover * {
  color: var(--mirai-ui-content);
}

@media only screen and (max-width: 480px) {
  ._c-bsa_list {
    max-height: calc(100svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}

@media only screen and (min-width: 481px) {
  ._c-bsa_list {
    max-height: calc(70svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}

._32TM9a_list {
  gap: var(--mirai-chat-space);
  width: 100%;
}

._32TM9a_item {
  border: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  border-radius: var(--mirai-chat-border-radius);
  flex-direction: row;
  display: flex;
}

._32TM9a_image {
  background-color: var(--mirai-ui-content-background);
  border-top-left-radius: var(--mirai-chat-border-radius);
  border-bottom-left-radius: var(--mirai-chat-border-radius);
  height: calc(var(--mirai-ui-space-XL) * 3);
  min-height: calc(var(--mirai-ui-space-XL) * 3);
  min-width: calc(var(--mirai-ui-space-XL) * 3);
  width: calc(var(--mirai-ui-space-XL) * 3);
  background-position: center;
  background-size: cover;
}

._32TM9a_content {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  justify-content: space-between;
}

._32TM9a_price {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
}

._32TM9a_lineThrough {
  text-decoration: line-through;
}

@media only screen and (max-width: 480px) {
  ._32TM9a_item {
    flex-basis: 100%;
  }
}

@media only screen and (min-width: 481px) {
  ._32TM9a_list {
    flex-flow: wrap;
  }

  ._32TM9a_item {
    flex-basis: calc(50% - calc(calc(var(--mirai-chat-space) / 2)  + calc(var(--mirai-chat-border-size) * 2)));
    max-width: calc(calc(var(--mirai-chat-content-max-width) / 2)  - calc(calc(var(--mirai-chat-space) / 2)  + calc(var(--mirai-chat-border-size) * 2)));
    flex-grow: 1;
  }

  ._32TM9a_item:hover {
    background-color: var(--mirai-ui-content);
    cursor: pointer;
  }

  ._32TM9a_item:hover ui-text {
    color: var(--mirai-ui-base);
  }
}

.MxoeZa_message {
  align-self: center;
  align-items: flex-end;
  gap: calc(var(--mirai-chat-space) / 2);
  max-width: var(--mirai-chat-content-max-width);
  width: 100%;
  animation: .2s cubic-bezier(.1, .1, .25, .9) MxoeZa_mirai-message-visible;
}

.MxoeZa_message.MxoeZa_auto {
  justify-content: left;
}

.MxoeZa_message:not(.MxoeZa_auto) {
  padding: 0 var(--mirai-chat-space);
  justify-content: right;
}

.MxoeZa_content {
  overflow: hidden;
}

.MxoeZa_content.MxoeZa_busy {
  padding: var(--mirai-ui-space-XS) 0;
  background-color: #0000;
  position: relative;
  overflow: visible;
}

.MxoeZa_content:not(.MxoeZa_auto) {
  border-radius: var(--mirai-chat-border-radius);
}

.MxoeZa_content:not(.MxoeZa_auto):not(.MxoeZa_busy) {
  background-color: var(--mirai-chat-accent);
  border-bottom-right-radius: 0;
}

.MxoeZa_content .MxoeZa_text {
  font-family: var(--mirai-chat-text-font);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  line-height: var(--mirai-chat-text-line-height);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.MxoeZa_content > .MxoeZa_text + * {
  margin-top: var(--mirai-chat-space);
}

.MxoeZa_content.MxoeZa_auto .MxoeZa_text {
  color: var(--mirai-ui-content);
}

.MxoeZa_content:not(.MxoeZa_auto) .MxoeZa_text {
  color: var(--mirai-chat-background);
  padding: var(--mirai-chat-message-offset);
}

.MxoeZa_content .MxoeZa_thumbnail {
  background-size: contain;
  background-color: var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  margin-right: var(--mirai-chat-space);
  min-height: var(--mirai-ui-space-XL);
  min-width: var(--mirai-ui-space-XL);
  align-self: flex-start;
}

.MxoeZa_content .MxoeZa_map {
  background-color: var(--mirai-chat-input-background);
  border: none;
  width: 100%;
  height: 180px;
  margin: 0;
}

.MxoeZa_content .MxoeZa_address {
  padding: var(--mirai-chat-message-offset);
}

.MxoeZa_requirement {
  width: 100%;
}

.MxoeZa_requirement.MxoeZa_disabled {
  filter: grayscale();
  pointer-events: none;
}

.MxoeZa_requirement.MxoeZa_form {
  border: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  border-radius: var(--mirai-chat-border-radius);
}

.MxoeZa_requirement > ui-input-text {
  padding: var(--mirai-chat-space) var(--mirai-chat-space) 0 var(--mirai-chat-space);
}

.MxoeZa_requirement > :not(ui-form) {
  width: 100%;
}

.MxoeZa_requirement .MxoeZa_footer {
  border-top: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  gap: var(--mirai-chat-space);
  padding: var(--mirai-chat-space);
  justify-content: flex-end;
}

.MxoeZa_requirement + ui-pressable {
  margin-top: var(--mirai-chat-space);
}

.MxoeZa_spinner {
  color: var(--mirai-ui-content);
  margin-left: calc(var(--mirai-chat-space) * .75);
  animation: .8s linear .4s infinite alternate MxoeZa_mirai-message-spinner;
  position: absolute;
}

.MxoeZa_spinner, .MxoeZa_spinner:before, .MxoeZa_spinner:after {
  background-color: var(--mirai-ui-content);
  color: var(--mirai-ui-content);
  height: calc(var(--mirai-chat-space) / 2.5);
  width: calc(var(--mirai-chat-space) / 2.5);
  border-radius: 50%;
}

.MxoeZa_spinner:before, .MxoeZa_spinner:after {
  content: "";
  animation: .8s infinite alternate MxoeZa_mirai-message-spinner;
  display: inline-block;
  position: absolute;
  top: 0;
}

.MxoeZa_spinner:before {
  left: calc(var(--mirai-chat-space) * -.65);
  animation-delay: 0s;
}

.MxoeZa_spinner:after {
  left: calc(var(--mirai-chat-space) * .65);
  animation-delay: .8s;
}

@media only screen and (max-width: 480px) {
  .MxoeZa_content {
    max-width: 100%;
  }

  .MxoeZa_message.MxoeZa_auto {
    padding: 0 var(--mirai-chat-space);
  }
}

@media only screen and (min-width: 481px) {
  .MxoeZa_content {
    max-width: 100%;
  }

  .MxoeZa_requirement.MxoeZa_form:not(.MxoeZa_calendar) {
    max-width: calc(calc(var(--mirai-chat-content-max-width) / 2)  - calc(calc(var(--mirai-chat-space) / 2)  + calc(var(--mirai-chat-border-size) * 2)));
  }

  .MxoeZa_requirement.MxoeZa_form.MxoeZa_calendar {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

@keyframes MxoeZa_mirai-message-spinner {
  0% {
    background-color: var(--mirai-ui-content-light);
  }

  50%, 100% {
    background-color: var(--mirai-ui-content-border);
  }
}

@keyframes MxoeZa_mirai-message-visible {
  from {
    opacity: 0;
    transform: translateY(var(--mirai-chat-space));
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.jaR39a_form {
  margin: var(--mirai-ui-space-M);
}

.jaR39a_description {
  margin-bottom: var(--mirai-ui-space-M);
}

.jaR39a_notification, .jaR39a_button {
  margin-top: var(--mirai-ui-space-M);
}

html {
  scroll-behavior: smooth;
}

.KkD-mq_settings {
  align-items: flex-start;
  gap: var(--mirai-ui-space-L);
}

.KkD-mq_settings .KkD-mq_menu {
  background-color: var(--mirai-ui-menu-base);
  border-radius: var(--mirai-ui-menu-border-radius);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-menu-option-color-disabled);
  width: var(--mirai-ui-breakpoint-S);
  overflow: hidden;
}

.KkD-mq_menu .KkD-mq_option {
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
  width: 100%;
  display: flex;
}

.KkD-mq_menu .KkD-mq_option:hover *, .KkD-mq_menu .KkD-mq_option.KkD-mq_active * {
  color: var(--mirai-ui-accent);
}

.KkD-mq_menu .KkD-mq_option:not(:last-child) {
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-menu-option-color-disabled);
}

.KkD-mq_settings .KkD-mq_form {
  gap: var(--mirai-ui-space-M);
}

.KkD-mq_form .KkD-mq_box {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-M);
  margin-top: var(--mirai-ui-space-L);
  padding: var(--mirai-ui-space-M);
}

.KkD-mq_form .KkD-mq_notification {
  margin-bottom: var(--mirai-ui-space-M);
}

.KkD-mq_lisa {
  margin: var(--mirai-ui-space-M);
  max-width: var(--mirai-ui-breakpoint-S);
}

.KkD-mq_descriptionAccount {
  margin-bottom: var(--mirai-ui-space-S);
}

.KkD-mq_modal {
  padding: var(--mirai-ui-space-M);
  gap: var(--mirai-ui-space-M);
}

.KkD-mq_modal .KkD-mq_description {
  text-align: center;
}

.KkD-mq_modal .KkD-mq_buttons {
  gap: var(--mirai-ui-space-M);
}

@media only screen and (max-width: 480px) {
  .KkD-mq_settings .KkD-mq_menu {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) {
  .KkD-mq_settings .KkD-mq_menu {
    width: var(--mirai-ui-breakpoint-S);
  }
}

html {
  scroll-behavior: smooth;
}

._5CrYMq_button {
  margin-top: var(--mirai-ui-space-L);
}

._5CrYMq_header {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-M);
}

._5CrYMq_description {
  color: var(--mirai-ui-content-light);
  margin-top: var(--mirai-ui-space-S);
}

._5CrYMq_form {
  padding: var(--mirai-ui-space-M);
}

._5CrYMq_notification {
  margin-bottom: var(--mirai-ui-space-L);
}

._5CrYMq_privacy {
  flex-direction: row;
  display: flex;
}

._5CrYMq_privacy ._5CrYMq_link {
  display: inline;
}

@media only screen and (min-width: 481px) {
  ._5CrYMq_container {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
    align-self: center;
    width: 100%;
  }
}

.ceVvIa_container {
  gap: var(--mirai-chat-space);
  max-width: var(--mirai-chat-content-max-width);
}

.ceVvIa_title.ceVvIa_hi {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, var(--mirai-chat-avatar-color-2), var(--mirai-chat-avatar-color-3), var(--mirai-chat-avatar-color-2));
  color: #0000;
  background-size: 200% 100%;
  -webkit-background-clip: text;
  animation: 1.5s linear infinite alternate ceVvIa_textGradient;
}

.ceVvIa_content {
  position: relative;
}

.ceVvIa_actions, .ceVvIa_prompts {
  transition: all var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
  position: absolute;
}

.ceVvIa_actions {
  gap: var(--mirai-chat-space);
  flex-wrap: wrap;
}

.ceVvIa_actions:not(.ceVvIa_visible), .ceVvIa_prompts:not(.ceVvIa_visible) {
  pointer-events: none;
}

.ceVvIa_actions:not(.ceVvIa_visible) {
  opacity: 0;
  transform: translateY(-16px);
}

.ceVvIa_prompts {
  gap: calc(var(--mirai-chat-space) / 2);
  flex-direction: column;
}

.ceVvIa_prompts:not(.ceVvIa_visible) {
  opacity: 0;
  transform: translateY(16px);
}

.ceVvIa_option {
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  flex: 1;
  display: flex;
}

.ceVvIa_actions .ceVvIa_option {
  gap: var(--mirai-chat-space);
  height: calc(var(--mirai-ui-space-XL) * 2);
  padding: var(--mirai-chat-space);
  flex-direction: column;
}

.ceVvIa_prompts .ceVvIa_option {
  box-sizing: border-box;
  align-items: center;
  gap: calc(var(--mirai-chat-space) / 2);
  padding: calc(var(--mirai-chat-space) / 2) var(--mirai-chat-space);
  flex-direction: row;
  width: 100%;
}

.ceVvIa_option:hover, .ceVvIa_option:active {
  background-color: var(--mirai-ui-content);
  border-color: var(--mirai-ui-content);
}

.ceVvIa_option:hover *, .ceVvIa_option:active * {
  color: var(--mirai-chat-background);
}

@media only screen and (max-width: 480px) {
  .ceVvIa_container {
    padding: var(--mirai-chat-space);
  }

  .ceVvIa_content {
    min-height: calc(var(--mirai-ui-space-XL) * 6);
  }

  .ceVvIa_actions .ceVvIa_option {
    min-width: 25%;
  }

  .ceVvIa_input {
    bottom: calc(var(--mirai-chat-space) * 2);
    left: var(--mirai-chat-space);
    right: var(--mirai-chat-space);
    position: absolute;
  }
}

@media only screen and (min-width: 481px) {
  .ceVvIa_title {
    font-size: calc(var(--mirai-ui-font-size-headline-1) * 1.5);
    line-height: calc(var(--mirai-ui-font-size-headline-1) * 1.75);
  }
}

@keyframes ceVvIa_textGradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.l6HrQW_container {
  gap: var(--mirai-ui-space-XS);
}

.l6HrQW_container > :not(:last-child) {
  margin-bottom: calc(var(--mirai-ui-input-text-padding-y) / 2);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

._9GQzMG_container {
  background-color: var(--mirai-ui-content-background);
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  padding: var(--mirai-ui-space-M);
  align-items: center;
  width: 100vw;
}

._9GQzMG_progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._9GQzMG_content {
  align-items: flex-start;
  gap: var(--mirai-ui-input-text-margin-bottom);
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

._9GQzMG_content > ._9GQzMG_main {
  row-gap: var(--mirai-ui-input-text-margin-bottom);
}

._9GQzMG_content > * {
  flex: 1;
}

._9GQzMG_box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

._9GQzMG_field {
  color: var(--mirai-ui-content-light);
  margin-bottom: calc(var(--mirai-ui-space-XXS) / 2);
}

._9GQzMG_notificationExtras {
  gap: var(--mirai-ui-space-M);
  justify-content: space-between;
}

._9GQzMG_form {
  column-gap: var(--mirai-ui-input-text-margin-bottom);
  flex-wrap: wrap;
  display: flex;
}

._9GQzMG_form > * {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
}

._9GQzMG_form ._9GQzMG_inline, ._9GQzMG_session ._9GQzMG_action, ._9GQzMG_agency ._9GQzMG_action {
  display: inline;
}

._9GQzMG_modal ._9GQzMG_payment {
  padding: var(--mirai-ui-space-M);
}

._9GQzMG_modal ._9GQzMG_scrollview {
  max-height: calc(100svh - var(--mirai-ui-space-XXL) * 3.5);
  border-radius: 0 0 var(--mirai-ui-modal-border-radius) var(--mirai-ui-modal-border-radius);
  align-items: normal;
}

._9GQzMG_scrollview ._9GQzMG_others {
  border-radius: 0 0 var(--mirai-ui-modal-border-radius) var(--mirai-ui-modal-border-radius);
  box-shadow: none;
}

._9GQzMG_totalPrice {
  gap: var(--mirai-ui-space-XXS);
  flex: 1;
}

@media only screen and (max-width: 480px) {
  ._9GQzMG_notificationExtras > :first-child {
    max-width: 70%;
  }

  ._9GQzMG_cta {
    margin-top: var(--mirai-ui-space-L);
  }

  ._9GQzMG_modal {
    max-height: 100svh;
  }
}

@media only screen and (max-width: 1179px) {
  ._9GQzMG_content, ._9GQzMG_form {
    flex-direction: column;
  }

  ._9GQzMG_confirmation {
    background-color: var(--mirai-ui-base);
    border-top: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-M);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@media only screen and (min-width: 1180px) {
  ._9GQzMG_modal {
    max-width: calc(var(--mirai-ui-breakpoint-M) * .9);
  }

  ._9GQzMG_form ._9GQzMG_half {
    min-width: calc(50% - calc(var(--mirai-ui-input-text-margin-bottom) / 2));
    max-width: calc(50% - calc(var(--mirai-ui-input-text-margin-bottom) / 2));
  }

  ._9GQzMG_form ._9GQzMG_wide {
    min-width: 100%;
  }

  ._9GQzMG_confirmation {
    background-color: var(--mirai-ui-base);
    border-radius: var(--mirai-ui-border-radius);
    box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
    max-width: calc(var(--mirai-ui-breakpoint-S)  - var(--mirai-ui-space-M) * 2);
    top: var(--mirai-ui-space-M);
    position: -webkit-sticky;
    position: sticky;
  }

  ._9GQzMG_confirmation ._9GQzMG_section {
    padding: var(--mirai-ui-space-M);
    gap: var(--mirai-ui-space-S);
  }

  ._9GQzMG_confirmation ._9GQzMG_section:not(:last-child) {
    border-bottom: solid 1px var(--mirai-ui-content-border);
  }

  ._9GQzMG_confirmation > ._9GQzMG_section:last-child {
    padding-top: 0;
  }

  ._9GQzMG_confirmation ._9GQzMG_footer {
    text-align: center;
  }
}

.f4AGXa_header {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-XS);
  justify-content: space-between;
}

.f4AGXa_header .f4AGXa_text {
  padding-left: var(--mirai-ui-space-XS);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.f4AGXa_footer {
  flex: 1;
}

.f4AGXa_notification {
  color: var(--mirai-ui-content-light);
}

@media only screen and (max-width: 480px) {
  .f4AGXa_ratesExplorer {
    padding-bottom: var(--mirai-ui-space-M);
    width: 100%;
  }

  .f4AGXa_header {
    margin-bottom: var(--mirai-ui-space-M);
  }

  .f4AGXa_fieldCalendar {
    margin: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .f4AGXa_extras {
    gap: var(--mirai-ui-space-S);
    padding: 0 var(--mirai-ui-space-M);
  }

  .f4AGXa_cta {
    margin-top: var(--mirai-ui-space-XS);
  }

  .f4AGXa_notification {
    border-top: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .f4AGXa_footer {
    border-top: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-M) var(--mirai-ui-space-L);
    flex: 1;
    justify-content: space-between;
    display: flex;
  }

  .f4AGXa_content.f4AGXa_calendarXS {
    max-height: calc(var(--mirai-ui-calendar-cell) * 8);
  }
}

@media only screen and (min-width: 481px) {
  .f4AGXa_ratesExplorer {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .f4AGXa_calendarInfo {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
  }

  .f4AGXa_notification {
    max-width: var(--mirai-deals-notification-max-width);
  }

  .f4AGXa_extras {
    border-top: solid 1px var(--mirai-ui-content-border);
    gap: var(--mirai-ui-space-XS);
    padding: var(--mirai-ui-space-M);
  }

  .f4AGXa_footer {
    justify-content: flex-end;
  }

  .f4AGXa_cta {
    height: var(--mirai-field-height);
  }
}

h2.f4AGXa_title {
  color: var(--mirai-ui-content);
  font-size: var(--mirai-ui-font-size-headline-2);
  font-weight: var(--mirai-ui-font-weight);
  line-height: var(--mirai-ui-line-height-headline-2);
}

.sU8I6G_container {
  gap: var(--mirai-ui-space-XXS);
  width: auto;
}

.sU8I6G_pressableState {
  border-radius: var(--mirai-ui-border-radius);
  align-items: center;
  gap: var(--mirai-ui-space-XS);
  height: calc(var(--mirai-ui-space-L)  + var(--mirai-ui-space-S));
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
  background-color: #000000a8;
  flex-direction: row;
  display: flex;
}

.sU8I6G_pressableState * {
  color: var(--mirai-ui-base);
}

.sU8I6G_state {
  border-radius: var(--mirai-ui-border-radius);
  bottom: var(--mirai-ui-space-M);
  top: var(--mirai-ui-space-M);
  left: var(--mirai-ui-space-M);
  right: var(--mirai-ui-space-M);
  z-index: var(--mirai-ui-layer-XL);
  background-color: #000000e6;
  align-items: center;
  position: fixed;
  overflow: scroll;
}

.sU8I6G_state * {
  color: var(--mirai-ui-base);
}

.sU8I6G_state .sU8I6G_header {
  align-items: "center";
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  background-color: #000000a8;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sU8I6G_state .sU8I6G_header .sU8I6G_name {
  align-items: "center";
  gap: var(--mirai-ui-space-XS);
}

.sU8I6G_state .sU8I6G_code {
  padding: var(--mirai-ui-space-S);
  width: 100%;
  font-family: Courier New, Courier, monospace;
  font-weight: bold;
  overflow-y: scroll;
}

._9PTKnq_container {
  gap: var(--mirai-ui-space-M);
}

._9PTKnq_title {
  justify-content: space-between;
  align-items: center;
}

._9PTKnq_notification {
  align-self: center;
}

._9PTKnq_card ._9PTKnq_spaceBetween {
  justify-content: space-between;
}

._9PTKnq_card ._9PTKnq_image {
  background-color: var(--mirai-ui-content-border);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  height: var(--mirai-card-image-height);
  background-position: center;
  background-size: cover;
}

._9PTKnq_search ._9PTKnq_form {
  width: 100%;
}

._9PTKnq_search ._9PTKnq_input {
  margin-top: 0;
}

._9PTKnq_search ._9PTKnq_button {
  margin-bottom: var(--mirai-ui-space-M);
}

._9PTKnq_actionSearch {
  align-self: flex-end;
}

span:has(._9PTKnq_actionSearch) {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  ._9PTKnq_card ._9PTKnq_offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }
}

@media only screen and (min-width: 481px) {
  ._9PTKnq_card ._9PTKnq_offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  ._9PTKnq_search {
    max-width: 320px;
  }
}

.z0GfBq_container {
  max-width: var(--mirai-ui-breakpoint-content);
  padding: var(--mirai-ui-space-M);
}

._8dAsqa_card ._8dAsqa_offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

._8dAsqa_card ._8dAsqa_row {
  flex-direction: var(--mirai-ui-row-direction);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  display: flex;
}

._8dAsqa_card ._8dAsqa_spaceBetween {
  justify-content: space-between;
  align-items: center;
}

._8dAsqa_card ._8dAsqa_slider {
  background-color: var(--mirai-ui-content-border);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  width: 100%;
  height: 208px;
  position: relative;
}

._8dAsqa_card > :not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

._8dAsqa_card > :nth-last-child(2) {
  flex: 1;
}

._8dAsqa_card ._8dAsqa_summary {
  margin-bottom: var(--mirai-ui-space-XXS);
}

._8dAsqa_card ._8dAsqa_summary ._8dAsqa_content {
  justify-content: space-between;
  align-items: flex-end;
}

._8dAsqa_card ._8dAsqa_summary ._8dAsqa_price {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
  margin-top: var(--mirai-ui-space-XXS);
}

._8dAsqa_card ._8dAsqa_summary ._8dAsqa_price ._8dAsqa_lineThrough {
  text-decoration: line-through;
}

._8dAsqa_preview {
  position: relative;
}

._8dAsqa_preview ._8dAsqa_tags {
  gap: var(--mirai-ui-space-XS);
  margin: var(--mirai-ui-space-S);
  position: absolute;
  top: 0;
  left: 0;
}

._8dAsqa_summary ._8dAsqa_tags {
  gap: var(--mirai-ui-space-XXS);
  flex-wrap: wrap;
}

._8dAsqa_preview ._8dAsqa_tags ._8dAsqa_tag, ._8dAsqa_summary ._8dAsqa_tag {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
  display: flex;
}

._8dAsqa_summary ._8dAsqa_tag {
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  padding: 0 var(--mirai-ui-space-XXS);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
}

._8dAsqa_summary ._8dAsqa_tag ._8dAsqa_truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 12ch;
  overflow: hidden;
}

._8dAsqa_summary ._8dAsqa_tag > ui-text {
  color: var(--mirai-ui-base);
}

@media only screen and (max-width: 480px) {
  ._8dAsqa_card ._8dAsqa_gap {
    gap: var(--mirai-ui-space-XXS);
  }

  ._8dAsqa_card ._8dAsqa_offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  ._8dAsqa_card > ._8dAsqa_offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-S);
  }

  ._8dAsqa_summary ._8dAsqa_tag ._8dAsqa_truncate {
    width: 8ch;
  }
}

@media only screen and (min-width: 481px) {
  ._8dAsqa_card ._8dAsqa_gap {
    gap: var(--mirai-ui-space-XS);
  }

  ._8dAsqa_card ._8dAsqa_offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  ._8dAsqa_card > ._8dAsqa_offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-M);
  }
}

.h-GNDa_columns {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XXS);
}

.h-GNDa_columns > * {
  gap: var(--mirai-ui-space-XS);
}

.h-GNDa_content {
  gap: var(--mirai-ui-space-XXS);
}

.h-GNDa_list {
  gap: var(--mirai-ui-space-XS);
}

.h-GNDa_text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.h-GNDa_textDoubleLine {
  -webkit-line-clamp: 2;
}

@media only screen and (min-width: 481px) {
  .h-GNDa_columns {
    flex-direction: var(--mirai-ui-row-direction);
    flex-wrap: wrap;
    gap: 5%;
  }

  .h-GNDa_columns > * {
    flex-basis: 47.5%;
  }

  .h-GNDa_oversized {
    align-items: flex-start;
    gap: var(--mirai-ui-space-S) 1%;
    flex-wrap: wrap;
  }

  .h-GNDa_oversized > * {
    margin-bottom: var(--mirai-ui-space-XXS);
    flex-basis: 24%;
    align-items: center;
  }

  .h-GNDa_oversized .h-GNDa_text {
    text-align: center;
  }
}

.lFXedq_row {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XXS);
}

.lFXedq_row.lFXedq_centered {
  align-items: center;
}

.d-ZiTq_features {
  gap: var(--mirai-ui-space-S);
  flex: 1;
}

.d-ZiTq_features .d-ZiTq_item {
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
}

.psK0Ka_content {
  gap: var(--mirai-ui-space-L);
  margin: var(--mirai-ui-space-M);
}

.psK0Ka_section {
  gap: var(--mirai-ui-space-XXS);
}

@media only screen and (min-width: 481px) {
  .psK0Ka_modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
    max-height: 90svh;
  }
}

.m5Wcqq_section {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  width: 100%;
}

.m5Wcqq_section:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.m5Wcqq_box {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-border-radius);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-XS);
}

.m5Wcqq_beds {
  gap: var(--mirai-ui-space-M);
}

.m5Wcqq_bedDisposition {
  gap: var(--mirai-ui-space-XS);
}

.m5Wcqq_slider {
  min-width: 738px;
  min-height: 400px;
}

@media only screen and (max-width: 480px) {
  .m5Wcqq_modal {
    max-height: var(--mirai-modal-max-height);
  }

  .m5Wcqq_scrollView {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) {
  .m5Wcqq_modal {
    max-width: 738px;
    max-height: 90svh;
  }
}

.ZQEz6q_slider {
  background-color: var(--mirai-ui-content-border);
  width: 100%;
  position: relative;
}

.ZQEz6q_slider .ZQEz6q_buttons {
  gap: var(--mirai-ui-space-S);
  left: calc(var(--mirai-ui-space-XXL) * 2);
  right: calc(var(--mirai-ui-space-XXL) * 2);
  justify-content: center;
  height: 100%;
  position: absolute;
}

.ZQEz6q_slider .ZQEz6q_buttons .ZQEz6q_button {
  background-color: var(--mirai-ui-slider-overlay);
  height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
}

.ZQEz6q_slider .ZQEz6q_buttons .ZQEz6q_icon {
  color: var(--mirai-ui-base);
}

.ZQEz6q_slider .ZQEz6q_tag {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-slider-border-radius);
  color: var(--mirai-ui-base);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
  right: var(--mirai-ui-space-S);
  top: var(--mirai-ui-space-S);
  position: absolute;
}

.muFFCa_content {
  gap: var(--mirai-ui-space-L);
  margin: var(--mirai-ui-space-M);
}

.muFFCa_section, .muFFCa_included .muFFCa_row {
  gap: var(--mirai-ui-space-XXS);
}

@media only screen and (min-width: 481px) {
  .muFFCa_modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
    max-height: 90svh;
  }
}

.D-oxBq_content {
  border: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .D-oxBq_modal {
    height: 100svh;
  }
}

@media only screen and (min-width: 481px) {
  .D-oxBq_modal {
    width: 90svw;
    height: 90svh;
  }
}

.S64cWa_option {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XS);
  flex-direction: var(--mirai-ui-row-direction);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  display: flex;
}

.S64cWa_option:not(.S64cWa_outlined) {
  width: 100%;
}

.S64cWa_option.S64cWa_outlined {
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-M) var(--mirai-ui-space-S);
  flex: 1;
}

.S64cWa_option.S64cWa_outlined:not(.S64cWa_inline) {
  width: auto;
}

.S64cWa_option.S64cWa_outlined:not(.S64cWa_disabled):not(.S64cWa_checked):hover {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-accent-dark);
}

.S64cWa_option.S64cWa_outlined.S64cWa_checked {
  box-shadow: 0 0 0 calc(var(--mirai-ui-border-width) * 2) var(--mirai-ui-accent);
  z-index: 0;
}

.S64cWa_option.S64cWa_inline {
  flex-direction: column;
  align-items: center;
  gap: 0;
  height: auto;
}

.S64cWa_option.S64cWa_inline .S64cWa_texts, .S64cWa_option.S64cWa_inline .S64cWa_texts * {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.S64cWa_option.S64cWa_inline:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.S64cWa_option.S64cWa_inline:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.S64cWa_option.S64cWa_inline:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.S64cWa_option.S64cWa_disabled {
  filter: grayscale();
  opacity: .5;
}

.S64cWa_option .S64cWa_texts {
  flex: 1;
}

.S64cWa_option .S64cWa_texts.S64cWa_highlight .S64cWa_caption {
  padding: 0 var(--mirai-ui-space-XXS);
}

.S64cWa_option .S64cWa_content {
  gap: var(--mirai-ui-space-XXS);
}

.S64cWa_option.S64cWa_outlined:not(.S64cWa_inline) .S64cWa_title {
  height: var(--mirai-ui-line-height-action);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.S64cWa_option .S64cWa_texts.S64cWa_highlight .S64cWa_title {
  color: var(--mirai-ui-success);
  background-color: var(--mirai-ui-success-background);
  border-radius: var(--mirai-ui-space-XXS);
  padding: 0 var(--mirai-ui-space-XXS);
}

@media only screen and (max-width: 480px) {
  .S64cWa_option.S64cWa_outlined {
    padding: var(--mirai-ui-space-S);
  }

  .S64cWa_option .S64cWa_texts {
    align-self: center;
  }

  .S64cWa_option.S64cWa_inline .S64cWa_texts {
    gap: calc(var(--mirai-ui-space-XXS) / 2);
  }
}

@media only screen and (min-width: 481px) {
  .S64cWa_option.S64cWa_outlined {
    padding: var(--mirai-ui-space-M);
  }

  .S64cWa_option.S64cWa_inline .S64cWa_texts {
    gap: var(--mirai-ui-space-XXS);
  }
}

._9s1gIG_tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * .8);
}

._9s1gIG_breakdown {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-XXS) 0;
}

._9s1gIG_breakdown ._9s1gIG_item {
  justify-content: space-between;
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

._9s1gIG_breakdown ._9s1gIG_item > ui-text:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._9s1gIG_breakdown ui-text {
  color: var(--mirai-ui-base);
}

._9s1gIG_breakdown ._9s1gIG_anchor {
  border-bottom: solid 1px var(--mirai-ui-base);
  opacity: .33;
}

.XBXaGq_tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * .8);
  gap: var(--mirai-ui-space-XS);
}

.XBXaGq_tooltip .XBXaGq_included {
  gap: var(--mirai-ui-space-XXS);
}

.XBXaGq_tooltip .XBXaGq_included ui-text {
  color: var(--mirai-ui-base);
}

.XBXaGq_tooltip .XBXaGq_row {
  align-items: flex-start;
}

.XBXaGq_row {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XXS);
}

.XBXaGq_row.XBXaGq_centered {
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .XBXaGq_content {
    margin-top: calc(var(--mirai-ui-space-XXS) / 2);
    gap: calc(var(--mirai-ui-space-XXS) / 2);
  }

  .XBXaGq_row ui-text {
    margin-top: calc(var(--mirai-ui-space-XXS) / 2);
  }
}

@media only screen and (min-width: 481px) {
  .XBXaGq_content {
    margin-top: var(--mirai-ui-space-XXS);
    gap: var(--mirai-ui-space-XXS);
  }
}

.-WjXmG_tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * .8);
  gap: var(--mirai-ui-space-XS);
}

.-WjXmG_tooltip .-WjXmG_row {
  align-items: center;
  gap: var(--mirai-ui-space-M);
}

.-WjXmG_tooltip ui-text {
  color: var(--mirai-ui-base);
}

.-WjXmG_tooltip ui-text:first-of-type {
  flex: 1;
}

.yqxz3q_chart {
  justify-content: stretch;
  align-items: flex-end;
}

.yqxz3q_chart .yqxz3q_value {
  background-color: var(--mirai-ui-content);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  flex: 1;
}

.yqxz3q_chart .yqxz3q_value.yqxz3q_disabled {
  background-color: var(--mirai-ui-content-border);
}

.yqxz3q_range {
  background-color: var(--mirai-ui-content);
  height: var(--mirai-ui-border-width);
  margin-bottom: var(--mirai-ui-space-S);
  position: relative;
}

.yqxz3q_range .yqxz3q_input {
  background-color: #0000;
}

.yqxz3q_range .yqxz3q_input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content);
  cursor: pointer;
  height: var(--mirai-ui-space-L);
  margin-top: calc(calc(var(--mirai-ui-space-L)  - 1px) / -2);
  width: var(--mirai-ui-space-L);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
}

.yqxz3q_range .yqxz3q_input::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content);
  cursor: pointer;
  height: var(--mirai-ui-space-L);
  margin-top: calc(calc(var(--mirai-ui-space-L)  - 1px) / -2);
  width: var(--mirai-ui-space-L);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
}

.yqxz3q_range .yqxz3q_input::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content);
  cursor: pointer;
  height: var(--mirai-ui-space-L);
  margin-top: calc(calc(var(--mirai-ui-space-L)  - 1px) / -2);
  width: var(--mirai-ui-space-L);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
}

.yqxz3q_range .yqxz3q_input:active::-webkit-slider-thumb {
  box-shadow: var(--mirai-ui-shadow);
  transform: scale(1.25);
}

.yqxz3q_range .yqxz3q_input:active::-moz-range-thumb {
  box-shadow: var(--mirai-ui-shadow);
  transform: scale(1.25);
}

.yqxz3q_range .yqxz3q_input:active::-ms-thumb {
  box-shadow: var(--mirai-ui-shadow);
  transform: scale(1.25);
}

.yqxz3q_range .yqxz3q_input::-webkit-slider-runnable-track {
  color: #0000;
  background-color: #0000;
  border-color: #0000;
  width: 100%;
  height: 0;
}

.yqxz3q_range .yqxz3q_input::-moz-range-track {
  color: #0000;
  background-color: #0000;
  border-color: #0000;
  width: 100%;
  height: 0;
}

.yqxz3q_range .yqxz3q_input::-ms-track {
  color: #0000;
  background-color: #0000;
  border-color: #0000;
  width: 100%;
  height: 0;
}

.yqxz3q_range .yqxz3q_input::-ms-fill-lower {
  background-color: var(--mirai-ui-content);
}

.yqxz3q_range .yqxz3q_input::-ms-fill-upper {
  background-color: var(--mirai-ui-content);
}

.yqxz3q_inputNumbers {
  gap: var(--mirai-ui-space-M);
}

.yqxz3q_inputNumbers .yqxz3q_input {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .yqxz3q_chart {
    gap: var(--mirai-ui-space-XS);
    height: var(--mirai-ui-space-XXL);
    padding: 0 var(--mirai-ui-space-XS);
  }
}

@media only screen and (min-width: 481px) {
  .yqxz3q_chart {
    gap: var(--mirai-ui-space-S);
    height: calc(var(--mirai-ui-space-XXL) * 2);
    padding: 0 var(--mirai-ui-space-S);
  }
}

.xRSaKa_modal {
  z-index: var(--mirai-ui-layer-S);
}

.xRSaKa_button {
  gap: var(--mirai-ui-space-XXS);
  overflow: visible !important;
}

.xRSaKa_button .xRSaKa_counter {
  background-color: var(--mirai-ui-button-secondary-color);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-base);
  color: var(--mirai-ui-base);
  height: var(--mirai-ui-space-S);
  width: var(--mirai-ui-space-S);
  line-height: var(--mirai-ui-space-S);
  text-align: center;
  top: calc(var(--mirai-ui-space-S) * -.5);
  padding: var(--mirai-ui-space-XXS);
  right: calc(var(--mirai-ui-space-S) * -.5);
  border-radius: 50%;
  position: absolute;
}

.xRSaKa_scrollView {
  padding: 0 var(--mirai-ui-space-M);
  max-width: calc(100% - calc(var(--mirai-ui-space-M) * 2));
  width: 100%;
}

.xRSaKa_section {
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-L) 0;
  width: 100%;
}

.xRSaKa_section:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

.xRSaKa_section .xRSaKa_boards {
  align-items: stretch;
}

.xRSaKa_section .xRSaKa_boards:not(.xRSaKa_inline) {
  gap: var(--mirai-ui-space-XS);
}

.xRSaKa_section .xRSaKa_amenities {
  flex-wrap: wrap;
}

.xRSaKa_section .xRSaKa_textOthers + .xRSaKa_amenities {
  margin-top: var(--mirai-ui-space-M);
}

.xRSaKa_section [data-role="input-option"] [data-role="text"] {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
}

.xRSaKa_textInline {
  display: inline;
}

.xRSaKa_spaceBetween {
  gap: var(--mirai-ui-space-M);
  justify-content: space-between;
}

.xRSaKa_footer {
  border-top: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-M);
}

@media only screen and (max-width: 480px) {
  .xRSaKa_modal {
    max-height: var(--mirai-modal-max-height);
  }

  .xRSaKa_button {
    margin-left: var(--mirai-ui-space-S);
  }

  .xRSaKa_section [data-role="input-option"] {
    width: 90%;
  }
}

@media only screen and (min-width: 481px) {
  .xRSaKa_button {
    position: absolute;
    right: 0;
  }

  .xRSaKa_button:hover .xRSaKa_counter {
    background-color: var(--mirai-ui-button-secondary-color-active);
  }

  .xRSaKa_modal {
    max-height: 90svh;
  }

  .xRSaKa_section .xRSaKa_amenities [data-role="input-option"] {
    max-width: 49%;
  }

  .xRSaKa_section .xRSaKa_amenities > :nth-child(odd) {
    margin-right: 2%;
  }

  .xRSaKa_section .xRSaKa_boards.xRSaKa_inline .xRSaKa_option {
    padding: var(--mirai-ui-space-L) var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .xRSaKa_modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
  }
}

@media only screen and (min-width: 1180px) {
  .xRSaKa_modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 1.5);
  }
}

._4PbAiG_pressable._4PbAiG_center[data-role] {
  justify-content: center;
}

._4PbAiG_pressable._4PbAiG_wide {
  width: 100%;
}

._4PbAiG_modal {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 770px;
}

@media only screen and (max-width: 480px) {
  ._4PbAiG_pressable {
    flex-grow: 1;
  }
}

._8EJtIq_card {
  border-radius: var(--mirai-ui-border-radius);
  height: 100%;
}

._8EJtIq_offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

._8EJtIq_card ._8EJtIq_row {
  flex-direction: var(--mirai-ui-row-direction);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  display: flex;
}

._8EJtIq_card ._8EJtIq_spaceBetween {
  justify-content: space-between;
  align-items: center;
}

._8EJtIq_card > :not(:last-child):not(:nth-last-child(3)) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

._8EJtIq_preview ._8EJtIq_details {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  min-height: var(--mirai-ui-space-XL);
  flex: 0;
  overflow: hidden;
}

._8EJtIq_preview {
  position: relative;
}

._8EJtIq_preview ._8EJtIq_tags {
  gap: var(--mirai-ui-space-XS);
  margin: var(--mirai-ui-space-S);
  position: absolute;
  top: 0;
  left: 0;
}

._8EJtIq_preview ._8EJtIq_tags > ui-text {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
  display: flex;
}

._8EJtIq_preview._8EJtIq_withRadius ._8EJtIq_slider {
  border-radius: var(--mirai-ui-border-radius) var(--mirai-ui-border-radius) 0 0;
}

._8EJtIq_preview ._8EJtIq_tag3D {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  padding: calc(var(--mirai-ui-space-XXS) / 2) calc(var(--mirai-ui-space-XXS) / 1);
}

._8EJtIq_card > :nth-last-child(2) {
  flex: 1;
}

._8EJtIq_card ._8EJtIq_optionText {
  margin-right: calc(var(--mirai-ui-space-M)  + var(--mirai-ui-space-XS));
  position: relative;
}

._8EJtIq_card ._8EJtIq_optionText ui-pressable {
  margin-left: var(--mirai-ui-space-XXS);
  position: absolute;
}

._8EJtIq_card ._8EJtIq_optionText > ._8EJtIq_tags {
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  display: inline-flex;
}

._8EJtIq_card ._8EJtIq_optionText ._8EJtIq_tag {
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  color: var(--mirai-ui-success);
  border-radius: var(--mirai-ui-space-XXS);
  margin-right: var(--mirai-ui-space-XXS);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
}

._8EJtIq_card ._8EJtIq_optionText ._8EJtIq_tag._8EJtIq_highlight {
  background-color: var(--mirai-ui-accent);
  color: var(--mirai-ui-base);
  padding: 0 var(--mirai-ui-space-XXS);
  margin-top: var(--mirai-ui-space-XXS);
  display: flex;
}

._8EJtIq_card ._8EJtIq_optionText ._8EJtIq_tag > ui-icon {
  top: calc(var(--mirai-ui-space-XXS) / 3);
  position: relative;
}

._8EJtIq_card ._8EJtIq_optionText._8EJtIq_standalone {
  margin-left: calc(var(--mirai-ui-space-XXS) / 4);
}

._8EJtIq_card ._8EJtIq_inlineOptions {
  align-items: stretch;
}

._8EJtIq_card ._8EJtIq_gap, ._8EJtIq_preview ._8EJtIq_gap {
  gap: var(--mirai-ui-space-XS);
}

._8EJtIq_card ._8EJtIq_summary {
  margin-bottom: var(--mirai-ui-space-XXS);
}

._8EJtIq_summary ._8EJtIq_content {
  justify-content: space-between;
  align-items: flex-end;
}

._8EJtIq_summary ._8EJtIq_content._8EJtIq_end {
  justify-content: flex-end;
}

._8EJtIq_summary ._8EJtIq_price {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
  margin-top: var(--mirai-ui-space-XXS);
}

._8EJtIq_summary ._8EJtIq_lineThrough {
  text-decoration: line-through;
}

._8EJtIq_summary ._8EJtIq_priceDetails {
  margin-top: var(--mirai-ui-space-XXS);
  align-self: flex-start;
}

._8EJtIq_summary ._8EJtIq_button {
  align-items: flex-end;
}

._8EJtIq_summary ._8EJtIq_tags {
  gap: var(--mirai-ui-space-XXS);
  flex-wrap: wrap;
}

._8EJtIq_summary ._8EJtIq_tag {
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  background-color: var(--mirai-ui-accent);
  color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-space-XXS);
  padding: 0 var(--mirai-ui-space-XXS);
  margin-right: var(--mirai-ui-space-XXS);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
}

._8EJtIq_tag ._8EJtIq_truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 12ch;
  overflow: hidden;
}

._8EJtIq_tag > ui-text {
  color: var(--mirai-ui-base);
}

@media only screen and (max-width: 480px) {
  ._8EJtIq_offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  ._8EJtIq_offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-S);
  }

  ._8EJtIq_tag ._8EJtIq_truncate {
    width: 8ch;
  }
}

@media only screen and (min-width: 481px) {
  ._8EJtIq_offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  ._8EJtIq_offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-M);
  }

  ._8EJtIq_summary ._8EJtIq_pricePer {
    margin-top: calc(var(--mirai-ui-space-XXS) * -.5);
  }
}

.MQaHbW_container {
  gap: var(--mirai-ui-space-L);
  padding: var(--mirai-ui-space-L);
}

.MQaHbW_banner {
  background-color: var(--mirai-ui-accent-background);
  border: solid 1px var(--mirai-ui-accent-border);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
  width: 100%;
}

.MQaHbW_texts {
  gap: var(--mirai-ui-space-XXS);
}

.MQaHbW_text {
  color: var(--mirai-ui-accent-dark);
}

@media only screen and (max-width: 480px) {
  .MQaHbW_banner {
    padding: var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) {
  .MQaHbW_banner {
    max-width: calc(var(--mirai-ui-breakpoint-S)  - calc(var(--mirai-ui-space-M) * 2));
  }
}

._7JQ5lW_scrollView {
  background-color: var(--mirai-ui-content-background);
  width: initial;
}

._7JQ5lW_slider {
  margin-top: var(--mirai-ui-space-M);
  background-color: #0000;
}

._7JQ5lW_slider > ui-scrollview {
  align-items: stretch;
  gap: var(--mirai-ui-space-M);
}

._7JQ5lW_container {
  align-items: stretch;
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
  flex-wrap: wrap;
  width: 100%;
}

._7JQ5lW_card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

._7JQ5lW_card._7JQ5lW_selected {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color), var(--mirai-ui-focus-shadow);
}

._7JQ5lW_card ._7JQ5lW_anchor {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

._7JQ5lW_card ._7JQ5lW_gap {
  gap: var(--mirai-ui-space-XS);
}

._7JQ5lW_card ._7JQ5lW_offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

._7JQ5lW_card > ._7JQ5lW_offset:nth-last-child(2) {
  flex: 1;
}

._7JQ5lW_card ._7JQ5lW_spaceBetween {
  justify-content: space-between;
  align-items: center;
}

._7JQ5lW_card ._7JQ5lW_image {
  background-color: var(--mirai-ui-content-border);
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 192px;
}

._7JQ5lW_card ._7JQ5lW_inputs {
  margin: var(--mirai-ui-space-XXS) 0;
  flex: 1;
  align-items: flex-end;
}

._7JQ5lW_card ._7JQ5lW_inputs > * {
  margin-bottom: 0;
}

._7JQ5lW_slider ._7JQ5lW_card._7JQ5lW_selected {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color);
}

._7JQ5lW_slider ._7JQ5lW_card ._7JQ5lW_image {
  margin: var(--mirai-ui-border-width);
  width: auto;
}

._7JQ5lW_footer {
  box-shadow: inset 0 1px 0 var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-S);
  padding: var(--mirai-ui-space-M);
  justify-content: flex-end;
}

._7JQ5lW_footer ._7JQ5lW_total {
  flex: 1;
}

._7JQ5lW_priceDetails {
  margin-top: var(--mirai-ui-space-XXS);
}

@media only screen and (max-width: 480px) {
  ._7JQ5lW_modal {
    max-height: 100svh;
  }

  ._7JQ5lW_card ._7JQ5lW_gap {
    gap: var(--mirai-ui-space-XXS);
  }
}

@media only screen and (min-width: 481px) {
  ._7JQ5lW_modal {
    max-width: calc(calc(var(--mirai-ui-breakpoint-content) / 2)  + calc(var(--mirai-ui-space-XXL) * 3));
    max-height: 90svh;
  }

  ._7JQ5lW_card {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }

  ._7JQ5lW_card._7JQ5lW_standalone {
    max-width: calc(var(--mirai-ui-breakpoint-S) * .9);
    flex-basis: 100%;
  }

  ._7JQ5lW_card ._7JQ5lW_gap {
    gap: var(--mirai-ui-space-XS);
  }
}

.LkUWSG_modal {
  align-content: center;
  align-items: center;
  width: 100svw;
  height: 100svh;
}

.LkUWSG_map {
  width: 100%;
  height: 100%;
}

.LkUWSG_card {
  bottom: var(--mirai-ui-space-M);
  box-shadow: var(--mirai-ui-shadow);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  flex-direction: row;
  position: absolute;
  overflow: hidden;
}

.LkUWSG_card:not(.LkUWSG_visible) {
  transform: translateY(125%);
}

.LkUWSG_card.LkUWSG_visible {
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transform: translateY(0%);
}

.LkUWSG_image {
  background-color: var(--mirai-ui-content-background);
  height: calc(var(--mirai-ui-space-XXL) * 2);
  max-height: calc(var(--mirai-ui-space-XXL) * 2);
  max-width: calc(var(--mirai-ui-space-XXL) * 2);
  width: calc(var(--mirai-ui-space-XXL) * 2);
  background-size: cover;
}

.LkUWSG_content {
  flex: 1;
}

.LkUWSG_content .LkUWSG_location {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
}

.LkUWSG_content .LkUWSG_anchor {
  flex: 1;
}

@media only screen and (max-width: 480px) {
  .LkUWSG_card {
    left: var(--mirai-ui-space-S);
    right: var(--mirai-ui-space-S);
    width: auto;
  }

  .LkUWSG_content {
    padding: var(--mirai-ui-space-S);
  }

  .LkUWSG_image {
    height: calc(var(--mirai-ui-space-XXL) * 2);
    max-height: calc(var(--mirai-ui-space-XXL) * 2);
    max-width: calc(var(--mirai-ui-space-XXL) * 2);
    width: calc(var(--mirai-ui-space-XXL) * 2);
  }
}

@media only screen and (min-width: 481px) {
  .LkUWSG_card {
    max-width: var(--mirai-ui-breakpoint-S);
    width: 100%;
    margin: 0 auto;
  }

  .LkUWSG_content {
    padding: var(--mirai-ui-space-M);
  }

  .LkUWSG_image {
    height: calc(var(--mirai-ui-space-XXL) * 2.5);
    max-height: calc(var(--mirai-ui-space-XXL) * 2.5);
    max-width: calc(var(--mirai-ui-space-XXL) * 2.5);
    width: calc(var(--mirai-ui-space-XXL) * 2.5);
  }
}

@media only screen and (min-width: 1180px) {
  .LkUWSG_modal > .LkUWSG_card.LkUWSG_visible {
    width: auto;
  }
}

.M8whdq_iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.M8whdq_container {
  width: 100%;
  height: 100%;
}

.M8whdq_panel {
  border-right: 1px solid var(--mirai-ui-content-border);
  box-sizing: border-box;
  background-color: var(--mirai-ui-content-background);
  padding: var(--mirai-ui-space-M);
  height: 100%;
}

.M8whdq_scrollview {
  gap: var(--mirai-ui-space-M);
  max-height: 90svh;
}

.M8whdq_filters {
  top: calc(var(--mirai-ui-space-XXL)  + calc(var(--mirai-ui-space-M) / 2));
  right: var(--mirai-ui-space-XS);
  z-index: var(--mirai-ui-layer-M);
  position: absolute;
}

.M8whdq_collapse {
  background-color: var(--mirai-ui-base);
  height: var(--mirai-ui-button-small-height);
  top: var(--mirai-ui-space-S);
  left: var(--mirai-ui-space-S);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: var(--mirai-ui-button-small-height);
  z-index: 1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.M8whdq_slider {
  left: 0;
  bottom: var(--mirai-ui-space-M);
  background-color: #0000;
  position: absolute;
  width: 100svw !important;
}

.M8whdq_slider > * {
  overflow-y: clip;
  width: 100svw !important;
}

.M8whdq_slider .M8whdq_item {
  margin-left: var(--mirai-ui-space-M);
  scroll-snap-align: center;
}

.M8whdq_slider.M8whdq_reduced {
  height: 120px;
}

.M8whdq_slider .M8whdq_expanded {
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  bottom: var(--mirai-ui-space-M);
  transform: translateY(150%);
}

.M8whdq_slider .M8whdq_expanded:not(.M8whdq_visible) {
  transform: translateY(150%);
}

.M8whdq_slider .M8whdq_expanded.M8whdq_visible {
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transform: translateY(0);
}

.M8whdq_slider .M8whdq_reduced {
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  transform: translateY(150%);
}

.M8whdq_slider .M8whdq_reduced:not(.M8whdq_visible) {
  opacity: 0;
  position: absolute;
  transform: translateY(150%);
}

.M8whdq_slider .M8whdq_reduced.M8whdq_visible {
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  opacity: 1;
  transform: translateY(0%);
}

.M8whdq_card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  max-width: 320px;
  position: relative;
}

.M8whdq_card.M8whdq_last {
  margin-right: var(--mirai-ui-space-M);
}

.M8whdq_card.M8whdq_disabled {
  filter: grayscale();
}

.M8whdq_card .M8whdq_preview {
  justify-content: space-between;
}

.M8whdq_card .M8whdq_disabled .M8whdq_footer {
  justify-content: end;
}

.M8whdq_image {
  background-color: var(--mirai-ui-content-border);
  border-radius: var(--mirai-ui-border-radius) 0 0 var(--mirai-ui-border-radius);
  background-size: cover;
  width: 120px;
  height: 120px;
}

.M8whdq_content {
  padding: var(--mirai-ui-space-S);
  justify-content: space-between;
  width: 220px;
  height: 120px;
}

.M8whdq_content .M8whdq_title {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.M8whdq_footer {
  border-top: 1px solid var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-S);
  justify-content: space-between;
}

.M8whdq_footer > .M8whdq_hidden {
  visibility: hidden;
}

.M8whdq_card.M8whdq_disabled .M8whdq_footer {
  justify-content: end;
}

@media only screen and (max-width: 480px) {
  .M8whdq_modal {
    height: 100svh;
  }

  .M8whdq_card .M8whdq_preview {
    min-height: 420px;
  }
}

@media only screen and (min-width: 481px) {
  .M8whdq_modal {
    width: 100svw;
    height: 100svh;
  }

  .M8whdq_filters {
    top: calc(var(--mirai-ui-space-XXL)  + var(--mirai-ui-space-S));
  }
}

._6NIda_container {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-accent-border);
  align-items: normal;
  overflow: hidden;
}

._6NIda_container > :first-child > * {
  width: -webkit-max-content;
  width: max-content;
}

._6NIda_title {
  gap: var(--mirai-ui-space-S);
}

._6NIda_items {
  width: 100%;
  overflow-x: auto;
}

._6NIda_item {
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  ._6NIda_container > :first-child {
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-accent-border);
    padding: var(--mirai-ui-space-S);
  }

  ._6NIda_title {
    justify-content: space-between;
  }

  ._6NIda_items {
    padding: 0 var(--mirai-ui-space-XS);
    align-items: center;
  }

  ._6NIda_item {
    padding: var(--mirai-ui-space-XS) 0;
    min-width: 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 481px) {
  ._6NIda_container > * {
    padding: var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  ._6NIda_item {
    padding: var(--mirai-ui-space-XS) 0;
    width: 33%;
  }
}

@media only screen and (min-width: 1180px) {
  ._6NIda_items {
    margin-left: var(--mirai-ui-space-M);
  }

  ._6NIda_item {
    width: 25%;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

[data-mirai-component="rates"] {
  background-color: var(--mirai-ui-content-background);
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  z-index: 1;
  flex-direction: column;
  display: flex;
}

._5vcgmq_finder ._5vcgmq_wrapper {
  max-width: var(--mirai-ui-breakpoint-content);
  place-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

._5vcgmq_finder {
  align-items: center;
}

._5vcgmq_finder._5vcgmq_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

._5vcgmq_progress {
  z-index: var(--mirai-ui-layer-M);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

._5vcgmq_container {
  z-index: var(--mirai-ui-layer-S);
  align-items: center;
  width: 100vw;
  position: relative;
}

._5vcgmq_container ._5vcgmq_wrapper {
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
  margin: 0 auto;
}

._5vcgmq_container ._5vcgmq_extraContent {
  gap: var(--mirai-ui-space-M);
}

._5vcgmq_container > ._5vcgmq_content {
  width: 100%;
}

._5vcgmq_header {
  gap: var(--mirai-ui-space-XS);
  flex-wrap: wrap;
  justify-content: flex-end;
}

._5vcgmq_header._5vcgmq_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

._5vcgmq_header ._5vcgmq_switch {
  align-self: center;
  margin-bottom: 0;
}

._5vcgmq_header ._5vcgmq_wide {
  width: 100%;
}

._5vcgmq_roomSelector {
  gap: var(--mirai-ui-space-XS);
  flex: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._5vcgmq_roomSelector > * {
  min-width: -webkit-max-content;
  min-width: max-content;
}

._5vcgmq_roomSelector ._5vcgmq_separator {
  margin: 0 var(--mirai-ui-space-XXS);
  opacity: .33;
}

._5vcgmq_separator._5vcgmq_active {
  color: var(--mirai-ui-base);
}

._5vcgmq_separator._5vcgmq_disabled {
  color: var(--mirai-ui-content);
}

._5vcgmq_separator._5vcgmq_secondary {
  color: var(--mirai-ui-accent);
}

._5vcgmq_footer {
  background-color: var(--mirai-ui-base);
  box-shadow: inset 0 1px 0 var(--mirai-ui-content-border), var(--mirai-ui-modal-shadow);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  z-index: var(--mirai-ui-layer-content);
  width: 100vw;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
}

._5vcgmq_footer._5vcgmq_visible {
  transform: translateY(0%);
}

._5vcgmq_footer ._5vcgmq_expander {
  background-color: var(--mirai-ui-base);
}

._5vcgmq_footer ._5vcgmq_expander > ._5vcgmq_button {
  margin: auto;
}

._5vcgmq_footer ._5vcgmq_cartItem {
  box-shadow: inset 0 -1px 0 var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-XS);
}

._5vcgmq_cartItem ._5vcgmq_room {
  background-color: var(--mirai-ui-content-border);
  border-radius: calc(var(--mirai-ui-border-radius) / 2);
  align-items: center;
  gap: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-L);
  line-height: var(--mirai-ui-space-L);
  min-width: var(--mirai-ui-space-XL);
  padding: 0 var(--mirai-ui-space-XS);
}

._5vcgmq_cartItem ._5vcgmq_description {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

._5vcgmq_cartItem ._5vcgmq_buttonClose {
  background-color: var(--mirai-ui-content-background);
}

._5vcgmq_footer ._5vcgmq_summary {
  gap: var(--mirai-ui-space-S);
}

._5vcgmq_footer ._5vcgmq_totalPrice {
  gap: var(--mirai-ui-space-XXS);
  flex: 1;
}

._5vcgmq_unavailability {
  gap: var(--mirai-ui-space-S);
}

._5vcgmq_unavailability ._5vcgmq_banner {
  background-color: var(--mirai-ui-accent-background);
  border: solid 1px var(--mirai-ui-accent-border);
  margin: var(--mirai-ui-space-S) 0;
  border-radius: var(--mirai-ui-border-radius);
  align-items: center;
}

._5vcgmq_unavailability ._5vcgmq_banner ._5vcgmq_texts {
  gap: var(--mirai-ui-space-XXS);
  flex: 1;
}

._5vcgmq_unavailability ._5vcgmq_banner ._5vcgmq_title, ._5vcgmq_unavailability ._5vcgmq_banner ._5vcgmq_icon {
  color: var(--mirai-ui-accent-dark);
}

._5vcgmq_unavailability ._5vcgmq_banner ._5vcgmq_icon {
  font-size: var(--mirai-ui-space-XL);
  height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
  align-self: center;
}

._5vcgmq_unavailability ._5vcgmq_card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  height: 100%;
}

._5vcgmq_unavailability ._5vcgmq_card > :not(._5vcgmq_distribution) {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

._5vcgmq_unavailability ._5vcgmq_card > :not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

._5vcgmq_unavailability ._5vcgmq_card > :nth-last-child(2) {
  flex: 1;
}

._5vcgmq_unavailability ._5vcgmq_card ._5vcgmq_price {
  justify-content: space-between;
  align-items: flex-end;
}

._5vcgmq_unavailability ._5vcgmq_card ._5vcgmq_distribution {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-XS);
}

._5vcgmq_unavailability ._5vcgmq_card ._5vcgmq_distribution > * {
  background-color: var(--mirai-ui-content-background);
  padding: var(--mirai-ui-space-XS);
}

._5vcgmq_unavailability ._5vcgmq_card ._5vcgmq_anchorDates {
  margin: 0 var(--mirai-ui-space-XS);
}

._5vcgmq_unavailability ._5vcgmq_action {
  display: inline;
}

._5vcgmq_unavailability ._5vcgmq_action * {
  margin-right: calc(var(--mirai-ui-space-XS) / 8);
}

._5vcgmq_unavailability ._5vcgmq_spaceBetween {
  justify-content: space-between;
  align-items: center;
}

._5vcgmq_box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

._5vcgmq_blur {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: var(--mirai-ui-layer-M);
}

._5vcgmq_blur * {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  transform: none;
}

._5vcgmq_error {
  align-self: center;
}

@media only screen and (max-width: 480px) {
  ._5vcgmq_container {
    padding: 0 var(--mirai-ui-space-S) var(--mirai-ui-space-M) var(--mirai-ui-space-S);
    min-height: 100svh;
  }

  ._5vcgmq_container ._5vcgmq_content {
    gap: var(--mirai-ui-space-M);
    padding-top: var(--mirai-ui-space-M);
  }

  ._5vcgmq_finder {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    gap: var(--mirai-ui-space-XXS);
    padding: var(--mirai-ui-space-S);
  }

  ._5vcgmq_header ._5vcgmq_tag {
    opacity: .1;
    justify-content: space-between;
  }

  ._5vcgmq_roomSelector {
    background-color: var(--mirai-ui-base);
    border-bottom: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-S);
    z-index: var(--mirai-ui-layer-M);
    width: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  ._5vcgmq_unavailability ._5vcgmq_banner {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-S);
  }

  ._5vcgmq_unavailability ._5vcgmq_texts ui-text, ._5vcgmq_unavailability ._5vcgmq_contactInfo {
    text-align: center;
  }

  ._5vcgmq_unavailability ._5vcgmq_button {
    width: -moz-available;
    width: -webkit-fill-available;
  }

  ._5vcgmq_footer ._5vcgmq_cartItem {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  ._5vcgmq_footer ._5vcgmq_summary {
    padding: var(--mirai-ui-space-S);
    align-items: flex-end;
  }
}

@media only screen and (min-width: 481px) {
  ._5vcgmq_container {
    padding: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
    min-height: 66svh;
  }

  ._5vcgmq_finder {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding: var(--mirai-ui-space-M);
  }

  ._5vcgmq_header {
    margin-bottom: var(--mirai-ui-space-XS);
    margin-top: var(--mirai-ui-space-XS);
  }

  ._5vcgmq_header > * {
    margin: var(--mirai-ui-space-XS) 0;
  }

  ._5vcgmq_unavailability ._5vcgmq_banner {
    gap: var(--mirai-ui-space-M);
    padding: var(--mirai-ui-space-XL) var(--mirai-ui-space-L);
  }

  ._5vcgmq_footer {
    padding: 0 var(--mirai-ui-space-M);
  }

  ._5vcgmq_footer ._5vcgmq_cartItem {
    padding: var(--mirai-ui-space-S) 0;
  }

  ._5vcgmq_footer ._5vcgmq_summary {
    padding: var(--mirai-ui-space-M) 0;
  }
}

.KFejBG_container {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  border: solid 1px var(--mirai-ui-accent-border);
  align-items: normal;
  overflow: hidden;
}

.KFejBG_container > :not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-accent-border);
}

.KFejBG_highlight {
  background-color: var(--mirai-ui-accent-background);
  justify-content: space-between;
}

.KFejBG_highlight > :first-child {
  gap: var(--mirai-ui-space-XXS);
}

.KFejBG_highlight .KFejBG_title {
  color: var(--mirai-ui-accent-dark);
}

.KFejBG_list {
  align-items: flex-start;
  overflow: hidden;
}

.KFejBG_list > * {
  direction: var(--mirai-ui-text-direction);
  gap: var(--mirai-ui-space-XS);
  flex: 1;
}

.KFejBG_textInline {
  display: inline;
}

.KFejBG_filter {
  background-color: var(--mirai-ui-base);
  gap: var(--mirai-ui-space-M);
}

@media only screen and (max-width: 480px) {
  .KFejBG_container > * {
    padding: var(--mirai-ui-space-S);
  }

  .KFejBG_highlight {
    gap: var(--mirai-ui-space-M);
  }

  .KFejBG_list {
    gap: var(--mirai-ui-space-XS);
  }

  .KFejBG_list > * {
    width: 100%;
  }

  .KFejBG_filter {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 481px) {
  .KFejBG_container > * {
    padding: var(--mirai-ui-space-M);
  }

  .KFejBG_list {
    flex: 1;
  }

  .KFejBG_list > * {
    min-width: calc(25% - var(--mirai-ui-space-L));
    width: 25%;
  }

  .KFejBG_filter {
    border-radius: var(--mirai-ui-border-radius);
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .KFejBG_highlight > :first-child {
    max-width: 60%;
  }

  .KFejBG_list {
    gap: var(--mirai-ui-space-M);
    flex: 1;
  }
}

@media only screen and (min-width: 1180px) {
  .KFejBG_highlight > :first-child {
    max-width: 66%;
  }

  .KFejBG_list {
    gap: var(--mirai-ui-space-L);
  }
}

.ZZ2CxW_notifications {
  max-height: calc(100vh - calc(var(--mirai-ui-space-S) * 2));
  padding: var(--mirai-ui-space-S);
  z-index: var(--mirai-ui-layer-XL);
  width: 100%;
  position: fixed;
  top: 0;
}

.ZZ2CxW_notifications > .ZZ2CxW_item:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-S);
}

@media only screen and (max-width: 480px) {
  .ZZ2CxW_notifications {
    right: 0;
  }

  .ZZ2CxW_item {
    transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
    transform: translateX(110%);
  }

  .ZZ2CxW_item.ZZ2CxW_visible {
    transform: translateX(0%);
  }
}

@media only screen and (min-width: 481px) {
  .ZZ2CxW_notifications {
    left: calc(50% - calc(var(--mirai-ui-breakpoint-S) / 2));
    max-width: calc(var(--mirai-ui-breakpoint-S)  - calc(var(--mirai-ui-space-S) * 2));
  }

  .ZZ2CxW_item {
    opacity: 0;
    transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing), transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
    transform: translateY(-110%);
  }

  .ZZ2CxW_item.ZZ2CxW_visible {
    opacity: 1;
    transform: translateY(0%);
  }
}

/*# sourceMappingURL=index.css.map */
