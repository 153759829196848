.action {
  align-items: center;
  color: var(--mirai-ui-action-color);
  display: flex;
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  font-weight: var(--mirai-ui-action-font-weight);
  justify-content: center;
  position: relative;
  width: fit-content;
}

.action:focus-visible {
  outline-offset: calc(var(--mirai-ui-focus-width) / 2);
}

.action.bold {
  font-family: var(--mirai-ui-font-bold);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.action.disabled,
.action.disabled * {
  color: var(--mirai-ui-action-color-disabled);
}

.action:not(.disabled):focus,
.action:not(.disabled):hover {
  color: var(--mirai-ui-focus-color);
}

.paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
}

.small {
  font-size: var(--mirai-ui-font-size-small);
}

.tiny {
  font-size: var(--mirai-ui-font-size-tiny);
}

.underline,
.underline * {
  text-decoration: underline;
}

.wide {
  width: 100%;
}

.action * {
  margin-right: var(--mirai-ui-space-XXS);
}
