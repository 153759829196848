.container {
  background-color: var(--mirai-ui-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  border-radius: var(--mirai-ui-border-radius);
  display: flex;
}

.item .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--mirai-ui-space-M);
  width: auto;
}

.item .header:not(.selected) .image {
  filter: grayscale(1);
  opacity: 0.5;
}

.item:not(:first-child) .header,
.children {
  border-top: var(--mirai-ui-border-width) solid var(--mirai-ui-content-border);
}

.item .header .radio {
  margin-right: var(--mirai-ui-space-XS);
  pointer-events: none;
}

.item .header .title {
  flex: 1;
  margin-right: var(--mirai-ui-space-M);
}

.item .header .image {
  height: var(--mirai-ui-space-M);
  margin-left: var(--mirai-ui-space-XXS);
}

.item .header .image.disabled {
  filter: grayscale(1);
  opacity: 0.25;
}

.item .content {
  padding: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
}

.item .content .caption {
  color: var(--mirai-ui-content-light);
}

.item .content .caption.withComponent {
  margin-bottom: var(--mirai-ui-space-XS);
}

.children {
  padding: var(--mirai-ui-space-M);
}
