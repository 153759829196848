.list {
  flex: 1;
}

.list .item {
  display: flex;
  flex-direction: row;
  gap: calc(var(--mirai-chat-space) / 2);
  padding: var(--mirai-chat-message-offset);
  width: 100%;
}

.list .item.category {
  cursor: default;
  padding-bottom: 0;
}

.list .item.category:not(:first-child) {
  border-top: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

.list .item.option.selected,
.list .item.option:hover {
  background-color: var(--mirai-ui-content);
}

.list .item.option.selected *,
.list .item.option:hover * {
  color: var(--mirai-ui-content);
}

/* S */
@media only screen and (max-width: 480px) {
  .list {
    max-height: calc(100svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .list {
    max-height: calc(70svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}
