.field {
  width: 100%;
}

.field.inline {
  flex-direction: var(--mirai-ui-row-direction);
}

.field.inline > .label {
  min-width: calc(var(--mirai-ui-space-XL) * 3);
}

.field.inline > .inlineValue {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

.field:not(.inline) > .value,
.value > [data-role='text'] {
  text-align: justify;
  display: block;
}

@media screen {
  .field .print {
    display: none;
  }

  .field .tooltip {
    margin-left: var(--mirai-ui-space-XXS);
    text-decoration: underline;
  }
}

@media only screen and (max-width: 480px) {
  .field.withValue:not(:last-child) {
    margin-bottom: var(--mirai-ui-space-S);
  }
}

@media only screen and (min-width: 481px) {
  .field.withValue:not(:last-child) {
    margin-bottom: var(--mirai-ui-space-M);
  }
}

@media print {
  .field .noPrint {
    display: none;
  }

  .field .print {
    display: block;
  }
}
