.notifications {
  max-height: calc(100vh - calc(var(--mirai-ui-space-S) * 2));
  padding: var(--mirai-ui-space-S);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--mirai-ui-layer-XL);
}

.notifications > .item:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-S);
}

/* S */
@media only screen and (max-width: 480px) {
  .notifications {
    right: 0;
  }

  .item {
    transform: translateX(110%);
    transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  }

  .item.visible {
    transform: translateX(0%);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .notifications {
    left: calc(50% - calc(var(--mirai-ui-breakpoint-S) / 2));
    max-width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-S) * 2));
  }

  .item {
    opacity: 0;
    transform: translateY(-110%);
    transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
      transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  }

  .item.visible {
    opacity: 1;
    transform: translateY(0%);
  }
}
