.anchor {
  margin: 0 var(--mirai-ui-space-XS);
}

.legend {
  color: var(--mirai-ui-content-light);
}

@media only screen and (min-width: 481px) {
  .nights {
    gap: var(--mirai-ui-space-XS);
  }
}
