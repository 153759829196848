.container {
  gap: var(--mirai-ui-space-L);
  padding: var(--mirai-ui-space-L);
}

.banner {
  background-color: var(--mirai-ui-accent-background);
  border: solid 1px var(--mirai-ui-accent-border);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
  width: 100%;
}

.texts {
  gap: var(--mirai-ui-space-XXS);
}

.text {
  color: var(--mirai-ui-accent-dark);
}

/* S */
@media only screen and (max-width: 480px) {
  .banner {
    padding: var(--mirai-ui-space-M);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .banner {
    max-width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-M) * 2));
  }
}
