.modal {
  z-index: var(--mirai-ui-layer-S);
}

.button {
  gap: var(--mirai-ui-space-XXS);
  overflow: visible !important;
}

.button .counter {
  background-color: var(--mirai-ui-button-secondary-color);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-base);
  color: var(--mirai-ui-base);
  height: var(--mirai-ui-space-S);
  width: var(--mirai-ui-space-S);
  line-height: var(--mirai-ui-space-S);
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: calc(var(--mirai-ui-space-S) * -0.5);
  padding: var(--mirai-ui-space-XXS);
  right: calc(var(--mirai-ui-space-S) * -0.5);
}

.scrollView {
  padding: 0 var(--mirai-ui-space-M);
  max-width: calc(100% - calc(var(--mirai-ui-space-M) * 2));
  width: 100%;
}

.section {
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-L) 0;
  width: 100%;
}

.section:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

.section .boards {
  align-items: stretch;
}

.section .boards:not(.inline) {
  gap: var(--mirai-ui-space-XS);
}

.section .amenities {
  flex-wrap: wrap;
}

.section .textOthers + .amenities {
  margin-top: var(--mirai-ui-space-M);
}

.section [data-role='input-option'] [data-role='text'] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.textInline {
  display: inline;
}

.spaceBetween {
  gap: var(--mirai-ui-space-M);
  justify-content: space-between;
}

.footer {
  border-top: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-M);
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    max-height: var(--mirai-modal-max-height);
  }

  .button {
    margin-left: var(--mirai-ui-space-S);
  }

  .section [data-role='input-option'] {
    width: 90%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .button {
    position: absolute;
    right: 0;
  }

  .button:hover .counter {
    background-color: var(--mirai-ui-button-secondary-color-active);
  }

  .modal {
    max-height: 90svh;
  }

  .section .amenities [data-role='input-option'] {
    max-width: 49%;
  }

  .section .amenities > :nth-child(odd) {
    margin-right: 2%;
  }

  .section .boards.inline .option {
    padding: var(--mirai-ui-space-L) var(--mirai-ui-space-M);
  }
}

/* M & L */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
  }
}

/* M & L */
@media only screen and (min-width: 1180px) {
  .modal {
    max-width: calc(var(--mirai-ui-breakpoint-content) / 1.5);
  }
}
