.container {
  align-items: normal;
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  border: solid 1px var(--mirai-ui-accent-border);
  overflow: hidden;
}

.container > *:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-accent-border);
}

.highlight {
  justify-content: space-between;
  background-color: var(--mirai-ui-accent-background);
}

.highlight > :first-child {
  gap: var(--mirai-ui-space-XXS);
}

.highlight .title {
  color: var(--mirai-ui-accent-dark);
}

.list {
  align-items: flex-start;
  overflow: hidden;
}

.list > * {
  direction: var(--mirai-ui-text-direction);
  flex: 1;
  gap: var(--mirai-ui-space-XS);
}

.textInline {
  display: inline;
}

.filter {
  background-color: var(--mirai-ui-base);
  gap: var(--mirai-ui-space-M);
}

/* S */
@media only screen and (max-width: 480px) {
  .container > * {
    padding: var(--mirai-ui-space-S);
  }

  .highlight {
    gap: var(--mirai-ui-space-M);
  }

  .list {
    gap: var(--mirai-ui-space-XS);
  }

  .list > * {
    width: 100%;
  }

  .filter {
    justify-content: space-between;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container > * {
    padding: var(--mirai-ui-space-M);
  }

  .list {
    flex: 1;
  }

  .list > * {
    min-width: calc(25% - var(--mirai-ui-space-L));
    width: 25%;
  }

  .filter {
    border-radius: var(--mirai-ui-border-radius);
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .highlight > :first-child {
    max-width: 60%;
  }

  .list {
    flex: 1;
    gap: var(--mirai-ui-space-M);
  }
}

@media only screen and (min-width: 1180px) {
  .highlight > :first-child {
    max-width: 66%;
  }

  .list {
    gap: var(--mirai-ui-space-L);
  }
}
