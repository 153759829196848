.modal {
  height: 100svh;
  width: 100svw;
  align-items: center;
  align-content: center;
}

.map {
  height: 100%;
  width: 100%;
}

.card {
  bottom: var(--mirai-ui-space-M);
  box-shadow: var(--mirai-ui-shadow);
  flex-direction: row;
  overflow: hidden;
  position: absolute;
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.card:not(.visible) {
  transform: translateY(125%);
}

.card.visible {
  transform: translateY(0%);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.image {
  background-color: var(--mirai-ui-content-background);
  background-size: cover;
  height: calc(var(--mirai-ui-space-XXL) * 2);
  max-height: calc(var(--mirai-ui-space-XXL) * 2);
  max-width: calc(var(--mirai-ui-space-XXL) * 2);
  width: calc(var(--mirai-ui-space-XXL) * 2);
}

.content {
  flex: 1;
}

.content .location {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
}

.content .anchor {
  flex: 1;
}

/* S */
@media only screen and (max-width: 480px) {
  .card {
    width: auto;
    left: var(--mirai-ui-space-S);
    right: var(--mirai-ui-space-S);
  }

  .content {
    padding: var(--mirai-ui-space-S);
  }

  .image {
    height: calc(var(--mirai-ui-space-XXL) * 2);
    max-height: calc(var(--mirai-ui-space-XXL) * 2);
    max-width: calc(var(--mirai-ui-space-XXL) * 2);
    width: calc(var(--mirai-ui-space-XXL) * 2);
  }
}

/* M */
@media only screen and (min-width: 481px) {
  .card {
    margin: 0 auto;
    max-width: var(--mirai-ui-breakpoint-S);
    width: 100%;
  }

  .content {
    padding: var(--mirai-ui-space-M);
  }

  .image {
    height: calc(var(--mirai-ui-space-XXL) * 2.5);
    max-height: calc(var(--mirai-ui-space-XXL) * 2.5);
    max-width: calc(var(--mirai-ui-space-XXL) * 2.5);
    width: calc(var(--mirai-ui-space-XXL) * 2.5);
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .modal > .card.visible {
    width: auto;
  }
}
