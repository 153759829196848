.card {
  height: 100%;
  border-radius: var(--mirai-ui-border-radius);
}

.offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.card .row {
  align-items: center;
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-space-XXS);
}

.card .spaceBetween {
  align-items: center;
  justify-content: space-between;
}

.card > *:not(:nth-last-child(1)):not(:nth-last-child(3)) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

/* -- <Item.Preview> ------------------------------------------------------------------------------------------------ */
.preview .details {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  flex: 0;
  min-height: var(--mirai-ui-space-XL);
  overflow: hidden;
}

.preview {
  position: relative;
}

.preview .tags {
  gap: var(--mirai-ui-space-XS);
  left: 0;
  margin: var(--mirai-ui-space-S);
  position: absolute;
  top: 0;
}

.preview .tags > ui-text {
  align-items: center;
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  display: flex;
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

.preview.withRadius .slider {
  border-radius: var(--mirai-ui-border-radius) var(--mirai-ui-border-radius) 0 0;
}
.preview .tag3D {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-border-radius);
  color: var(--mirai-ui-base);
  padding: calc(var(--mirai-ui-space-XXS) / 2) calc(var(--mirai-ui-space-XXS) / 1);
}

/* -- <Item.Rates> -------------------------------------------------------------------------------------------------- */
.card > *:nth-last-child(2) {
  flex: 1;
}

.card .optionText {
  margin-right: calc(var(--mirai-ui-space-M) + var(--mirai-ui-space-XS));
  position: relative;
}

.card .optionText ui-pressable {
  margin-left: var(--mirai-ui-space-XXS);
  position: absolute;
}

.card .optionText > .tags {
  display: inline-flex;
  gap: calc(var(--mirai-ui-space-XXS) / 2);
}

.card .optionText .tag {
  display: inline-flex;
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  color: var(--mirai-ui-success);
  border-radius: var(--mirai-ui-space-XXS);
  margin-right: var(--mirai-ui-space-XXS);
  width: fit-content;
}

.card .optionText .tag.highlight {
  display: flex;
  background-color: var(--mirai-ui-accent);
  color: var(--mirai-ui-base);
  padding: 0 var(--mirai-ui-space-XXS);
  margin-top: var(--mirai-ui-space-XXS);
}

.card .optionText .tag > ui-icon {
  position: relative;
  top: calc(var(--mirai-ui-space-XXS) / 3);
}

.card .optionText.standalone {
  margin-left: calc(var(--mirai-ui-space-XXS) / 4);
}

.card .inlineOptions {
  align-items: stretch;
}

.card .gap,
.preview .gap {
  gap: var(--mirai-ui-space-XS);
}

/* -- <Item.Summary> ------------------------------------------------------------------------------------------------ */
.card .summary {
  margin-bottom: var(--mirai-ui-space-XXS);
}

.summary .content {
  justify-content: space-between;
  align-items: flex-end;
}

.summary .content.end {
  justify-content: flex-end;
}

.summary .price {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
  margin-top: var(--mirai-ui-space-XXS);
}

.summary .lineThrough {
  text-decoration: line-through;
}

.summary .priceDetails {
  align-self: flex-start;
  margin-top: var(--mirai-ui-space-XXS);
}

.summary .button {
  align-items: flex-end;
}

.summary .tags {
  flex-wrap: wrap;
  gap: var(--mirai-ui-space-XXS);
}

.summary .tag {
  display: inline-flex;
  gap: calc(var(--mirai-ui-space-XXS) / 2);
  background-color: var(--mirai-ui-accent);
  color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-space-XXS);
  padding: 0 var(--mirai-ui-space-XXS);
  margin-right: var(--mirai-ui-space-XXS);
  width: fit-content;
}

.tag .truncate {
  width: 12ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag > ui-text {
  color: var(--mirai-ui-base);
}

/* S */
@media only screen and (max-width: 480px) {
  .offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-S);
  }

  .tag .truncate {
    width: 8ch;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .offset:nth-last-child(2) {
    padding-bottom: var(--mirai-ui-space-M);
  }

  .summary .pricePer {
    margin-top: calc(var(--mirai-ui-space-XXS) * -0.5);
  }
}
