.container {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
  flex: 1;
  position: relative;
}

.border {
  border: var(--mirai-ui-input-text-border);
  border-radius: var(--mirai-ui-input-text-radius);
  padding-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  transition: box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.border.error {
  border-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

.border.focus:not(.error) {
  border-color: var(--mirai-ui-input-text-color-active);
  box-shadow: var(--mirai-ui-focus-shadow);
}

.border.focus.error {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

.content {
  min-height: var(--mirai-ui-input-min-height);
}

.content > *:not(.label):not(.icon) {
  padding: calc(var(--mirai-ui-input-text-padding-y) + var(--mirai-ui-space-S))
    calc(var(--mirai-ui-input-text-padding-x) / 2) calc(var(--mirai-ui-input-text-padding-x) / 2)
    calc(var(--mirai-ui-input-text-padding-x) / 2);
}

.hint {
  color: var(--mirai-ui-content-light);
  margin: calc(var(--mirai-ui-input-text-padding-y) / 4) var(--mirai-ui-input-text-padding-x);
}

.icon {
  font-size: var(--mirai-ui-input-text-icon-size);
  height: var(--mirai-ui-input-text-icon-size);
  line-height: var(--mirai-ui-input-text-icon-size);
  margin: 0;
  padding: 0;
  position: absolute;
  right: var(--mirai-ui-input-text-padding-x);
  top: calc(calc(var(--mirai-ui-input-min-height) - var(--mirai-ui-input-text-icon-size)) / 2);
  width: var(--mirai-ui-input-text-icon-size);
}

.icon.error {
  color: var(--mirai-ui-error);
}

.icon.success {
  color: var(--mirai-ui-success);
}

.withLabel {
  padding-top: calc(var(--mirai-ui-input-text-padding-y) + var(--mirai-ui-space-S));
}

.focus .label {
  color: var(--mirai-ui-input-text-color-active);
}

.label {
  color: var(--mirai-ui-input-color-disabled);
  background-color: var(--mirai-ui-input-background);
  font-size: var(--mirai-ui-input-font-size);
  left: var(--mirai-ui-input-text-padding-x);
  line-height: calc(var(--mirai-ui-input-min-height) - var(--mirai-ui-border-width));
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  text-overflow: ellipsis;
  top: var(--mirai-ui-border-width);
  transition: font-size var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    line-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    right var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    top var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  white-space: nowrap;
}

.label.withValue {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
  top: calc(var(--mirai-ui-space-XXS) + var(--mirai-ui-border-width));
}

.label .defaultRequired {
  margin: 0 var(--mirai-ui-space-XXS) 0 var(--mirai-ui-space-XXS);
}

.text.required {
  border-radius: var(--mirai-ui-border-radius);
  bottom: calc(var(--mirai-ui-space-XXS) / 4);
  margin-left: var(--mirai-ui-space-XXS);
  padding: calc(var(--mirai-ui-space-XXS) / 4) calc(var(--mirai-ui-space-XXS) / 2);
  position: relative;
  text-align: center;
}
