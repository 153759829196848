.container {
  width: fit-content;
}

.layer {
  z-index: var(--mirai-ui-layer-content);
}

.layer:not(.fixed) {
  position: absolute;
}

.layer.fixed {
  position: fixed;
}

.layer.hidden {
  display: none;
  visibility: hidden;
}
