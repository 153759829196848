.form {
  margin: var(--mirai-ui-space-M);
}

.description {
  margin-bottom: var(--mirai-ui-space-M);
}

.notification,
.button {
  margin-top: var(--mirai-ui-space-M);
}
