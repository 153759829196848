.container {
}

.container > iframe {
  border: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
}

.container.ready > iframe {
  height: 460px;
}

.button {
  display: none;
}
