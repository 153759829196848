.tooltip {
  max-width: calc(var(--mirai-ui-breakpoint-S) * 0.8);
  gap: var(--mirai-ui-space-XS);
}

.tooltip .row {
  align-items: center;
  gap: var(--mirai-ui-space-M);
}

.tooltip ui-text {
  color: var(--mirai-ui-base);
}

.tooltip ui-text:first-of-type {
  flex: 1;
}

/* S */
@media only screen and (max-width: 480px) {
}

/* M & L */
@media only screen and (min-width: 481px) {
}
