.inputContainer {
  flex: 1;
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
  position: relative;
}

.inputBorder {
  border: var(--mirai-ui-input-text-border);
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-base);
  padding-left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  padding-right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  transition: box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.inputBorder.disabled {
  border-color: var(--mirai-ui-input-text-color-disabled);
}

.inputBorder.error {
  border-color: var(--mirai-ui-error);
  box-shadow: var(--mirai-ui-input-shadow-active-warning);
}

.inputBorder.focus:not(.error):not(.warning):not(.success) {
  border-color: var(--mirai-ui-input-color-active);
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.inputBorder.focus.error {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

/* .inputBorder.focus.success {
  border-color: var(--mirai-ui-success-dark);
  box-shadow: var(--mirai-ui-input-shadow-active-success);
} */

.content {
  justify-content: center;
  min-height: var(--mirai-ui-input-min-height);
  position: relative;
}

.icon {
  font-size: var(--mirai-ui-input-text-icon-size);
  height: var(--mirai-ui-input-text-icon-size);
  line-height: var(--mirai-ui-input-text-icon-size);
  width: var(--mirai-ui-input-text-icon-size);
  margin: 0 calc(var(--mirai-ui-input-text-padding-x) / 3);
}

.icon.left {
  color: var(--mirai-ui-input-color-disabled);
  pointer-events: none;
}

.icon.expand {
  pointer-events: none;
  z-index: var(--mirai-ui-layer-S);
}

.icon.expand:not(.reverse) {
  margin-left: calc(var(--mirai-ui-input-text-icon-size) * -1);
}

.icon.expand.reverse {
  margin-right: calc(var(--mirai-ui-input-text-icon-size) * -1);
}

.icon.expand,
.icon.password {
  color: var(--mirai-ui-content);
}

.input:not(.multiLine) {
  padding: var(--mirai-ui-input-text-padding-y) calc(var(--mirai-ui-input-text-padding-x) / 2);
}

.input.multiLine {
  margin: var(--mirai-ui-input-text-padding-y) 0;
  padding: 0 calc(var(--mirai-ui-input-text-padding-x) / 2);
}

.input.withLabel:not(.multiLine) {
  padding-top: calc(var(--mirai-ui-input-text-padding-y) + var(--mirai-ui-space-S));
}

.input.withLabel.multiLine {
  margin-top: calc(var(--mirai-ui-input-text-padding-y) + var(--mirai-ui-space-M));
}

.input.empty:not(:focus) {
  color: var(--mirai-ui-base);
}

.hint {
  margin: calc(var(--mirai-ui-input-text-padding-y) / 4) var(--mirai-ui-input-text-padding-x);
}

.label {
  border-radius: var(--mirai-ui-input-text-radius);
  background-color: var(--mirai-ui-input-background);
  font-size: var(--mirai-ui-input-font-size);
  left: calc(var(--mirai-ui-input-text-padding-x) / 2);
  line-height: calc(var(--mirai-ui-input-min-height) - var(--mirai-ui-border-width));
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: calc(var(--mirai-ui-input-text-padding-x) / 2);
  text-overflow: ellipsis;
  top: var(--mirai-ui-border-width);
  transition: font-size var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    line-height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    right var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    top var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  white-space: nowrap;
}

.label.withValue {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
  top: calc(var(--mirai-ui-space-XXS) + var(--mirai-ui-border-width));
}

.text {
  color: var(--mirai-ui-input-color-disabled);
}

.text.disabled,
.icon.disabled {
  color: var(--mirai-ui-input-text-color-disabled);
}

.text.error {
  color: var(--mirai-ui-error);
}

.text.focus:not(.error) {
  color: var(--mirai-ui-input-text-color-active);
}

.text .required {
  /* border-radius: var(--mirai-ui-border-radius); */
  /* bottom: calc(var(--mirai-ui-space-XXS) / 4); */
  margin-left: var(--mirai-ui-space-XXS);
  /* position: relative; */
  /* text-align: center; */
}

/* .text .required:not(.square) {
  padding: calc(var(--mirai-ui-space-XXS) / 4) calc(var(--mirai-ui-space-XXS) / 2);
}

.text .required.square {
  height: var(--mirai-ui-space-S);
  width: var(--mirai-ui-space-S);
  line-height: var(--mirai-ui-space-S);
} */

.text .required.error {
  /* background-color: var(--mirai-ui-error-background); */
  color: var(--mirai-ui-error);
}

/* .text .required.success {
  background-color: var(--mirai-ui-success-background);
  color: var(--mirai-ui-success);
} */

.caption {
  margin: 0 calc(var(--mirai-ui-input-text-padding-x) / 3);
}

.icon.error {
  color: var(--mirai-ui-error);
}

.icon.success {
  color: var(--mirai-ui-success);
}

.icon.warning {
  color: var(--mirai-ui-warning);
}

/* -- reset defaults -------------------------------------------------------- */
input.input,
textarea.input {
  border: none;
  margin: 0;
  padding: var(--mirai-ui-input-text-padding-y) calc(var(--mirai-ui-input-text-padding-x) / 2);
}
