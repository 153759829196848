.section {
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  width: 100%;
}

.section:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.box {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-border-radius);
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-XS);
}

.beds {
  gap: var(--mirai-ui-space-M);
}

.bedDisposition {
  gap: var(--mirai-ui-space-XS);
}

.slider {
  min-height: 400px;
  min-width: 738px;
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    max-height: var(--mirai-modal-max-height);
  }

  .scrollView {
    width: 100%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .modal {
    max-height: 90svh;
    max-width: 738px;
  }
}
