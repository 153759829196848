.container {
  gap: var(--mirai-ui-space-XXS);
  width: auto;
}

.pressableState {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.66);
  border-radius: var(--mirai-ui-border-radius);
  display: flex;
  flex-direction: row;
  gap: var(--mirai-ui-space-XS);
  height: calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-S));
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.pressableState * {
  color: var(--mirai-ui-base);
}

.state {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: var(--mirai-ui-border-radius);
  bottom: var(--mirai-ui-space-M);
  top: var(--mirai-ui-space-M);
  left: var(--mirai-ui-space-M);
  overflow: scroll;
  position: fixed;
  right: var(--mirai-ui-space-M);
  z-index: var(--mirai-ui-layer-XL);
}

.state * {
  color: var(--mirai-ui-base);
}

.state .header {
  align-items: 'center';
  background-color: rgba(0, 0, 0, 0.66);
  gap: var(--mirai-ui-space-XS);
  justify-content: space-between;
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  position: sticky;
  top: 0;
}

.state .header .name {
  align-items: 'center';
  gap: var(--mirai-ui-space-XS);
}

.state .code {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  overflow-y: scroll;
  padding: var(--mirai-ui-space-S);
  width: 100%;
}
