.list {
  gap: var(--mirai-chat-space);
  width: 100%;
}

.item {
  border: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  border-radius: var(--mirai-chat-border-radius);
  display: flex;
  flex-direction: row;
}

.image {
  background-color: var(--mirai-ui-content-background);
  background-position: center;
  background-size: cover;
  border-top-left-radius: var(--mirai-chat-border-radius);
  border-bottom-left-radius: var(--mirai-chat-border-radius);
  height: calc(var(--mirai-ui-space-XL) * 3);
  min-height: calc(var(--mirai-ui-space-XL) * 3);
  min-width: calc(var(--mirai-ui-space-XL) * 3);
  width: calc(var(--mirai-ui-space-XL) * 3);
}

.content {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  justify-content: space-between;
}

.price {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
}

.lineThrough {
  text-decoration: line-through;
}

/* S */
@media only screen and (max-width: 480px) {
  .item {
    flex-basis: 100%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .list {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .item {
    flex-basis: calc(50% - calc(calc(var(--mirai-chat-space) / 2) + calc(var(--mirai-chat-border-size) * 2)));
    flex-grow: 1;
    max-width: calc(
      calc(var(--mirai-chat-content-max-width) / 2) -
        calc(calc(var(--mirai-chat-space) / 2) + calc(var(--mirai-chat-border-size) * 2))
    );
  }

  .item:hover {
    background-color: var(--mirai-ui-content);
    cursor: pointer;
  }

  .item:hover ui-text {
    color: var(--mirai-ui-base);
  }
}
