.content {
  gap: var(--mirai-ui-space-L);
  margin: var(--mirai-ui-space-M);
}

.section {
  gap: var(--mirai-ui-space-XXS);
}

/* M & L */
@media only screen and (min-width: 481px) {
  .modal {
    max-height: 90svh;
    max-width: calc(var(--mirai-ui-breakpoint-content) / 2);
  }
}
