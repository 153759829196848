.container {
  gap: var(--mirai-ui-space-M);
}

.title {
  align-items: center;
  justify-content: space-between;
}

.notification {
  align-self: center;
}

.card .spaceBetween {
  justify-content: space-between;
}

.card .image {
  background-color: var(--mirai-ui-content-border);
  background-position: center;
  background-size: cover;
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  height: var(--mirai-card-image-height);
}

/* Bookings.Search.jsx */
.search .form {
  width: 100%;
}

.search .input {
  margin-top: 0;
}

.search .button {
  margin-bottom: var(--mirai-ui-space-M);
}

.actionSearch,
span:has(.actionSearch) {
  align-self: flex-end;
}

/* S */
@media only screen and (max-width: 480px) {
  .card .offset {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .card .offset {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .search {
    max-width: 320px;
  }
}
