.bookingSummary.box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
}

.bookingSummary .section {
  gap: var(--mirai-ui-space-S);
  padding: var(--mirai-ui-space-M);
}

.bookingSummary .section:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.bookingSummary .dates {
  align-items: flex-start;
}

.bookingSummary .dates > * {
  flex: 1;
}

.bookingSummary .info {
  gap: var(--mirai-ui-space-XS);
}

.bookingSummary .notification > * {
  text-align: justify;
}

.bookingSummary .links {
  justify-content: space-between;
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .bookingSummary .dates {
    gap: var(--mirai-ui-space-XS);
  }

  .bookingSummary .details {
    padding: var(--mirai-ui-space-M) var(--mirai-ui-space-M) 0 var(--mirai-ui-space-M);
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .bookingSummary .dates {
    gap: var(--mirai-ui-space-S);
  }
}

@media print {
  .noPrint {
    display: none !important;
  }

  .bookingSummary .section {
    margin-bottom: var(--mirai-ui-space-M);
    padding: 0;
  }

  .bookingSummary .section:not(:last-child) {
    border-bottom: none;
  }

  .bookingSummary h2,
  .bookingSummary h3 {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  .bookingSummary span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }
}
