.slider {
  align-items: center;
  background-color: var(--mirai-ui-content-border);
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button {
  align-items: center;
  background-color: var(--mirai-ui-slider-button-background);
  border-radius: 50%;
  box-shadow: var(--mirai-ui-modal-shadow);
  display: flex;
  height: var(--mirai-ui-button-small-height);
  justify-content: center;
  position: absolute;
  top: calc(50% - calc(var(--mirai-ui-button-small-height) / 2));
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: var(--mirai-ui-button-small-height);
  z-index: var(--mirai-ui-layer-M);
}

.button.hide {
  transform: scale(0);
}

.button .icon {
  color: var(--mirai-ui-slider-icon-color);
}

.button.first {
  left: var(--mirai-ui-space-S);
}

.button.second {
  right: var(--mirai-ui-space-S);
}

.item {
  position: relative;
}

.item .image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.item .image.portrait {
  background-size: contain;
}

.item .caption {
  align-items: center;
  bottom: var(--mirai-ui-space-S);
  left: var(--mirai-ui-space-S);
  position: absolute;
  right: var(--mirai-ui-space-S);
}

.item .caption .text {
  color: var(--mirai-ui-slider-overlay-color);
}

.counter {
  align-items: center;
  bottom: var(--mirai-ui-space-S);
  display: flex;
  flex-direction: row;
  gap: var(--mirai-ui-space-XXS);
  position: absolute;
}

.counter:not(.reverse) {
  right: var(--mirai-ui-space-S);
}

.counter.reverse {
  left: var(--mirai-ui-space-S);
}

.counter * {
  color: var(--mirai-ui-slider-color);
}

.overlay {
  background-color: var(--mirai-ui-slider-overlay);
  border-radius: var(--mirai-ui-slider-border-radius);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
}

.overlay * {
  color: var(--mirai-ui-slider-overlay-color);
}

.progress {
  background-color: var(--mirai-ui-slider-overlay);
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  right: 0;
}

.progress.visible {
  height: calc(var(--mirai-ui-space-XS) / 1);
}

/* S */
@media only screen and (max-width: 480px) {
}

/* M & L */
@media only screen and (min-width: 481px) {
  .item .caption {
    transition: opacity var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
    opacity: 0;
  }

  .item:hover .caption {
    opacity: 1;
  }
}
