.container {
  gap: var(--mirai-chat-space);
  max-width: var(--mirai-chat-content-max-width);
}

.title.hi {
  animation: textGradient 1.5s linear infinite alternate;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--mirai-chat-avatar-color-2),
    var(--mirai-chat-avatar-color-3),
    var(--mirai-chat-avatar-color-2)
  );
  background-size: 200% 100%;
  color: transparent;
  -webkit-background-clip: text;
}

.content {
  position: relative;
}

.actions,
.prompts {
  position: absolute;
  transition: all var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
}

.actions {
  flex-wrap: wrap;
  gap: var(--mirai-chat-space);
}

.actions:not(.visible),
.prompts:not(.visible) {
  pointer-events: none;
}

.actions:not(.visible) {
  opacity: 0;
  transform: translateY(-16px);
}

.prompts {
  flex-direction: column;
  gap: calc(var(--mirai-chat-space) / 2);
}

.prompts:not(.visible) {
  opacity: 0;
  transform: translateY(16px);
}

.option {
  display: flex;
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  flex: 1;
}

.actions .option {
  flex-direction: column;
  gap: var(--mirai-chat-space);
  height: calc(var(--mirai-ui-space-XL) * 2);
  padding: var(--mirai-chat-space);
}

.prompts .option {
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
  gap: calc(var(--mirai-chat-space) / 2);
  padding: calc(var(--mirai-chat-space) / 2) var(--mirai-chat-space);
  width: 100%;
}

.option:hover,
.option:active {
  background-color: var(--mirai-ui-content);
  border-color: var(--mirai-ui-content);
}

.option:hover *,
.option:active * {
  color: var(--mirai-chat-background);
}

/* S */
@media only screen and (max-width: 480px) {
  .container {
    padding: var(--mirai-chat-space);
  }

  .content {
    min-height: calc(var(--mirai-ui-space-XL) * 6);
  }

  .actions .option {
    min-width: 25%;
  }

  .input {
    bottom: calc(var(--mirai-chat-space) * 2);
    left: var(--mirai-chat-space);
    position: absolute;
    right: var(--mirai-chat-space);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .title {
    font-size: calc(var(--mirai-ui-font-size-headline-1) * 1.5);
    line-height: calc(var(--mirai-ui-font-size-headline-1) * 1.75);
  }
}

@keyframes textGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
