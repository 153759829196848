.container {
  width: 100%;
}

.container.outlined {
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  border-radius: var(--mirai-ui-table-border-radius);
}

.table {
  background-color: var(--mirai-ui-table-background);
  border-collapse: collapse;
  color: var(--mirai-ui-table-color);
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  table-layout: auto;
  text-align: left;
  width: 100%;
}

.table thead {
  background-color: var(--mirai-ui-table-background);
  box-shadow: 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  position: sticky;
  top: 0;
  z-index: var(--mirai-ui-layer-L);
}

.table thead tr {
  font-family: var(--mirai-ui-font-bold);
  font-size: var(--mirai-ui-font-size-small);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.table tbody {
  font-size: var(--mirai-ui-font-size-action);
}

.table tbody tr {
  scroll-snap-align: start;
}

.table tbody tr.selected {
  background-color: var(--mirai-ui-table-selected-background);
  color: var(--mirai-ui-table-selected-color);
}

.table tbody tr.selected td:first-child {
  background-color: var(--mirai-ui-table-selected-background);
}

.table tbody tr:not(:last-child) {
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
}

.table th {
  user-select: none;
}

.table th,
.table td {
  padding: var(--mirai-ui-table-padding-y) var(--mirai-ui-table-padding-x) var(--mirai-ui-table-padding-y) 0;
}

.table th:first-child,
.table td:first-child {
  background-color: var(--mirai-ui-table-background);
  left: 0;
  padding-left: var(--mirai-ui-table-padding-x);
  position: sticky;
  z-index: var(--mirai-ui-layer-M);
}

.table th:last-child,
.table td:last-child {
  padding-right: var(--mirai-ui-table-padding-x);
}

.table th .icons {
  gap: var(--mirai-ui-space-XXS);
  margin-left: auto;
}

.table th .icon.disabled {
  color: var(--mirai-ui-table-color-disabled);
}

.table th .column,
.table .column.selectable {
  gap: calc(var(--mirai-ui-table-padding-x) / 2);
}

.table th .tooltip {
  position: relative;
}

.table .column.text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .column.number {
  float: right;
  width: fit-content;
}

.table .column.component {
  z-index: var(--mirai-ui-layer-S);
}

.table .checkbox {
  margin: 0;
}

/* <ColumnFilter> */
.table .menuFilter .items {
  gap: var(--mirai-ui-space-M);
}

.table .menuFilter .input {
  margin-bottom: 0;
}

/*  */
.filters {
  background-color: var(--mirai-ui-table-background);
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-table-border-color);
  flex-wrap: wrap;
  gap: calc(var(--mirai-ui-table-padding-y) / 2);
  left: 0;
  padding: calc(var(--mirai-ui-table-padding-y) / 2) var(--mirai-ui-table-padding-x);
  position: sticky;
  width: 100%;
}

.filters .item {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-XXS);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XXS) var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  user-select: none;
}

.filters .item .buttonRemove {
  align-items: center;
  border-radius: var(--mirai-ui-border-radius);
  display: flex;
  height: var(--mirai-ui-space-L);
  justify-content: center;
  width: var(--mirai-ui-space-L);
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .table .checkbox {
    max-width: var(--mirai-ui-checkbox-size-mobile);
    min-width: var(--mirai-ui-checkbox-size-mobile);
  }
}

/* Table & Desktop */
@media only screen and (min-width: 481px) {
  .table .checkbox {
    max-width: var(--mirai-ui-checkbox-size);
    min-width: var(--mirai-ui-checkbox-size);
  }

  .table thead tr th:hover {
    color: var(--mirai-ui-table-color);
    cursor: pointer;
  }

  .table tbody tr:hover.onPress {
    background-color: var(--mirai-ui-table-selected-background);
    cursor: pointer;
  }

  .table tbody tr:hover.onPress:not(.selected) td:first-child {
    background-color: var(--mirai-ui-table-selected-background);
  }

  .table .menuFilter {
    margin: var(--mirai-ui-space-M) 0;
    width: calc(var(--mirai-ui-breakpoint-S) * 0.8);
  }

  .filters .item .buttonRemove:hover {
    background-color: var(--mirai-ui-content-background);
  }
}

/* Safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .table thead {
    border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-table-border-color);
  }
}
