.currency {
  white-space: nowrap;
}

.priceBreakdown {
  gap: var(--mirai-ui-space-M);
}

.lines > *:not(.notification) {
  align-items: flex-start;
  gap: var(--mirai-ui-space-M);
}

.lines .amount {
  white-space: nowrap;
}

.lines > *:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-XS);
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .priceBreakdown {
    gap: var(--mirai-ui-space-XS);
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .priceBreakdown {
    gap: var(--mirai-ui-space-M);
  }
}
