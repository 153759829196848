.cards {
  gap: var(--mirai-ui-space-M);
}

.cards:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-M);
}

.card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    filter var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.card.disabled {
  filter: grayscale(1);
}

.card.focus {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color), var(--mirai-ui-focus-shadow);
}

.card > *:nth-last-child(2) {
  flex: 1;
}

.card > *:not(:last-child) {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

/* S */
@media only screen and (max-width: 480px) {
  .cards {
    flex-direction: column;
  }

  .card {
    width: 100%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .cards {
    align-items: stretch;
    flex-wrap: wrap;
  }
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .card {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .card:not(.columnMode) {
    width: 1%;
    flex-basis: calc(33.33% - calc(calc(var(--mirai-ui-space-M) * 2) / 3)) !important;
  }

  .card.columnMode {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }
}
