.typewriter {
  display: inline-block;
  position: relative;
}

.scaffold {
  display: block;
  visibility: hidden;
  white-space: pre-wrap;
}

.animated {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre-wrap;
}
