.container {
  align-items: flex-end;
  background-color: var(--mirai-chat-input-background);
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-input-background);
  border-radius: var(--mirai-chat-border-radius);
  gap: calc(var(--mirai-chat-space) / 2);
  justify-content: center;
  padding: calc(var(--mirai-chat-space) / 1);
  z-index: 0;
  transition: border var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.container.focus {
  border-color: var(--mirai-chat-border-color);
}

.input {
  align-self: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  line-height: var(--mirai-chat-text-line-height);
  margin: 0;
  outline: none;
  text-align: var(--mirai-ui-text-align);
  user-select: none;
  width: 100%;
  /* autoresize */
  min-height: auto;
  overflow-y: hidden;
  resize: none;
}

.input:disabled,
.input::placeholder {
  color: var(--mirai-ui-input-color-disabled);
  opacity: 0.66;
}

.button {
  border-radius: 50%;
}

.context {
  flex-wrap: wrap;
  gap: 0 calc(var(--mirai-chat-space) / 2);
}

.context .field {
  background-color: var(--mirai-chat-background);
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-radius: calc(var(--mirai-chat-space) * 2);
  padding: calc(var(--mirai-chat-space) / 2) calc(var(--mirai-chat-space) / 1);
}

.context .field .icon {
  margin-right: calc(var(--mirai-chat-space) / 2);
}

@media screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  .input {
    font-size: var(--mirai-ui-input-font-size);
  }
}
