.calendar {
  flex: 1;
}

.footer {
  border-top: solid 1px var(--mirai-ui-content-border);
  justify-content: space-between;
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.hint {
  color: var(--mirai-ui-content-light);
}

.notification {
  align-self: center;
  margin: var(--mirai-ui-space-S);
  max-width: calc(var(--mirai-ui-calendar-cell) * 14);
}

.offset {
  padding: var(--mirai-finder-calendar-padding);
}

/* S */
@media only screen and (max-width: 480px) {
  .container {
    max-height: calc(var(--mirai-modal-max-height) - var(--mirai-ui-space-XXL));
  }

  .calendar {
    flex: 1;
    max-height: calc(
      var(--mirai-modal-max-height) - calc(var(--mirai-ui-space-XXL) * 3) - var(--mirai-ui-space-XXS)
    ) !important;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    min-height: calc(var(--mirai-ui-calendar-cell) * 8);
  }

  .container.top {
    min-height: calc(calc(var(--mirai-ui-calendar-cell) * 8) + calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-S)));
  }
}
