.option {
  align-items: flex-start;
  display: flex;
  gap: var(--mirai-ui-space-XS);
  flex-direction: var(--mirai-ui-row-direction);
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.option:not(.outlined) {
  width: 100%;
}

.option.outlined {
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  flex: 1;
  padding: var(--mirai-ui-space-M) var(--mirai-ui-space-S);
}

.option.outlined:not(.inline) {
  width: auto;
}

.option.outlined:not(.disabled):not(.checked):hover {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-accent-dark);
}

.option.outlined.checked {
  box-shadow: 0 0 0 calc(var(--mirai-ui-border-width) * 2) var(--mirai-ui-accent);
  z-index: 0;
}

.option.inline {
  align-items: center;
  flex-direction: column;
  gap: 0;
  height: auto;
}

.option.inline .texts,
.option.inline .texts * {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.option.inline:first-of-type {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.option.inline:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.option.inline:last-of-type {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.option.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}

.option .texts {
  flex: 1;
}

.option .texts.highlight .caption {
  padding: 0 var(--mirai-ui-space-XXS);
}

.option .content {
  gap: var(--mirai-ui-space-XXS);
}

.option.outlined:not(.inline) .title {
  height: var(--mirai-ui-line-height-action);
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.option .texts.highlight .title {
  color: var(--mirai-ui-success);
  background-color: var(--mirai-ui-success-background);
  border-radius: var(--mirai-ui-space-XXS);
  padding: 0 var(--mirai-ui-space-XXS);
}

/* S */
@media only screen and (max-width: 480px) {
  .option.outlined {
    padding: var(--mirai-ui-space-S);
  }

  .option .texts {
    align-self: center;
  }

  .option.inline .texts {
    gap: calc(var(--mirai-ui-space-XXS) / 2);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .option.outlined {
    padding: var(--mirai-ui-space-M);
  }

  .option.inline .texts {
    gap: var(--mirai-ui-space-XXS);
  }
}
