.text {
  color: var(--mirai-booking-query-color);
}

.text:hover {
  color: var(--mirai-booking-query-color-active);
}

.button {
  margin-bottom: var(--mirai-ui-space-L);
  margin-top: var(--mirai-ui-space-L);
}

.description {
  margin-bottom: var(--mirai-ui-space-S);
  width: 100%;
}

.form {
  padding: var(--mirai-ui-space-M) var(--mirai-ui-space-L);
}

.wide {
  width: 100%;
}
