.box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

.details {
  gap: var(--mirai-ui-space-M);
  padding-bottom: 0;
}

.details .inline {
  align-items: start;
  color: var(--mirai-ui-content-light);
  gap: var(--mirai-ui-space-XS);
}

.details .item {
  gap: var(--mirai-ui-space-S);
  margin-bottom: var(--mirai-ui-space-XS);
}

.details .item .image {
  background-color: var(--mirai-ui-content-background);
  background-size: cover;
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 0 1px var(--mirai-ui-content-background);
}

.details .buttonMore {
  border-top: solid 1px var(--mirai-ui-content-border);
  margin: calc(var(--mirai-ui-space-M) * -1);
  padding: var(--mirai-ui-space-XXS) 0;
  width: auto;
}

.details .observations {
  gap: var(--mirai-ui-space-XXS);
}

/* S */
@media only screen and (max-width: 480px) {
  .details .item .image {
    height: var(--mirai-ui-space-XL);
    min-height: var(--mirai-ui-space-XL);
    min-width: var(--mirai-ui-space-XL);
    width: var(--mirai-ui-space-XL);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .details .item .image {
    height: var(--mirai-ui-space-XXL);
    min-height: var(--mirai-ui-space-XXL);
    min-width: var(--mirai-ui-space-XXL);
    width: var(--mirai-ui-space-XXL);
  }
}
