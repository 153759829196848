.iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.container {
  height: 100%;
  width: 100%;
}

.panel {
  border-right: 1px solid var(--mirai-ui-content-border);
  box-sizing: border-box;
  background-color: var(--mirai-ui-content-background);
  height: 100%;
  padding: var(--mirai-ui-space-M);
}

.scrollview {
  max-height: 90svh;
  gap: var(--mirai-ui-space-M);
}

.filters {
  position: absolute;
  top: calc(var(--mirai-ui-space-XXL) + calc(var(--mirai-ui-space-M) / 2));
  right: var(--mirai-ui-space-XS);
  z-index: var(--mirai-ui-layer-M);
}

.collapse {
  align-items: center;
  background-color: var(--mirai-ui-base);
  border-radius: 50%;
  display: flex;
  height: var(--mirai-ui-button-small-height);
  justify-content: center;
  position: absolute;
  top: var(--mirai-ui-space-S);
  left: var(--mirai-ui-space-S);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: var(--mirai-ui-button-small-height);
  z-index: 1;
}

.slider {
  background-color: transparent;
  width: 100svw !important;
  position: absolute;
  left: 0px;
  bottom: var(--mirai-ui-space-M);
}

.slider > * {
  width: 100svw !important;
  overflow-y: clip;
}

.slider .item {
  margin-left: var(--mirai-ui-space-M);
  scroll-snap-align: center;
}

.slider.reduced {
  height: 120px;
}

.slider .expanded {
  transform: translateY(150%);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  bottom: var(--mirai-ui-space-M);
}

.slider .expanded:not(.visible) {
  transform: translateY(150%);
}

.slider .expanded.visible {
  transform: translateY(0);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.slider .reduced {
  transform: translateY(150%);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.slider .reduced:not(.visible) {
  transform: translateY(150%);
  opacity: 0;
  position: absolute;
}

.slider .reduced.visible {
  transform: translateY(0%);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  opacity: 1;
}

.card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  max-width: 320px;
  position: relative;
}

.card.last {
  margin-right: var(--mirai-ui-space-M);
}

.card.disabled {
  filter: grayscale(1);
}

.card .preview {
  justify-content: space-between;
}

.card .disabled .footer {
  justify-content: end;
}

.image {
  background-color: var(--mirai-ui-content-border);
  background-size: cover;
  border-radius: var(--mirai-ui-border-radius) 0 0 var(--mirai-ui-border-radius);
  height: 120px;
  width: 120px;
}

.content {
  padding: var(--mirai-ui-space-S);
  justify-content: space-between;
  width: 220px;
  height: 120px;
}

.content .title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.footer {
  border-top: 1px solid var(--mirai-ui-content-border);
  padding: var(--mirai-ui-space-S);
  justify-content: space-between;
}

.footer > .hidden {
  visibility: hidden;
}

.card.disabled .footer {
  justify-content: end;
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    height: 100svh;
  }

  .card .preview {
    min-height: 420px;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .modal {
    height: 100svh;
    width: 100svw;
  }

  .filters {
    top: calc(var(--mirai-ui-space-XXL) + var(--mirai-ui-space-S));
  }
}
