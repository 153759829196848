.scrollView {
  background-color: var(--mirai-ui-content-background);
  width: initial;
}

.slider {
  margin-top: var(--mirai-ui-space-M);
  background-color: transparent;
}

.slider > ui-scrollview {
  align-items: stretch;
  gap: var(--mirai-ui-space-M);
}

.container {
  align-items: stretch;
  flex-wrap: wrap;
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
  width: 100%;
}

.card {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
  box-sizing: border-box;
  transition: box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

/* !TODO: Use <Card> */
.card.selected {
  box-shadow: 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color), var(--mirai-ui-focus-shadow);
}

.card .anchor {
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.card .gap {
  gap: var(--mirai-ui-space-XS);
}

.card .offset {
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.card > .offset:nth-last-child(2) {
  flex: 1;
}

.card .spaceBetween {
  align-items: center;
  justify-content: space-between;
}

.card .image {
  background-color: var(--mirai-ui-content-border);
  background-position: center;
  background-size: cover;
  border-top-left-radius: var(--mirai-ui-border-radius);
  border-top-right-radius: var(--mirai-ui-border-radius);
  height: 192px;
  width: 100%;
}

.card .inputs {
  align-items: flex-end;
  flex: 1;
  margin: var(--mirai-ui-space-XXS) 0;
}

.card .inputs > * {
  margin-bottom: 0;
}

.slider .card.selected {
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-focus-color);
}

.slider .card .image {
  margin: var(--mirai-ui-border-width);
  width: auto;
}

.footer {
  box-shadow: inset 0 1px 0 var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-S);
  justify-content: flex-end;
  padding: var(--mirai-ui-space-M);
}

.footer .total {
  flex: 1;
}

.priceDetails {
  margin-top: var(--mirai-ui-space-XXS);
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    max-height: 100svh;
  }

  .card .gap {
    gap: var(--mirai-ui-space-XXS);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .modal {
    max-height: 90svh;
    max-width: calc(calc(var(--mirai-ui-breakpoint-content) / 2) + calc(var(--mirai-ui-space-XXL) * 3));
  }

  .card {
    flex-basis: calc(50% - calc(var(--mirai-ui-space-M) / 2));
  }

  .card.standalone {
    flex-basis: 100%;
    max-width: calc(var(--mirai-ui-breakpoint-S) * 0.9);
  }

  .card .gap {
    gap: var(--mirai-ui-space-XS);
  }
}
