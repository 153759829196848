.radio {
  position: relative;
}

.radio input {
  background-color: var(--mirai-ui-radio-background);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-border-color);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  user-select: none;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.radio .checkmark {
  border-radius: 50%;
  margin: calc(var(--mirai-ui-radio-size) / 4);
  height: calc(var(--mirai-ui-radio-size) / 2);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: calc(var(--mirai-ui-radio-size) / 2);
}

.radio input:disabled {
  border-color: var(--mirai-ui-radio-background-disabled);
}

.radio input:checked:not(:disabled) {
  background-color: var(--mirai-ui-radio-background-checked);
  border-color: var(--mirai-ui-radio-background-checked);
}

.radio input:checked:disabled ~ .checkmark {
  background-color: var(--mirai-ui-radio-background-disabled);
}

.radio input:checked:not(:disabled) ~ .checkmark {
  background-color: var(--mirai-ui-radio-background);
}

.radio input:not(:disabled).error {
  border-color: var(--mirai-ui-error);
}

.radio input:not(:disabled):not(.error):not(:checked):focus,
.radio input:not(:disabled):not(.error):not(:checked):hover {
  border-color: var(--mirai-ui-input-color-active);
  box-shadow: var(--mirai-ui-input-shadow-active);
}

.radio input:not(:disabled).error:focus,
.radio input:not(:disabled).error:hover {
  box-shadow: var(--mirai-ui-input-shadow-active-error);
}

.radio input:not(:disabled):checked:focus,
.radio input:not(:disabled):checked:hover {
  box-shadow: var(--mirai-ui-input-shadow-active);
}

/* -- reset defaults -------------------------------------------------------- */
.radio input.input {
  margin-right: 0;
  top: 0;
}

/* S */
@media only screen and (max-width: 480px) {
  .radio {
    height: var(--mirai-ui-radio-size-mobile);
    width: var(--mirai-ui-radio-size-mobile);
  }

  .radio input {
    height: var(--mirai-ui-radio-size-mobile);
    width: var(--mirai-ui-radio-size-mobile);
  }

  .radio .checkmark {
    margin: calc(var(--mirai-ui-radio-size-mobile) / 4);
    height: calc(var(--mirai-ui-radio-size-mobile) / 2);
    width: calc(var(--mirai-ui-radio-size-mobile) / 2);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .radio {
    height: var(--mirai-ui-radio-size);
    width: var(--mirai-ui-radio-size);
  }

  .radio input {
    height: var(--mirai-ui-radio-size);
    width: var(--mirai-ui-radio-size);
  }

  .radio .checkmark {
    margin: calc(var(--mirai-ui-radio-size) / 4);
    height: calc(var(--mirai-ui-radio-size) / 2);
    width: calc(var(--mirai-ui-radio-size) / 2);
  }
}
