.selectEnvironment {
  border-radius: var(--mirai-ui-border-radius);
  cursor: pointer;
  position: relative;
}

.selectEnvironment:hover,
.selectEnvironment:active {
  background-color: var(--mirai-header-background-active);
}

.selectEnvironment > * {
  height: var(--mirai-ui-button-height);
  line-height: var(--mirai-ui-button-height);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.selectEnvironment * {
  color: var(--mirai-header-color);
}

.selectEnvironment:hover *,
.selectEnvironment:active * {
  color: var(--mirai-header-color-active);
}

.selectEnvironment.small > * {
  height: calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-S));
  line-height: calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-S));
}

.selectEnvironment .input {
  align-self: center;
  background-color: transparent;
  color: transparent;
  margin-bottom: 0;
  left: 0;
  position: absolute;
  right: 0;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selectEnvironment .caption {
  gap: var(--mirai-ui-space-XXS);
  pointer-events: none;
}

.icon.skeleton {
  color: var(--mirai-ui-content-background) !important;
}
