.container {
  overflow: hidden;
}

input.input {
  background-color: transparent;
  font-size: var(--mirai-ui-font-size-action);
  overflow: hidden;
  padding: 0;
  text-align: var(--mirai-ui-text-align);
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ! disabled because <Finder> */
/* input.input:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
} */

/* S */
@media only screen and (max-width: 480px) {
  .content {
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-S);
  }

  input.input.compacted {
    line-height: var(--mirai-ui-input-min-height);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  input.input {
    max-width: calc(var(--mirai-ui-space-XL) * 2);
  }
}

@media screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 0) {
  input.input {
    font-size: var(--mirai-ui-input-font-size);
  }
}
