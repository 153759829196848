.dispatcher {
  cursor: pointer;
}

.tooltip {
  background-color: var(--mirai-ui-tooltip-background);
  border-radius: var(--mirai-ui-tooltip-border-radius);
  margin-bottom: calc(var(--mirai-ui-tooltip-space) / 2);
  margin-top: calc(var(--mirai-ui-tooltip-space) / 2);
  max-width: var(--mirai-ui-tooltip-max-width);
  padding: var(--mirai-ui-tooltip-space);
  pointer-events: none;
}

.tooltip:before {
  border-style: solid;
  border-width: calc(var(--mirai-ui-tooltip-space) / 2);
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.tooltip:not(.align):not(.left):not(.right):before {
  left: calc(50% - calc(var(--mirai-ui-tooltip-space) / 2));
}

.tooltip.top:not(.align):before {
  border-color: var(--mirai-ui-tooltip-background) transparent transparent transparent;
  bottom: calc(calc(var(--mirai-ui-tooltip-space) / 2) * -1);
}

.tooltip.bottom:not(.align):before {
  border-color: transparent transparent var(--mirai-ui-tooltip-background) transparent;
  top: calc(calc(var(--mirai-ui-tooltip-space) / 2) * -1);
}

.tooltip.left:not(.align):before {
  left: calc(var(--mirai-ui-tooltip-space) * 2);
}

.tooltip.right:not(.align):before {
  right: calc(var(--mirai-ui-tooltip-space) * 2);
}

.tooltip.align {
  margin-bottom: 0;
  margin-top: 0;
}

.tooltip.align.left {
  position: relative;
  right: calc(var(--mirai-ui-tooltip-space) / 2);
}

.tooltip.align.right {
  margin-left: calc(var(--mirai-ui-tooltip-space) / 2);
}

.tooltip.align:before {
  top: calc(50% - calc(var(--mirai-ui-tooltip-space) / 2));
}

.tooltip.align.left:before {
  right: calc(var(--mirai-ui-tooltip-space) * -1);
  border-color: transparent transparent transparent var(--mirai-ui-tooltip-background);
}

.tooltip.align.right:before {
  left: calc((var(--mirai-ui-tooltip-space) / 2) * -1);
  border-color: transparent var(--mirai-ui-tooltip-background) transparent transparent;
}

.tooltip .text {
  color: var(--mirai-ui-tooltip-color);
}
