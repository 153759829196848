.header {
  background-color: var(--mirai-chat-background);
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--mirai-chat-space);
  position: absolute;
  transition: height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
  z-index: 1;
  top: 0;
}

.header .avatar {
  margin-right: var(--mirai-ui-space-S);
}
