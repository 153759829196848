.container {
  gap: var(--mirai-ui-space-M);
}

.inputCVV {
  margin-left: var(--mirai-ui-space-M);
}

.inputExpire {
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  outline: 0;
  width: 22px;
}

.inputName {
  border: 0;
  box-sizing: content-box;
  color: var(--mirai-ui-input-color);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  height: var(--mirai-ui-space-L) !important;
  outline: 0;
}

.button {
  width: 100%;
}
