.scrollview {
  align-items: flex-start;
  display: flex;
  height: fit-content;
  width: fit-content;
}

.scrollview.notIndicator { 
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollview.notIndicator::-webkit-scrollbar {
  display: none;
}

.scrollview.horizontal {
  direction: var(--mirai-ui-text-direction);
  flex-direction: row;
  overflow-x: scroll;
}

.scrollview.vertical {
  flex-direction: column;
  overflow-y: scroll;
}

.scrollview.snap.vertical {
  scroll-snap-type: y mandatory;
}

.scrollview.snap.horizontal {
  scroll-snap-type: x mandatory;
}

.scrollview.snap > * {
  scroll-snap-align: start;
}

.scrollview .indicator {
  align-items: center;
  background-color: var(--mirai-ui-base);
  border-radius: 50%;
  box-shadow: var(--mirai-ui-shadow);
  justify-content: center;
  overflow: hidden;
  position: sticky;
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.indicator.horizontal {
  right: var(--mirai-ui-space-M);
  top: 50%;
  transform-origin: top center;
  transform: scale(1) translateY(-50%);
}

.indicator.vertical {
  bottom: var(--mirai-ui-space-M);
  left: 50%;
  transform-origin: center left;
  transform: scale(1) translateX(-50%);
}

.indicator.horizontal.hide {
  transform: scale(0) translateY(-50%);
}

.indicator.vertical.hide {
  transform: scale(0) translateX(-50%);
}

.indicator .progress {
  background-color: var(--mirai-ui-content-border);
  height: 0%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.indicator .icon {
  z-index: var(--mirai-ui-layer-M);
}

/* S */
@media only screen and (max-width: 480px) {
  .scrollview .indicator {
    min-height: var(--mirai-ui-button-small-height);
    min-width: var(--mirai-ui-button-small-height);
  }
}

/* M& L */
@media only screen and (min-width: 481px) {
  .scrollview .indicator {
    min-height: var(--mirai-ui-button-height);
    min-width: var(--mirai-ui-button-height);
  }
}
