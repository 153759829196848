.pressable {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: fit-content;
  margin: 0;
  outline: none;
  padding: 0;
  user-select: none;
  width: fit-content;
  -webkit-tap-highlight-color: transparent;
}

.pressable:focus-visible {
  border-radius: var(--mirai-ui-border-radius);
  outline: var(--mirai-ui-focus-width) solid var(--mirai-ui-focus-color);
  outline-offset: calc(var(--mirai-ui-focus-width) * -0.5);
  z-index: var(--mirai-ui-layer-S);
}

.pressable.disabled,
.pressable:disabled {
  cursor: unset;
  outline: none;
}
