html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.container {
  align-items: center;
  background-color: var(--mirai-ui-content-background);
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  padding: var(--mirai-ui-space-M);
  width: 100vw;
}

.progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.content {
  align-items: flex-start;
  gap: var(--mirai-ui-input-text-margin-bottom);
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

.content > .main {
  row-gap: var(--mirai-ui-input-text-margin-bottom);
}

.content > * {
  flex: 1;
}

.box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: inset 0 0 1px var(--mirai-ui-content-light);
  padding: var(--mirai-ui-space-M);
}

.field {
  color: var(--mirai-ui-content-light);
  margin-bottom: calc(var(--mirai-ui-space-XXS) / 2);
}

.notificationExtras {
  gap: var(--mirai-ui-space-M);
  justify-content: space-between;
}

/* == PARTIALS ============================================================== */
/* -- <Form> ---------------------------------------------------------------- */
.form {
  column-gap: var(--mirai-ui-input-text-margin-bottom);
  display: flex;
  flex-wrap: wrap;
}

.form > * {
  margin-bottom: var(--mirai-ui-input-text-margin-bottom);
}

.form .inline {
  display: inline;
}

/* -- <Session> ---------------------------------------------------------------- */
.session .action {
  display: inline;
}

/* -- Agency --------------------------------------------------------------- */
.agency .action {
  display: inline;
}

/* -- <Modal> --------------------------------------------------------------- */
.modal .payment {
  padding: var(--mirai-ui-space-M);
}

.modal .scrollview {
  align-items: normal;
  max-height: calc(100svh - var(--mirai-ui-space-XXL) * 3.5);
  border-radius: 0 0 var(--mirai-ui-modal-border-radius) var(--mirai-ui-modal-border-radius);
}

.scrollview .others {
  border-radius: 0 0 var(--mirai-ui-modal-border-radius) var(--mirai-ui-modal-border-radius);
  box-shadow: none;
}

/* -- Footer ---------------------------------------------------------------  */
.totalPrice {
  flex: 1;
  gap: var(--mirai-ui-space-XXS);
}

/* S */
@media only screen and (max-width: 480px) {
  .notificationExtras > :first-child {
    max-width: 70%;
  }

  .cta {
    margin-top: var(--mirai-ui-space-L);
  }

  .modal {
    max-height: 100svh;
  }
}

/* S & M */
@media only screen and (max-width: 1179px) {
  .content {
    flex-direction: column;
  }

  /* -- <Form> -------------------------------------------------------------- */
  .form {
    flex-direction: column;
  }

  /* -- <Confirmation> ----------------------------------------------------------- */
  .confirmation {
    background-color: var(--mirai-ui-base);
    border-top: solid 1px var(--mirai-ui-content-border);
    bottom: 0;
    left: 0;
    padding: var(--mirai-ui-space-M);
    position: fixed;
    width: 100%;
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .modal {
    max-width: calc(var(--mirai-ui-breakpoint-M) * 0.9);
  }

  /* -- <Form> -------------------------------------------------------------- */
  .form .half {
    min-width: calc(50% - calc(var(--mirai-ui-input-text-margin-bottom) / 2));
    max-width: calc(50% - calc(var(--mirai-ui-input-text-margin-bottom) / 2));
  }

  .form .wide {
    min-width: 100%;
  }

  /* -- <Confirmation> ----------------------------------------------------------- */
  .confirmation {
    background-color: var(--mirai-ui-base);
    border-radius: var(--mirai-ui-border-radius);
    box-shadow: inset 0 0 0 1px var(--mirai-ui-content-border);
    max-width: calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 2);
    position: sticky;
    top: var(--mirai-ui-space-M);
  }

  .confirmation .section {
    padding: var(--mirai-ui-space-M);
    gap: var(--mirai-ui-space-S);
  }

  .confirmation .section:not(:last-child) {
    border-bottom: solid 1px var(--mirai-ui-content-border);
  }

  .confirmation > .section:last-child {
    padding-top: 0;
  }

  .confirmation .footer {
    text-align: center;
  }
}
