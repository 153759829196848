.header {
  border-bottom: solid 1px var(--mirai-ui-content-border) !important;
  padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-M) var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  margin-bottom: var(--mirai-ui-space-S);
}

.header .input {
  font-size: var(--mirai-ui-font-size-action);
  margin-left: var(--mirai-ui-space-XXS);
}

.footer {
  border-top: solid 1px var(--mirai-ui-content-border);
  align-items: flex-end;
}

.item {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  max-height: var(--mirai-places-item-height);
  min-height: var(--mirai-places-item-height);
  overflow: hidden;
  padding: 0 var(--mirai-places-padding);
  width: 100%;
}

.item.selected,
.item:hover:not(.head) {
  background-color: var(--mirai-places-item-background-selected);
}

.item.selected *,
.item:hover:not(.head) * {
  color: var(--mirai-places-item-color-selected);
}

.item .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scrollview {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .container {
    max-height: calc(var(--mirai-modal-max-height) - var(--mirai-ui-space-XXL));
  }

  .footer {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .scrollview {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  .container {
    max-height: calc(
      calc(var(--mirai-places-item-height) * calc(var(--mirai-places-items-per-column) + 3)) -
        var(--mirai-places-padding)
    );
    padding: calc(var(--mirai-places-padding) / 2) 0;
  }

  .container.withSubmit {
    max-height: calc(
      calc(var(--mirai-places-item-height) * calc(var(--mirai-places-items-per-column) + 5)) -
        var(--mirai-places-padding)
    );
  }

  .footer {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M) var(--mirai-ui-space-XXS);
  }

  .item {
    width: var(--mirai-places-item-width);
  }

  .columns {
    flex-wrap: wrap;
    max-width: calc(var(--mirai-places-item-width) * 3);
  }

  .columns2 {
    width: calc(var(--mirai-places-item-width) * 2);
  }

  .columns3 {
    width: calc(var(--mirai-places-item-width) * 3);
  }
}
