.slider {
  background-color: var(--mirai-ui-content-border);
  position: relative;
  width: 100%;
}

.slider .buttons {
  gap: var(--mirai-ui-space-S);
  height: 100%;
  justify-content: center;
  left: calc(var(--mirai-ui-space-XXL) * 2);
  position: absolute;
  right: calc(var(--mirai-ui-space-XXL) * 2);
}

.slider .buttons .button {
  background-color: var(--mirai-ui-slider-overlay);
  height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
}

.slider .buttons .icon {
  color: var(--mirai-ui-base);
}

.slider .tag {
  background-color: var(--mirai-ui-accent);
  border-radius: var(--mirai-ui-slider-border-radius);
  color: var(--mirai-ui-base);
  padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-XS);
  position: absolute;
  right: var(--mirai-ui-space-S);
  top: var(--mirai-ui-space-S);
}
