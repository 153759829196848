.text {
  color: var(--mirai-ui-content);
  direction: var(--mirai-ui-text-direction);
  display: inline-block;
  font-family: var(--mirai-ui-font);
  font-weight: var(--mirai-ui-font-weight);
  text-align: var(--mirai-ui-text-align);
  margin: 0;
  padding: 0;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.action {
  font-size: var(--mirai-ui-font-size-action);
  line-height: var(--mirai-ui-line-height-action);
}

.bold:not(.brand) {
  font-family: var(--mirai-ui-font-bold);
  font-weight: var(--mirai-ui-font-bold-weight);
}

.medium:not(.brand) {
  font-family: var(--mirai-ui-font-medium);
  font-weight: var(--mirai-ui-font-medium-weight);
}

.capitalize::first-letter {
  text-transform: uppercase;
}

.brand {
  font-family: var(--mirai-ui-text-brand-font);
  font-weight: var(--mirai-ui-text-brand-font-weight);
}

.brand.bold {
  font-family: var(--mirai-ui-text-brand-font-bold);
  font-weight: var(--mirai-ui-text-brand-font-bold-weight);
}

.brand.medium {
  font-family: var(--mirai-ui-text-brand-font-medium);
  font-weight: var(--mirai-ui-text-brand-font-medium-weight);
}

.headline-1 {
  font-size: var(--mirai-ui-font-size-headline-1);
  line-height: var(--mirai-ui-line-height-headline-1);
}

.headline-2 {
  font-size: var(--mirai-ui-font-size-headline-2);
  line-height: var(--mirai-ui-line-height-headline-2);
}

.headline-3 {
  font-size: var(--mirai-ui-font-size-headline-3);
  line-height: var(--mirai-ui-line-height-headline-3);
}

.paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
  line-height: var(--mirai-ui-line-height-paragraph);
}

.small {
  font-size: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-line-height-small);
}

.tiny {
  font-size: var(--mirai-ui-font-size-tiny);
  line-height: var(--mirai-ui-line-height-tiny);
}

.underline {
  text-decoration: underline;
}

.upperCase {
  text-transform: uppercase;
}

.wide {
  width: 100%;
}

/* color */
.accent {
  color: var(--mirai-ui-accent);
}

.accentDark {
  color: var(--mirai-ui-accent-dark);
}

.accentLight {
  color: var(--mirai-ui-accent-light);
}

.error {
  color: var(--mirai-ui-error);
}

.info {
  color: var(--mirai-ui-info);
}

.light {
  color: var(--mirai-ui-content-light);
}

.success {
  color: var(--mirai-ui-success);
}

.warning {
  color: var(--mirai-ui-warning);
}
