.form {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.form .input {
  flex: 1 1 100%;
}

@media only screen and (min-width: 431px) {
  .form .input:nth-child(n + 3) {
    flex: 1 1 calc(50% - var(--mirai-ui-space-M));
    margin-right: var(--mirai-ui-space-M);
  }

  .form .input:last-child {
    margin-right: 0;
  }
}
