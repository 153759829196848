.message {
  align-items: flex-end;
  align-self: center;
  animation: mirai-message-visible 200ms cubic-bezier(0.1, 0.1, 0.25, 0.9); /* ! TODO: Why variables dont' work? */
  gap: calc(var(--mirai-chat-space) / 2);
  max-width: var(--mirai-chat-content-max-width);
  width: 100%;
}

.message.auto {
  justify-content: left;
}

.message:not(.auto) {
  justify-content: right;
  padding: 0 var(--mirai-chat-space);
}

.content {
  overflow: hidden;
}

.content.busy {
  background-color: transparent;
  overflow: visible;
  padding: var(--mirai-ui-space-XS) 0;
  position: relative;
}

.content:not(.auto) {
  border-radius: var(--mirai-chat-border-radius);
}

.content:not(.auto):not(.busy) {
  background-color: var(--mirai-chat-accent);
  border-bottom-right-radius: 0;
}

.content .text {
  font-family: var(--mirai-chat-text-font);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  line-height: var(--mirai-chat-text-line-height);
  width: fit-content;
}

.content > .text + * {
  margin-top: var(--mirai-chat-space);
}

.content.auto .text {
  color: var(--mirai-ui-content);
}

.content:not(.auto) .text {
  color: var(--mirai-chat-background);
  padding: var(--mirai-chat-message-offset);
}

.content .thumbnail {
  align-self: flex-start;
  background-size: contain;
  background-color: var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  margin-right: var(--mirai-chat-space);
  min-height: var(--mirai-ui-space-XL);
  min-width: var(--mirai-ui-space-XL);
}

.content .map {
  background-color: var(--mirai-chat-input-background);
  border: none;
  height: 180px;
  margin: 0;
  width: 100%;
}

.content .address {
  padding: var(--mirai-chat-message-offset);
}

.requirement {
  width: 100%;
}

.requirement.disabled {
  filter: grayscale(1);
  pointer-events: none;
}

.requirement.form {
  border: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  border-radius: var(--mirai-chat-border-radius);
}

.requirement > ui-input-text {
  padding: var(--mirai-chat-space) var(--mirai-chat-space) 0 var(--mirai-chat-space);
}

.requirement > *:not(ui-form) {
  width: 100%;
}

.requirement .footer {
  border-top: solid var(--mirai-chat-border-size) var(--mirai-ui-content-border);
  gap: var(--mirai-chat-space);
  justify-content: flex-end;
  padding: var(--mirai-chat-space);
}

.requirement + ui-pressable {
  margin-top: var(--mirai-chat-space);
}

.spinner {
  animation: mirai-message-spinner 0.8s infinite linear alternate;
  animation-delay: 0.4s;
  color: var(--mirai-ui-content);
  margin-left: calc(var(--mirai-chat-space) * 0.75);
  position: absolute;
}

.spinner,
.spinner::before,
.spinner::after {
  background-color: var(--mirai-ui-content);
  border-radius: 50%;
  color: var(--mirai-ui-content);
  height: calc(var(--mirai-chat-space) / 2.5);
  width: calc(var(--mirai-chat-space) / 2.5);
}

.spinner::before,
.spinner::after {
  animation: mirai-message-spinner 0.8s infinite alternate;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.spinner::before {
  animation-delay: 0s;
  left: calc(var(--mirai-chat-space) * -0.65);
}

.spinner::after {
  animation-delay: 0.8s;
  left: calc(var(--mirai-chat-space) * 0.65);
}

/* S  */
@media only screen and (max-width: 480px) {
  .content {
    max-width: 100%;
  }

  .message.auto {
    padding: 0 var(--mirai-chat-space);
  }
}

/* M & L  */
@media only screen and (min-width: 481px) {
  .content {
    max-width: 100%;
  }

  .requirement.form:not(.calendar) {
    max-width: calc(
      calc(var(--mirai-chat-content-max-width) / 2) -
        calc(calc(var(--mirai-chat-space) / 2) + calc(var(--mirai-chat-border-size) * 2))
    );
  }

  .requirement.form.calendar {
    max-width: fit-content;
  }
}

@keyframes mirai-message-spinner {
  0% {
    background-color: var(--mirai-ui-content-light);
  }
  50%,
  100% {
    background-color: var(--mirai-ui-content-border);
  }
}

@keyframes mirai-message-visible {
  from {
    opacity: 0;
    transform: translateY(var(--mirai-chat-space));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
