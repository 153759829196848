.icon {
  display: block;
}

.action {
  font-size: var(--mirai-ui-font-size-action);
  height: var(--mirai-ui-font-size-action);
  line-height: var(--mirai-ui-font-size-action);
  width: var(--mirai-ui-font-size-action);
}

.headline-1 {
  font-size: var(--mirai-ui-space-XL);
  height: var(--mirai-ui-space-XL);
  line-height: var(--mirai-ui-space-XL);
  width: var(--mirai-ui-space-XL);
}

.headline-2 {
  font-size: var(--mirai-ui-font-size-headline-2);
  height: var(--mirai-ui-font-size-headline-2);
  line-height: var(--mirai-ui-font-size-headline-2);
  width: var(--mirai-ui-font-size-headline-2);
}

.headline-3 {
  font-size: var(--mirai-ui-font-size-headline-3);
  height: var(--mirai-ui-font-size-headline-3);
  line-height: var(--mirai-ui-font-size-headline-3);
  width: var(--mirai-ui-font-size-headline-3);
}

.paragraph {
  font-size: var(--mirai-ui-font-size-paragraph);
  height: var(--mirai-ui-font-size-paragraph);
  line-height: var(--mirai-ui-font-size-paragraph);
  width: var(--mirai-ui-font-size-paragraph);
}

.small {
  font-size: var(--mirai-ui-font-size-small);
  height: var(--mirai-ui-font-size-small);
  line-height: var(--mirai-ui-font-size-small);
  width: var(--mirai-ui-font-size-small);
}
