.box {
  align-items: flex-start;
  background-color: var(--mirai-ui-base);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  border-radius: var(--mirai-ui-border-radius);
  padding: var(--mirai-ui-space-M);
}

.box > * {
  flex: 1;
}

.linkPrivacy {
  display: inline;
}

/* -- <Modal> ---------------------------------------------------------------- */
.modal {
  padding: var(--mirai-ui-space-M);
}

@media print {
  .noPrint {
    display: none;
  }

  .box {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 0 var(--mirai-ui-space-XS) 0;
  }

  .box.others > * {
    margin-top: var(--mirai-ui-space-M);
  }

  .box span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }
}
