.environment {
  align-self: center;
  background-color: var(--mirai-chat-input-background);
  border-radius: var(--mirai-chat-border-radius);
  flex-wrap: wrap;
  gap: var(--mirai-ui-space-XS);
  margin-top: calc(var(--mirai-chat-space) / 2);
  max-width: var(--mirai-chat-content-max-width);
  padding: calc(var(--mirai-chat-space) / 2) var(--mirai-chat-space);
}

.environment .variable {
  color: var(--mirai-ui-base);
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  display: block;
}

.environment.error {
  background-color: var(--mirai-ui-error-background);
}

.environment.error * {
  color: var(--mirai-ui-error);
}

@media only screen and (max-width: 480px) {
  .environment {
    margin-left: var(--mirai-chat-space);
  }
}
