.offset {
  padding-left: var(--mirai-ui-space-M);
  padding-right: var(--mirai-ui-space-M);
}

.notification {
  margin: var(--mirai-ui-space-M) var(--mirai-ui-space-M) 0 var(--mirai-ui-space-M);
}

.container .scrollview {
  gap: var(--mirai-ui-space-XXS);
  padding-top: var(--mirai-ui-space-XXS);
  width: 100%;
}

.ages {
  background-color: var(--mirai-ui-content-background);
  border-bottom: solid 1px var(--mirai-ui-content-border);
  border-top: solid 1px var(--mirai-ui-content-border);
  padding-bottom: var(--mirai-ui-space-S);
  padding-top: var(--mirai-ui-space-M);
  position: relative;
  overflow: visible;
}

.ages:after,
.ages:before {
  content: '';
  display: block;
  position: absolute;
  right: calc(var(--mirai-ui-space-M) + var(--mirai-ui-space-XL));
  top: calc(var(--mirai-ui-space-XS) * -2);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: var(--mirai-ui-space-XS);
}

.ages:after {
  margin-top: 1px;
  border-color: transparent transparent var(--mirai-ui-content-background) transparent;
}

.ages:before {
  border-color: transparent transparent var(--mirai-ui-content-border) transparent;
}

.ages .inputs {
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.ages .inputs .inputSelect {
  box-sizing: border-box;
  margin-bottom: var(--mirai-ui-space-M);
  margin-top: 0;
  max-width: 47%;
  min-width: 47%;
}

.ages .inputs .inputSelect:nth-child(odd) {
  margin-right: 6%;
}

.inputNumber *:first-letter {
  text-transform: capitalize;
}

.header {
  align-items: center;
  border-bottom: solid 1px var(--mirai-ui-content-border);
  height: calc(var(--mirai-ui-button-small-height) + var(--mirai-ui-space-XS));
  justify-content: space-between;
  margin-bottom: var(--mirai-ui-space-S);
  padding-left: var(--mirai-ui-space-M);
  padding-right: var(--mirai-ui-space-XS);
}

.footer {
  border-top: 1px solid var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-M);
  justify-content: space-between;
  padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
}

.buttonAdd {
  flex: 1;
}

.buttonSubmit {
  align-self: flex-start;
}

@media only screen and (max-width: 480px) {
  .container {
    max-height: calc(var(--mirai-modal-max-height) - var(--mirai-ui-space-XXL));
  }

  .scrollview {
    flex: 1;
  }
}

@media only screen and (min-width: 481px) {
  .container {
    width: var(--mirai-occupation-width);
  }

  .scrollview {
    max-height: var(--mirai-occupation-scrollview-max-height);
  }

  .ages {
    margin-left: 1px;
    margin-right: 1px;
  }
}
